<template>            
  <v-menu offset-y v-model="showMenu">
    <template v-slot:activator="{ on }">
      <v-text-field 
        :value="value" 
        :label="label"
        :rules="rules"
        :loading="loading"
        clearable 
        autocomplete="off"
        hide-details 
        v-on="on"
        @input="input"
        @click:clear="$emit('clear')"	
      />
    </template>
    <v-list max-height="300" class="overflow-y-auto">
      <v-list-item v-for="(item, index) in items" :key="index" @click="select(item)">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.fullName }}</v-list-item>
                    <img :src="item.thumbnailEdge" height="75"/>
                    <img :src="item.thumbnailSeam" height="75"/>
                </template>
                <span>{{item.description}}</span>
            </v-tooltip>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
    props: { label: String, value: String, rules: Array, queryParams: Object },
    data() { return { items: [], showMenu: false, loading: false }},
    methods: {
        query(v) {
            this.loading = true
            return this.axios.get('cards/joint-type/index', { params: { name: v, ...this.queryParams }}).then(r => {
                this.items = r.data.items.slice()
            }).finally(() => this.loading = false)
        },
        input(v) {
            this.$emit('input', v)
            if (!v || v.length < 2) return
            clearTimeout(this.searchRequestTimeout);
            this.searchRequestTimeout = setTimeout(() => this.query(v).then(() => this.showMenu = true), 1000);
        },
        select(i) {
            this.$emit('input', i.name)
            this.$emit('select', { edge: i.urlEdge, seam: i.urlSeam })
        },
    },
    mounted() {
        this.query();
    }
}
</script>