<template>
  <v-container v-if="card">
    <table class="table text-center">
      <tr>
        <th style="width: 25%;">СОГЛАСОВАНО</th>
        <th
          class="tmpl-hovered"
          id="name"
          @click="$emit('click', { fieldName: 'name', fieldTitle: 'Название карты'})"
          >
            Технологическая карта сварки №{{card.version.name}}
        </th>
        <th style="width: 25%;">УТВЕРЖДАЮ</th>
      </tr>
      <tr>
        <td>{{card.version.agreeder.post}}</td>
        <th>ОПИСАНИЕ КАРТЫ</th>
        <td>{{card.version.accepter.post}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.org}}</td>
        <td
          class="tmpl-hovered"
          id="about"
          rowspan="3"
          @click="$emit('click', { fieldName: 'about', fieldTitle: 'Орписание карты'})"
          >
          {{card.version.about}}
        </td>
        <td>{{card.version.accepter.org}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.name}}</td>
        <td>{{card.version.accepter.name}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.date}}</td>
        <td>{{card.version.accepter.date}}</td>
      </tr>
    </table>
    <table class="table text-center">
      <tr><th colspan="6">Характеристика соединения</th></tr>
      <tr>
        <th>Способ сварки</th>
        <th>Норм. документ</th>
        <th>Тип соединения</th>
        <th>Тип шва</th>
        <th>Вид шва</th>
        <th>Положение стыка</th>
      </tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="weldMethod"
          @click="$emit('click', { fieldName: 'weldMethod', fieldTitle: 'Способ сварки'})"
          >
          {{card.version.weldMethod}}
        </td>
        <td
          class="tmpl-hovered"
          id="normDocument"
          @click="$emit('click', { fieldName: 'normDocument', fieldTitle: 'Нормативный документ'})"
          >
          {{card.version.normDocument}}
        </td>
        <td
          class="tmpl-hovered"
          id="jointType"
          @click="$emit('click', { fieldName: 'jointType', fieldTitle: 'Тип соединения'})"
          >
          {{card.version.jointType}}
        </td>
        <td
          class="tmpl-hovered"
          id="seamType"
          @click="$emit('click', { fieldName: 'seamType', fieldTitle: 'Тип шва'})"
          >
          {{card.version.seamType}}
        </td>
        <td
          class="tmpl-hovered"
          id="seamKind"
          @click="$emit('click', { fieldName: 'seamKind', fieldTitle: 'Вид шва'})"
          >
          {{card.version.seamKind}}
        </td>
        <td
          class="tmpl-hovered"
          id="weldPosition"
          @click="$emit('click', { fieldName: 'weldPosition', fieldTitle: 'Положение при сварки'})"
          >
          {{card.version.weldPosition}}
        </td>
      </tr>
    </table>
    <table class="table text-center">
      <tr>
        <th style="width: 50%;">Конструкция соединения</th>
        <th style="width: 50%;">Конструктивные элементы шва</th>
      </tr>
      <tr>
        <td colspan="2">
          <table class="table">
            <tr>
              <td 
                class="tmpl-hovered"
                id="jointUrl"
                @click="$emit('click', { fieldName: 'jointUrl', fieldTitle: 'Конструкция соединения'})"
              >
                <img :src="card.version.jointUrl" style="max-height: 450px; max-width: 450px;"/>
              </td>
              <td 
                class="tmpl-hovered"
                id="seamUrl"
                @click="$emit('click', { fieldName: 'seamUrl', fieldTitle: 'Конструктивные элементы шва'})"
              >
                <img :src="card.version.seamUrl" style="max-height: 450px; max-width: 450px;"/>
              </td>
            </tr>
            <tr>
              <td
                class="tmpl-hovered"
                id="jointText"
                @click="$emit('click', { fieldName: 'jointText', fieldTitle: 'Примечания к конструкции соединения'})"
              >
                {{card.version.jointText}}
              </td>
              <td
                class="tmpl-hovered"
                id="seamText"
                @click="$emit('click', { fieldName: 'seamText', fieldTitle: 'Примечания к конструктивным элементам шва'})"
              >
                {{card.version.seamText}}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table text-center">
      <tr><th colspan="5">Характеристика свариваемых элементов</th></tr>
      <tr>
        <th>п.п.</th>
        <th>Тип элемента</th>
        <th>Марка материала</th>
        <th>Диаметр</th>
        <th>Толщина</th>
      </tr>
      <tr 
        class="tmpl-hovered" 
        id="element1"
        @click="$emit('click', { fieldName: 'element1', fieldTitle: 'Элемент 1'})"
      >
        <td>1</td>
        <td>{{card.version.element1.type}}</td>
        <td>{{card.version.element1.material}}</td>
        <td>{{card.version.element1.d || '- - -'}}</td>
        <td>{{card.version.element1.s || '- - -'}}</td>
      </tr>
      <tr 
        class="tmpl-hovered" 
        id="element2"
        @click="$emit('click', { fieldName: 'element2', fieldTitle: 'Элемент 2'})"
      >
        <td>2</td>
        <td>{{card.version.element2.type}}</td>
        <td>{{card.version.element2.material}}</td>
        <td>{{card.version.element2.d || '- - -'}}</td>
        <td>{{card.version.element2.s || '- - -'}}</td>
      </tr>
    </table>
    <table class="table text-center">
      <tr><th>Порядок сварки</th></tr>
      <tr
        class="tmpl-hovered"
        id="weldOrders"
        @click="$emit('click', { fieldName: 'weldOrders', fieldTitle: 'Порядок сварки'})"
      >
        <td>
          <v-container>
            <v-row justify="center">
              <v-col cols="4" v-for="(wo, i) in card.version.weldOrders" :key="'WO_' + i">
                <table class="table">
                  <tr><td>{{ wo.name }}</td></tr>
                  <tr><td><img :src="wo.url" style="max-width: 100%;"/></td></tr>
                  <tr><td>{{wo.text}}</td></tr>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </tr>
      <tr><th colspan="2">Общий комментарий к порядку сварки</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          colspan="2"
          id="weldOrderText"
          @click="$emit('click', { fieldName: 'weldOrderText', fieldTitle: 'Коментрий к порядку сварки'})"
          >
          {{card.version.weldOrderText}}
        </td>
      </tr>
    </table>
    <LayersTable :layers="card.version.layers" />
    <table class="table">
      <tr><th>Дополнительные параметры режимов</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="modesText"
          @click="$emit('click', { fieldName: 'modesText', fieldTitle: 'Дополнительные параметры'})"
          >
          {{card.version.modesText}}
        </td>
      </tr>
      <tr><th>Преварительный подогрев</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="preHeat"
          @click="$emit('click', { fieldName: 'preHeat', fieldTitle: 'Преварительный подогрев'})"
          >
          {{card.version.preHeat}}
        </td>
      </tr>
      <tr><th>Требования к кромкам</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="edgeRequire"
          @click="$emit('click', { fieldName: 'edgeRequire', fieldTitle: 'Требования к кромкам'})"
          >
          {{card.version.edgeRequire}}
        </td>
      </tr>
      <tr><th>Требования к сборке</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="assemblyRequire"
          @click="$emit('click', { fieldName: 'assemblyRequire', fieldTitle: 'Требования к сборке'})"
          >
          {{card.version.assemblyRequire}}
        </td>
      </tr>
      <tr><th>Требования к прихваткам</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="tackweldRequire"
          @click="$emit('click', { fieldName: 'tackweldRequire', fieldTitle: 'Требования к прихваткам'})"
          >
          {{card.version.tackweldRequire}}
        </td>
      </tr>
      <tr><th>Требования к сварке</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="weldRequire"
          @click="$emit('click', { fieldName: 'weldRequire', fieldTitle: 'Требования к сварке'})"
          >
          {{card.version.weldRequire}}
        </td>
      </tr>
    </table>
    <table class="table">
      <tr><th>Сварочное оборудование</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="weldEquipment"
          @click="$emit('click', { fieldName: 'weldEquipment', fieldTitle: 'Сварочное оборудование'})"
          >
          <span v-for="(eq, i) in card.version.weldEquipments" :key="i">{{ eq.name }}; </span>
        </td>
      </tr>
    </table>
    <table class="table">
      <tr><th>Послесварочная термическая обработка</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="postHeat"
          @click="$emit('click', { fieldName: 'postHeat', fieldTitle: 'Послесварочная термическая обработка'})"
          >
          Тип обработки: {{card.version.postHeatType}};
          Оборудование: <span v-for="(eq, i) in card.version.heatTreatmentEquipments" :key="i">{{ eq.name }}, </span>;
          Охлаждение: {{card.version.postHeatCooling}};
          Циклы обработки: <span v-for="(c, i) in card.version.postHeatCycles" :key="i">температура: {{c.temperature}}, выдержка: {{c.delay}}</span>;
        </td>
      </tr>
      <tr><th>Операционный контроль</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="operationControl"
          @click="$emit('click', { fieldName: 'operationControl', fieldTitle: 'Операционный контроль'})"
          >
          {{card.version.operationControl}}
        </td>
      </tr>
      <tr><th>Техника безопасности и охрана труда</th></tr>
      <tr>
        <td
          class="tmpl-hovered"
          id="safety"
          @click="$emit('click', { fieldName: 'safety', fieldTitle: 'Техника безопасности и охрана труда'})"
          >
          {{card.version.safety}}
        </td>
      </tr>
    </table>
    <table class="table" style="max-width: 50%">
      <tr>
        <th class="pr-2 text-right">Разработал</th>
        <td class="pl-2 text-left">{{developer}}</td>
      </tr>
      <tr>
        <th class="pr-2 text-right">Дата</th>
        <td class="pl-2 text-left">{{secToDatetime(this.card.createdAt)}}</td>
      </tr>
    </table>
    <table class="table" v-if="card.version.files.length">
      <thead>
        <tr>
          <th class="th text-center">
            Прикрепленные файлы
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(f, i) in card.version.files" :key="i">
          <td>
            <a :href="f.url" :download="f.name" target="_blank">
              {{f.name}}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </v-container>
</template>

<script>
import { secToDatetime } from '@/libs/time.js'
import { mapGetters } from 'vuex'
import LayersTable from '@/modules/cards/components/LayersTable.vue'

export default {
  components: { LayersTable },
  props: ['cardId'],
  data() {
    return {
      card: null,
      secToDatetime,
    }
  },
  computed: {
    ...mapGetters(['getLayerNameById', 'getChannelNameById', 'getPolarityNameById']),
    developer() {
      return `${this.card.developer.firstname} ${this.card.developer.middlename} ${this.card.developer.lastname}`
    },
  },
  methods: {
    getCurrent(channels) {
      let c = channels.find(ch => ch.nameId == 1)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    },
    getVoltage(channels) {
      let c = channels.find(ch => ch.nameId == 2)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    }
  },
  mounted: function() {
    this.$axios.get('/cards/special/card/view', {
      params: { id: this.cardId }
    }).then(r => {
      this.card = r.data
      this.$nextTick(() => { this.$emit('update:cardLoaded') })
    }).catch(() => {})
  },
}
</script>

<style>
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  .table td {
    height: 22px;
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
  }
  .table th {
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
    text-align: center;
  }
</style>
