<template>
  <v-navigation-drawer
    :value="showAvatarMenu"
    right
    absolute
    temporary
    style="padding-top: 64px; z-index: 4;"
    @input="!$event && setShowAvatarMenu(false)"
    >
    <v-list class="py-0">
      <v-divider class="py-0"/>
      <v-subheader>Управление акаунтом</v-subheader>
      <v-divider class="py-0"/>
      <v-list-item 
        class="py-0" 
        v-for="item in userMenu"
        :key="'user-menu' + item.title" 
        @click="go(item.route)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="userLogout">
        <v-list-item-title>Выход</v-list-item-title>
      </v-list-item>
      <v-divider class="py-0"/>
      <v-subheader>Управление организацией</v-subheader>
      <v-divider class="py-0"/>
      <v-list-item class="py-0" @click="go('/user/organizations/index')">
        <v-list-item-title>Выбрать</v-list-item-title>
      </v-list-item>
      <template v-if="orgSelected">
        <v-list-item 
          class="py-0" 
          v-for="item in orgMenu"
          :key="'org-menu' + item.title" 
          @click="go(item.route)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
      <v-list-item v-if="orgSelected" @click="orgLogout">
        <v-list-item-title>Выход</v-list-item-title>
      </v-list-item>
<!--       <v-list-item v-if="orgSelected" @click="orgGoOut">
        <v-list-item-title>Покинуть</v-list-item-title>
      </v-list-item>
 --><!--       <template v-if="admin">
        <v-divider class="py-0"/>
        <v-subheader>Администрирование</v-subheader>
        <v-divider class="py-0"/>
        <v-list-item class="py-0"
          v-for="(item, i) in adminMenu"
          :key="'org-menu' + item.title"
          @click="go(item.route)"
          >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
 -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

const userMenu = [
  { title: 'Профиль', route: '/user/profile/update' },
  { title: 'Модули', route: '/user/modules/index' },  
  // { title: 'Настройки', route: '/user/account/settings' },
]

const orgMenu = [
  { title: 'Профиль', route: '/organization/profile/update' },
  { title: 'Персонал', route: '/organization/staff/index' },
  { title: 'Модули', route: '/organization/modules/index' },  
]


export default {
  data: () => ({
    userMenu,
    orgMenu,
  }),
  computed: {
    ...mapState(['showAvatarMenu']),
    ...mapState('organization', { orgSelected: 'selected' }),
  },
  methods: {
    ...mapMutations(['setShowAvatarMenu']),
    userLogout() {
      this.setShowAvatarMenu(false)
      if (this.orgSelected) {
        this.$store.dispatch('organization/logout').then(() => {
          this.$store.dispatch('user/logout')
        })
      } else {
        this.$store.dispatch('user/logout')
      }
    },
    orgLogout() {
      this.setShowAvatarMenu(false)
      this.$store.dispatch('organization/logout').then(() => {
        this.$router.push('/').catch(() => {})
      })
    },
    orgGoOut() {
      this.setShowAvatarMenu(false)
      if (confirm('Really?')) {
        console.log('going out')
        // this.$router.push('/').catch(() => {})
      }
    },
    go(route) {
      this.setShowAvatarMenu(false)
      if (this.$route.path == route) {
        return
      }
      this.$router.push(route)
    }
  }
}
</script>
