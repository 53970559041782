<template>
  <v-card v-if="verificationLoadedFlag" width="65%" tile style="margin: 25px auto;">
    <v-card-text>
      <v-card-title class="justify-center text-center mb-5">
        Протокол №{{$route.query.id}} поверки устройства<br>{{ $route.query.deviceUuid }}
      </v-card-title>
      <v-card-subtitle class="pl-0 pb-0">Таблица А.1 – Результаты поверки измерения напряжения постоянного тока.</v-card-subtitle>
      <table class="table text-center">
        <thead>
          <tr>
            <td colspan="3">Поверяемые точки</td>
            <td colspan="3">Значения измеряемой величины</td>
            <td colspan="2">Результаты калибровки</td>
            <td rowspan="2">Заключение</td>
          </tr>
          <tr>  
            <td>к.т.</td>
            <td>Диапазон, В</td>
            <td>Установленное значение, В</td>
            <td>Нижний предел, В</td>
            <td>Верхний предел, В</td>
            <td>Показания, В</td>
            <td>Предел допускаемой погрешности ∆, В</td>
            <td>Погрешность, В</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, i) in verification[1]">
            <tr :key="'VP_F' + i">
              <td rowspan="2">{{i + 1}}</td>
              <td rowspan="11" v-if="i == 0">От -100.0 до 100.0</td>
              <td>{{ item.ref.toFixed(1) }}</td>
              <td>{{ calcLowLimit(item.ref, voltageDelta).toFixed(2) }}</td>
              <td>{{ calcHighLimit(item.ref, voltageDelta).toFixed(2) }}</td>
              <td>{{ item.forward.toFixed(2) }}</td>
              <td rowspan="2">&#177;{{ voltageDelta }}</td>
              <td>{{ (item.ref - item.forward).toFixed(2) }}</td>
              <td>{{ checkMatch(item.ref, item.forward, voltageDelta) }}</td>
            </tr>
            <tr :key="'VP_B' + i">
              <td>{{ (-item.ref).toFixed(1) }}</td>
              <td>{{ calcLowLimit(-item.ref, voltageDelta).toFixed(2) }}</td>
              <td>{{ calcHighLimit(-item.ref, voltageDelta).toFixed(2) }}</td>
              <td>{{ item.backward.toFixed(2) }}</td>
              <td>{{ (-item.ref - item.backward).toFixed(2) }}</td>
              <td>{{ checkMatch(-item.ref, item.backward, voltageDelta) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <br>
      <!-- CURRENT -->
      <v-card-subtitle class="pl-0 pb-0">Таблица А.2 – Результаты поверки измерения постоянного тока.</v-card-subtitle>
      <table class="table text-center">
        <thead>
          <tr>
            <td colspan="3">Поверяемые точки</td>
            <td colspan="3">Значения измеряемой величины</td>
            <td colspan="2">Результаты калибровки</td>
            <td rowspan="2">Заключение</td>
          </tr>
          <tr>  
            <td>к.т.</td>
            <td>Диапазон, А</td>
            <td>Установленное значение, А</td>
            <td>Нижний предел, А</td>
            <td>Верхний предел, А</td>
            <td>Показания, А</td>
            <td>Предел допускаемой погрешности ∆, А</td>
            <td>Погрешность, А</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, i) in verification[0]">
            <tr :key="'CP_F' + i">
              <td rowspan="2">{{i + 1}}</td>
              <td v-if="i == 0" rowspan="11">От -500.0 до 500.0</td>
              <td>{{ item.ref.toFixed(1) }}</td>
              <td>{{ calcLowLimit(item.ref, currentDelta).toFixed(2) }}</td>
              <td>{{ calcHighLimit(item.ref, currentDelta).toFixed(2) }}</td>
              <td>{{ item.forward.toFixed(2) }}</td>
              <td rowspan="2">&#177;{{ currentDelta }}</td>
              <td>{{ (item.ref - item.forward).toFixed(2) }}</td>
              <td>{{ checkMatch(item.ref, item.forward, currentDelta) }}</td>
            </tr>
            <tr :key="'CP_B' + i">
              <td>{{ (-item.ref).toFixed(1) }}</td>
              <td>{{ calcLowLimit(-item.ref, currentDelta).toFixed(2) }}</td>
              <td>{{ calcHighLimit(-item.ref, currentDelta).toFixed(2) }}</td>
              <td>{{ item.backward.toFixed(2) }}</td>
              <td>{{ (-item.ref - item.backward).toFixed(2) }}</td>
              <td>{{ checkMatch(-item.ref, item.backward, currentDelta) }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </v-card-text>
    <v-card-actions class="mr-5 pb-5">
      <v-btn :to="'/device/verification/index?deviceUuid=' + $route.query.deviceUuid">Назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn>Печать</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { secToDatetime } from '@/libs/time.js'

export default {
  data: () => ({
    secToDatetime,
    verification: {},
    verificationLoadedFlag: false,
    voltageDelta: 0.15,
    currentDelta: 1.5
  }),
  methods: {
    checkMatch(ref, real, delta) {
      let realDelta = ref - real
      if (Math.abs(realDelta) <= delta) {
        return 'соотв.'
      } else {
        return 'не соотв.' 
      }
    },
    calcLowLimit(v, d) {
      if (v > 0) {
        return v - d
      } else {
        return v + d
      }
    },
    calcHighLimit(v, d) {
      if (v > 0) {
        return v + d
      } else {
        return v - d
      }
    },
  },
  mounted: function() {
    this.$axios.get('/device/verification/view', {
      params: { id: this.$route.query.id }
    }).then(r => {
      this.verification = r.data
      this.verificationLoadedFlag = true
    }).catch(() => {})
  },
}
</script>


<style>
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  .table td {
    height: 22px;
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
  }
  .table th {
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
    text-align: center;
  }
</style>
