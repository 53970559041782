<template>
    <v-container>
        <v-row>
            <v-col offset="2" cols="8">
                <v-card>
                    <v-toolbar color="primary" dark>Активные модули</v-toolbar>
                    <v-card-text>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Модуль</th>
                                        <th>Краткое описание</th>
                                        <th>Активирован</th>
                                        <th>Действует до</th>
                                        <th width="64"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="m in activeModules" :key="m.id">
                                        <td>{{m.name}}</td>
                                        <td>{{m.description}}</td>
                                        <td>{{m.activatedAt}}</td>
                                        <td>{{m.expiredAt}}</td>
                                        <td><v-btn tile outlined><v-icon>mdi-plus</v-icon></v-btn></td>
                                    </tr>
                                    <tr v-if="activeModules.length == 0">
                                        <td colspan="6" class="text-center">
                                            Ничего не найдено
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>            
                </v-card>
            </v-col>
            <v-col offset="3" cols="6">
                <v-card>
                    <v-toolbar color="primary" dark>Доступные модули</v-toolbar>
                    <v-card-text>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Модуль</th>
                                        <th>Описание</th>
                                        <th width="160"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="m in allModules" :key="m.id">
                                        <td>{{m.name}}</td>
                                        <td>{{m.description}}</td>
                                        <td>
                                            <v-btn 
                                                tile 
                                                outlined 
                                                :disabled="inActiveModules(m.id) || checkOrgModule(m)"
                                                color="warning" 
                                                @click="activate(m)"
                                            >
                                                Активировать
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>            
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    data() {
        return {
            allModules: [],
            // activeModules: [],
        }
    },
    computed: {
        orgId() {
            return this.$store.getters.getOrgId;
        },
        activeModules() {
            if (this.orgId) {
                return this.$store.state.organization.modules
            }
            return this.$store.state.user.modules
        }
    },
    methods: {
        checkOrgModule(m) {
            if (m.typeId === 3) {
                if(this.orgId) {
                    return false
                } else {
                    return true
                }
            }
            return false
        },
        activate(module) {
            this.$store.dispatch('module/activateModule', module) //.then(() => this.getActiveModules())
        },
        inActiveModules(id) {
            return this.activeModules.some(mm => mm.id == id)
        },
        // getActiveModules() {
        //     let url = 'module/main/user-index' 
        //     if (this.$store.getters.getOrgId) {
        //         url = 'module/main/organization-index'
        //     }
        //     this.$axios.get(url).then(r => this.activeModules = r.data.slice())
        // }
    },
    mounted() {
        this.$axios.get('module/main/index').then(r => this.allModules = r.data.slice())
        // this.getActiveModules();
    }
}
</script>