<template>
  <v-card style="margin: 15px;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Импортирование технологических карт</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pt-2 px-0">
      <v-row no-gutters justify="space-between" class="px-5">
        <v-col cols="4" class="px-2">
          <v-select
            label="Модуль"
            :items="modules"
            item-text="name"
            v-model="selectedModule"
            return-object
            @change="updateCards"
          />
        </v-col>
        <v-col cols="4" class="px-2">
          <StaffSearchField
            v-model="accepter"
            :queryParams="{ orgId: $store.getters.getOrgId, role: 'accepter' }"
            label="Утверждающий"
          />
        </v-col>
        <v-col cols="3" class="px-2">
          <v-checkbox label="Требуется согласование" v-model="requireAgree"/>
        </v-col>
        <v-col cols="12" class="px-2" v-if="requireAgree">
          <v-row>
            <v-col cols="6" class="px-2">
              <OrganizationSearchField
                v-model="agreederOrg"
                :queryParams="{ scope: 1 }"
                label="Согласующая организация"
              />
            </v-col>
            <v-col cols="6" class="px-2">
              <StaffSearchField
                v-model="agreeder"
                :queryParams="{ orgId: agreederOrg ? agreederOrg.id : null, role: 'agreeder' }"
                label="Согласующий"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <CardsTable
            v-if="selectedModule"
            :baseUrl="selectedModule.baseUrl + '/card'"
            :key="cardsTableUK"
            :showActions="false"
            viewTarget="_blank"
            @changed="cardTableChanged"            
            >
            <template v-slot:select="{ card }">
              <v-checkbox
                :value="card"
                v-model="selectedCards"
              />
            </template>
          </CardsTable>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <!-- <v-btn
        color="default"
        :loading="sending"
        :disabled="sending"
        @click="$router.go(-1)"
        >
        Отмена
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        :loading="sending"
        :disabled="disableNext()"
        @click="select"
        >
        Создать
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CardsTable from '@/modules/cards/components/CardsTable.vue'
import StaffSearchField from '@/modules/organization/components/StaffSearchField.vue'
import OrganizationSearchField from '@/modules/organization/components/OrganizationSearchField.vue'

export default {
  components: { CardsTable, StaffSearchField, OrganizationSearchField },
  data: () => ({
    cardsTableUK: 0,
    sending: false,
    loading: false,
    selectedCards: [],
    selectedModule: null,
    accepter: null,
    requireAgree: false,
    agreederOrg: null,
    agreeder: null,
  }),
  computed: {
    // orgId() {
    //   return this.$store.state.organization.profile.id
    // },
    // userId() {
    //   return this.$store.state.user.profile.userId
    // },
    modules() {
      return this.$store.getters.getModules.filter(m => m.typeId == 1)
    }
  },
  methods: {
    disableNext() {
      if (this.sending || this.selectedCards.length === 0 || this.accepter === null) {
        return true
      }
      if (this.requireAgree && ( this.agreeder === null || this.agreederOrg === null)) {
        return true
      }
      return false
    },
    select() {
      this.loading = true
      let cards = []
      this.selectedCards.forEach(c => {
        let card = {
          moduleId: this.selectedModule.id,
          moduleCardId: c.id,
          name: c.name,
          weldMethod: c.weldMethod,
          element1Type: c.element1Type,
          element2Type: c.element2Type,
          d1: c.d1,
          d2: c.d2,
          s1: c.s1,
          s2: c.s2,
          seamType: c.seamType,
          jointType: c.jointType,
          element1Material: c.element1Material,
          element2Material: c.element2Material,
          weldMaterial: c.weldMaterial,
          weldPosition: c.weldPosition,
          createdBy: c.createdBy,
          accepterId: this.accepter.userId,
          agreederOrgId: this.agreederOrg ? this.agreederOrg.id : null,
          agreederId: this.agreeder ? this.agreeder.userId : null,
        }
        cards.push(card)
      })
      this.$axios.post('/manufacture/card/create', cards).then(r => {
        this.$router.push('index-accept')
      })
    },
    updateCards() {
      this.cardsTableUK = Date.now()
    },
    cardTableChanged() {
      this.selectedCards = []
    },
  },
}
</script>
