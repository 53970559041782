<template>
  <v-card class="ma-5">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Простые карты режимов</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <CardsTable :key="reloadCardsTable" :baseUrl="'/cards/special/card'">
      <template v-slot:select="{ card }">
        <v-checkbox
          :value="card.id"
          v-model="selectedCards"
          v-if="Number(card.status) !== 2"
        />
      </template>
    </CardsTable>
    <v-card-actions>
      <v-btn
        color="default"
        :disabled="selectedCards.length === 0"
        @click="archiveCards"
        >
        В архив
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" to="card/create">Создать</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import { mapActions } from 'vuex'
import CardsTable from '@/modules/cards/components/CardsTable.vue'

export default {
  components: { CardsTable },
  data: () => ({
    selectedCards: [],
    reloadCardsTable: 0,
    perPage: 5
  }),
  methods: {
    archiveCards(item) {
      let url = '/cards/special/archive'
      this.$axios.post(url, { ids: this.selectedCards }).then(r => {
        this.reloadCardsTable = Date.now()
      }).catch(() => {})
    },
    // ...mapActions('cards/special', { createCard: 'setStep'})
    // createCard() {
    //   this.$store.dispatch('cards/special/setStep', 1).then(() => {
    //     this.$router.push('/cards/special/create')
    //   })
    // }
  }
}
</script>
