<template>
  <v-app>
    <Toolbar/>
    <MainMenu/>
    <AvatarMenu/>
    <Navbar v-if="navbarItems.length" :items="navbarItems"/>
    <!-- <div style="background-color: red;">test</div> -->
    <v-alert
      v-if="alert"
      class="alert-fixed elevation-3"
      :color="alert.type" dark
      >
      {{alert.message}}
    </v-alert>
    <v-main :class="{ pt128: alert }">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Toolbar from './components/Toolbar.vue'
import Navbar from './components/Navbar.vue'
import MainMenu from './components/MainMenu.vue'
import AvatarMenu from './components/AvatarMenu.vue'

export default {
  components: { Toolbar, Navbar, MainMenu, AvatarMenu },
  data: () => ({}),
  props: {
    navbarItems: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.user.role === 'admin'
    },
    alert() {
      return this.$store.getters.alert
    }
  }
}
</script>

<style>
.alert-fixed {
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 4;
}
.pt128 {
  padding-top: 128px !important;
}
</style>
