<template>
  <v-card width="90%" class="mt-4 mx-auto">
    <ChatDialog
      v-if="chatDialogShow"
      :title="chatDialogTitle"
      :cardId="chatDialogCardId"
      @close="chatDialogShow = false"
    />
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Тех. карты в очереди на рассмотрение</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/manufacture/card/create"><v-icon>mdi-plus</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="px-0 pb-0">
      <CardsTable
        url="/manufacture/card/index-accept"
        :key="cardsTableUK"
        >
        <template v-slot:status="{card}">
          <CardStatus :value="getCardStatus(card.status)"/>
        </template>
        <template v-slot:name="{card}">
          <router-link :to="cardViewUrl(card)">{{card.name}}</router-link>
        </template>
        <template v-slot:action="{ card }">
          <v-btn icon v-if="card.importedBy == userId && card.status == 1">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            v-if="card.status == 16"
            icon
            color="primary"
            @click="showChatDialog(card)"
            >
            <v-icon>mdi-chat</v-icon>
          </v-btn>
        </template>
      </CardsTable>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CardsTable from './components/CardsTable.vue'
import CardStatus from './components/CardStatus.vue'
import { statuses } from '../../vars/index.js'
import ChatDialog from '../card-chat/ChatDialog.vue'

export default {
  components: {
    CardsTable,
    CardStatus,
    ChatDialog,
  },
  data: () => ({
    perPage: 5,
    selectedCards: [],
    cardsTableUK: 0,
    statuses,
    chatDialogShow: false,
    chatDialogTitle: '',
    chatDialogCardId: 0,
  }),
  computed: {
    orgId() {
      return this.$store.state.organization.profile.id
    },
    userId() {
      return this.$store.state.user.profile.userId
    },
  },
  methods: {
    showChatDialog(card) {
      this.chatDialogTitle = card.name
      this.chatDialogCardId = card.id
      this.chatDialogShow = true
    },
    cardViewUrl(card) {
      if (card.status == 10) {
        if (card.importedBy == this.userId) {
          return '/manufacture/card-comment/correct?id=' + card.id
        }
      } else {
        if (card.accepterId == this.userId) {
          return '/manufacture/card-comment/accept?id=' + card.id
        }
      }
      return '/manufacture/card-comment/view?id=' + card.id
    },
    getCardStatus(status) {
      let s = this.statuses.find(cs => cs.id == status)
      return s || { icon: 'mdi-help', text: 'Неизвестно', color: 'default' }
    },
  },
}
</script>
