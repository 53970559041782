<template>
  <CommentProcView>
    <template v-slot:title="{ proc }">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          Исправление карты: {{proc.name}}
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:actions="{ proc }">
      <v-card-actions>
        <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
        <ChatDialog
          v-if="chatDialogShow"
          :title="proc.name"
          :procId="proc.id"
          @close="chatDialogShow = false"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          v-if="proc.status == 16"
          @click="chatDialogShow = true"
          >
          Обсудить
        </v-btn>
        <v-btn
          color="success"
          :to="'/manufacture/process-template/update?id=' + proc.id"
          >
          Исправить
        </v-btn>
        <v-btn
          color="error"
          :disabled="disableToCorrected(proc)"
          @click="sendToCorrected(proc)"
          >
          Отправить на рассмотрение
        </v-btn>
      </v-card-actions>
    </template>
  </CommentProcView>
</template>

<script>
import CommentProcView from './components/CommentProcView.vue'
import ChatDialog from '../process-template-chat/ChatDialog.vue'

export default {
  components: { CommentProcView, ChatDialog },
  data: () => ({
    chatDialogShow: false,
    chatDialogTitle: '',
    chatDialogCardId: 0,
  }),
  methods: {
    disableToCorrected(proc) {
      let fn = ''
      let c = proc.privateChat.find(c => {
        if (c.fieldName != fn) {
          fn = c.fieldName
          return c.status == 1
        }
      })
      return !!c
    },
    sendToCorrected(proc) {
      this.$axios.post('manufacture/process-template/to-corrected', { id: proc.id})
      .then(r => {
        this.$router.go(-1)
      }).catch(() => {})
    },
  },
}
</script>
