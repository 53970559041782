<template>
  <v-autocomplete
    v-model="selected"
    :label="label"
    :items="computeItems"
    item-text="name"
    return-object
    :loading="loading"
    :search-input.sync="searchValue"
    @change="$emit('input', selected)"
    >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.uuid }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
    label: {
      type: String,
      default: 'Поиск устройства...'
    },
    queryParams: {
      type: Object,
      default: () => ({})
    }
  },  
  data() {
    return {
      loading: false,
      items: [],
      selected: null,
      searchValue: '',
      searchRequestTimeout: null,
    }
  },
  computed: {
    computeItems() {
      if(this.items.length > 0) return this.items
      else if(this.value) return [this.value]
      return []
    },
  },  
  methods: {
    query(v) {
      this.loading = true
      this.$axios.get('/device/main/index', { params: { name: v }}).then(r => {
        this.items = r.data.items.slice()
      }).finally(() => this.loading = false)
    },
    select() {
      this.$emit('input', this.selected)
    },
  },
  watch: {
    searchValue(v) {
      if(v && v.length >= 2) {
        if (this.selected && this.selected.name == v) {
          return
        }
        clearTimeout(this.searchRequestTimeout);
        this.searchRequestTimeout = setTimeout(() => this.query(v), 1000);
      }
    },
  },
}
</script>
