<template>
  <div class="uploader" :style="{ display: displayStyle }">
    <v-progress-linear
      v-if="showProgress && (uploading || error)"
      :value="progress"
    />
    <span v-if="uploading">
      <v-btn class="ma-0" icon @click="abort">
        <v-icon>mdi-cancel</v-icon>
      </v-btn>
    </span>
    <span v-if="error">
      <v-btn class="ma-0" icon @click="restart">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    file: File,
    uploadUrl: { type: String, default: 'file/upload' },
    cancel: Boolean,
    showProgress: Boolean,
  },
  data: () => ({
    uploading: false,
    error: false,
    progress: 0,
  }),
  computed: {
    displayStyle() {
      if (this.uploading || this.error) {
        return 'flex'
      }
      return 'none'
    }
  },
  watch: {
    file: function(newValue, oldValue) {
      this.upload()
    },
    cancel: function(newValue, oldValue) {
      if(oldValue) return
      this.abort()
    },
  },
  methods: {
    upload() {
      this.$emit('start')
      this.uploading = true
      this.error = false
      var formData = new FormData()
      formData.append('file', this.file)
      this.$axios.post(this.uploadUrl, formData, {
        onUploadProgress: progressEvent => {
          var progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.$emit('progress', progress)
            this.progress = progress
        }
      }).then(r => {
        this.$emit('done', r.data)
        this.uploading = false
        this.progress = 0
      }).catch(err => {
        this.$emit('error', err)
        this.uploading = false
        this.error = true
      });
    },
    abort() {
      http.abort(this.axiosConfig.requestID)
      this.error = true
    },
    restart() {
      this.progress = 0
      this.error = false
      this.upload()
    }
  }
}
</script>

<style>
  .uploader {
    display: flex;
    align-items: center;
    width: 100%;
    height: 25px;
    padding: 0 25px 0 25px;
  }
</style>
