<template>
  <v-card width="90%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Простые карты режимов</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <CardsTable :perPage="perPage" :key="reloadCardsTable" baseUrl="/cards/simple/card">
      <template v-slot:select="{ card }">
        <v-checkbox
          :value="card.id"
          v-model="selectedCards"
          v-if="Number(card.status) !== 2"
        />
      </template>
    </CardsTable>
    <v-card-actions>
      <v-btn
        color="default"
        :disabled="selectedCards.length === 0"
        @click="archiveCards"
        >
        В архив
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" to="/cards/simple/card/create">Создать</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CardsTable from '@/modules/cards/components/CardsTable.vue'

export default {
  components: {
    CardsTable,
  },
  data: () => ({
    selectedCards: [],
    reloadCardsTable: 0,
    perPage: 5
  }),
  computed: {
    ...mapState('organization', {
      orgId: state => state.profile.id
    })
  },
  methods: {
    archiveCards(item) {
      let url = '/cards/simple/card/archive'
      this.$axios.post(url, { ids: this.selectedCards }).then(r => {
        this.reloadCardsTable = Date.now()
      }).catch(() => {})
    },
  },
}
</script>
