<template>
  <v-card width="85%" style="margin: 25px auto">
    <v-card-title class="primary justify-center white--text">
      Создание новой карты
    </v-card-title>
    <v-stepper :value="currentStep" @change="currentStep = $event">
      <v-stepper-header>
        <template v-for="(it,index) in stepsCount">
          <v-stepper-step
            :step="index + 1"
            :complete="currentStep > index + 1"
            :editable="currentStep > index + 1"
            edit-icon="$complete"
            :key="index"
          >
            Шаг {{ index + 1 }}
          </v-stepper-step>
        </template>
      </v-stepper-header>
    </v-stepper>
    <v-card-text class="grey lighten-1">
      <component
        v-if="currentStep" 
        :is="'Step' + currentStep" 
        v-model="card"
      />
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-btn color="default" @click="$router.go(-1)">Отмена</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="scondary" v-if="currentStep > 1" @click="currentStep--">Назад</v-btn>
      <v-btn v-if="currentStep != stepsCount" color="primary" @click="currentStep++">
        Далее
      </v-btn>
      <v-btn v-else color="success" @click="save">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Step1 from './steps/Step1.vue'
import Step2 from './steps/Step2.vue'
import Step3 from './steps/Step3.vue'
import Step4 from './steps/Step4.vue'
import Step5 from './steps/Step5.vue'
import Step6 from './steps/Step6.vue'
import Step7 from './steps/Step7.vue'

const testCard = {
  accepter: { name: "Khurin",  org: "Delta 1", post: "Constructor", date: "22.02.1984" },
  agreeder: { name: "Smirnov", org: "Delta 2", post: "Director", date: "19.05.1977" },
  name: "TEST CARD NAME",
  about: "Something about card and it destenee",
  files: [],
  weldMethod: "РД",
  normDocument: "ГОСТ 5264-80",
  jointType: "С2",
  jointUrl: "/api/cards/assets/images?file=joints/g_5264-80_s2_1.png",
  jointText: "",
  seamUrl: "/api/cards/assets/images?file=joints/g_5264-80_s2_2.png",
  seamText: "",
  seamType: "СШ",
  seamKind: "Ос, сп",
  weldPosition: "Н1",
  jointOthers: "Прочие параметры",
  element1:  { type: "Труба (круглая) (Т)", material: "09Г2С", d: "100", s: "4" },
  element2:  { type: "Труба (круглая) (Т)", material: "Ст3", d: "101", s: "5" },
  weldOrders:  [
    { name: "Порядок 1", url: "", text: "Примечание к порядку 1" },
  ],
  weldOrderText: "Общее примечание к порядку сварки",
  edgeRequire: "Какие-то требования к кромкам",
  assemblyRequire: "Какие-то требования к сборке",
  tackweldRequire: "Какие-то требования к прихватке",
  weldRequire: "Какие-то требования к сварки",
  layers: [
    { num: 1, layerNameId: 2, weldMaterial: "M0", polarityNameId: 1, electrode: 3, channels: [
      { channelId: 1, min: 50, max: 70},
      { channelId: 2, min: 20, max: 25}
    ]},
    { num: 2, layerNameId: 3, weldMaterial: "M1", polarityNameId: 1, electrode: 3.2, channels: [
      { channelId: 1, min: 60, max: 80},
      { channelId: 2, min: 20, max: 25}
    ]}
  ],
  modesText: "Прочие параметры соединения",
  weldEquipments:  [
    { name:  "DC - 200"}
  ],
  preHeat: "Ну там какой-то подогрев",
  heatTreatmentType: "Супер",
  heatTreatmentCooling: "Как пойдет",
  heatTreatmentEquipments:  [
    { name:  "Хороший прогреватель" }
  ],
  heatTreatmentCycles: [
    { temperature: 100, delay: 10, text: "Что-то по этому циклу"}
  ],
  operationControl: "Что-то там по контролю",
  safety: "Подробный рассказ про технику безопасности и права и обязанности работника",
}

const emptyCard = {
  accepter: { name: "",  org: "", post: "", date: "" },
  agreeder: { name: "", org: "", post: "", date: "" },
  name: "",
  about: "",
  files: [],
  weldMethod: "",
  normDocument: "",
  jointType: "",
  jointUrl: "",
  jointText: "",
  seamUrl: "",
  seamText: "",
  seamType: "",
  seamKind: "",
  weldPosition: "",
  element1:  { type: "", material: "", d: null, s: null },
  element2:  { type: "", material: "", d: null, s: null },
  weldOrders:  [],
  weldOrderText: "",
  edgeRequire: "",
  assemblyRequire: "",
  tackweldRequire: "",
  weldRequire: "",
  layers: [],
  modesText: "",
  weldEquipments: [],
  preHeat: "",
  heatTreatmentType: "",
  heatTreatmentCooling: "",
  heatTreatmentEquipments:  [],
  heatTreatmentCycles: [],
  operationControl: "",
  safety: "",
}

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
  },
  props: {
    mode: {
      type: String,
    },
  },  
  data() {
    return {
      currentStep: null,
      stepsCount: 7,
      loading: true,
      card: testCard,
    }
  },
  methods: {
    save() {
      this.loading = true
      let url = '/cards/special/card/create'
      if (this.mode == 'update') {
        url = '/cards/special/card/update?id=' + this.$route.query.id
      }
      this.$axios.post(url, { ...this.card }).then(() => {
        this.$router.go(-1)
      }).catch(() => {}).finally(() => { this.loading = false })
    }
  },
  mounted() {
    if (this.mode == 'copy' || this.mode == 'update') {
      this.$axios.get('cards/special/card/view?id=' + this.$route.query.id).then(r => {
        this.card = r.data.version
        if (this.mode == 'copy') {
          this.card.name = ''
        }
        this.loading = false
        this.currentStep = 1
      })
    } else {
      this.loading = false
      this.currentStep = 1
    }
  }
}
</script>

<style>
  .bordered {
    border-style: solid;
    border-width: thin;
  }
  .hovered {
    opacity: 100%;
  }
  .hovered:hover {
    opacity: 50%;
  }
</style>
