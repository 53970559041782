// import navbarItems from './navbarItems.js'

export default {
  path: '/device',
  meta: { private: true },
//   props: { navbarItems: navbarItems },
  component: () => import('@/layouts/main/Index.vue'),
  redirect: '/device/index',
  children: [
    {
      path: 'index',
      component: () => import('./views/Index.vue')
    },
    {
      path: 'create',
      component: () => import('./views/Create.vue')
    },    
    {
      path: 'view',
      component: () => import('./views/View.vue')
    },    
    {
      path: 'verification/index',
      component: () => import('./views/verification/Index.vue')
    },
    {
      path: 'verification/view',
      component: () => import('./views/verification/View.vue')
    },
  ]
}
