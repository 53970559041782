<template>
  <v-card width="65%" class="mt-4 mx-auto">
    <SignupDialog
      :show="showSignupDialog"
      @close="showSignupDialog = false"
    />
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Выбор организации</v-toolbar-title>
      <v-spacer/>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>            
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/organization/profile/create">
            <v-list-item-title>Создать</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showSignupDialog = true">
            <v-list-item-title>Подать заявку</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text>
      <v-row justify="end">
        <v-col cols="7">
          <TimeoutedField
            label="Поиск..."
            v-model="search"
            hide-details
          />
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th>Название</th>
              <th>Город</th>
              <th>Улица</th>
              <th>Дом</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="org in orgs" :key="org.id">
              <td class="pa-1">
                <img v-if="org.thumbnail" :src="org.thumbnail" width="50px"/>
                <v-icon v-else size="50px">mdi-factory</v-icon>
              </td>
              <td>{{org.title}}</td>
              <td>{{org.city}}</td>
              <td>{{org.street}}</td>
              <td>{{org.building}}</td>
              <td class="pa-0 pr-2">              
                <v-btn color="warning" text :disabled="isAllreadySignin(org.id)" @click="organizationSignin(org.id)">
                    <v-icon>mdi-login</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr v-if="orgs.length == 0" class="text-center pt-3">
                <td colspan="5">Ничего не найдено</td>
            </tr>
            </tbody>
          </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import TimeoutedField from '@/components/TimeoutedField.vue'
import SignupDialog from './components/SignupDialog.vue'

export default {
  components: { SignupDialog, TimeoutedField },
  data: () => ({
    showSignupDialog: false,
    orgs: [],
    search: '',
  }),
  methods: {
    isAllreadySignin(id) {
      return id == this.$store.getters['organization/orgId']
    },
    getOrgs() {
      this.$axios.get('/user/organizations/index', { params: { title: this.search }}).then(r => {
        this.orgs = r.data.items.slice()
      })
    },
    ...mapActions('organization', { organizationSignin: 'login' }),
  },
  mounted() {
    this.getOrgs()
  },
  watch: {
    search() {      
      this.getOrgs()
    }
  }
}
</script>
