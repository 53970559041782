<template>
  <v-card width="75%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Тех. процессы в работе</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/manufacture/process/create">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <ProcessesTable :queryParams="{ status: 24 }">
        <template v-slot:name="{ process }">
          <router-link :to="'/manufacture/process/view?id=' + process.templateId">
            {{process.template.version.name}}
          </router-link>
        </template>
      </ProcessesTable>
    </v-card-text>
  </v-card>
</template>
<script>
import ProcessesTable from './components/ProcessesTable.vue'

export default {
  components: { ProcessesTable },
}
</script>
