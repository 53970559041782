<template>
    <v-container>
        <v-simple-table class="cards-table" fixed-header max-height="300">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-if="hasSelectSlot"></th>
                        <th>
                          <span>ID/Название</span>
                          <input v-model="filter.name" class="my-input" style="max-width: 100%;"/>
                        </th>
                        <th>
                          <span>Способ сварки</span>
                          <input v-model="filter.weldMethod" class="my-input"/>
                        </th>
                        <th>
                          <span>Тип соединения</span>
                          <input v-model="filter.jointType" class="my-input"/>
                        </th>
                        <th>
                          <span>Тип элемента 1</span>
                          <input v-model="filter.element1Type" class="my-input"/>
                        </th>
                        <th>
                          <span>D1</span>
                          <input v-model="filter.d1From" class="my-input"/>
                          <input v-model="filter.d1To" class="my-input"/>
                        </th>
                        <th>
                          <span>S1</span>
                          <input v-model="filter.s1From" class="my-input"/>
                          <input v-model="filter.s1To" class="my-input"/>
                        </th>
                        <th>
                          <span>Тип элемента 2</span>
                          <input v-model="filter.element2Type" class="my-input"/>
                        </th>
                        <th>
                          <span>D2</span>
                          <input v-model="filter.d2From" class="my-input"/>
                          <input v-model="filter.d2To" class="my-input"/>
                        </th>
                        <th>
                          <span>S2</span>
                          <input v-model="filter.s2From" class="my-input"/>
                          <input v-model="filter.s2To" class="my-input"/>
                        </th>
                        <th>
                          <span>Шов</span>
                          <input v-model="filter.seamType" class="my-input"/>
                        </th>
                        <th>
                          <span>Материал 1</span>
                          <input v-model="filter.element1Material" class="my-input"/>
                        </th>
                        <th>
                          <span>Материал 2</span>
                          <input v-model="filter.element2Material" class="my-input"/>
                        </th>
                        <th>  
                          <span>Положение</span>
                          <input v-model="filter.weldPosition" class="my-input"/>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="card in cards" :key="card.id">
                        <td v-if="hasSelectSlot">
                          <slot name="select" :card="card">
                        </slot>
                        </td>
                        <td>
                        <router-link :to="baseUrl + '/view?id=' + card.id" :target="viewTarget">
                            {{ card.name }}
                        </router-link>
                        </td>
                        <td>{{ card.weldMethod }}</td>
                        <td>{{ card.jointType }}</td>
                        <td>{{ card.element1Type }}</td>
                        <td>{{ card.d1 }}</td>
                        <td>{{ card.s1 }}</td>
                        <td>{{ card.element2Type }}</td>
                        <td>{{ card.d2 }}</td>
                        <td>{{ card.s2 }}</td>
                        <td>{{ card.seamType }}</td>
                        <td>{{ card.element1Material }}</td>
                        <td>{{ card.element2Material }}</td>
                        <td>{{ card.weldPosition }}</td>
                        <td v-if="showActions">
                          <div style="display: flex">
                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                      <v-btn
                                          icon
                                          :to="baseUrl + '/update?id=' + card.id"
                                          :disabled="Number(card.status) == 7"
                                      >
                                          <v-icon color="primary" v-on="on">mdi-file-edit</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>Редактировать</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                      <v-btn icon :to="baseUrl + '/copy?id=' + card.id">
                                          <v-icon color="primary" v-on="on">mdi-content-copy</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>Копировать</span>
                              </v-tooltip>
                          </div>
                        </td>
                    </tr>
                    <tr v-if="cards.length == 0"><td colspan="14">Не найдено ни одной тех. карты</td></tr>
                    </tbody>
            </template>
        </v-simple-table>
        <v-pagination v-if="pagesTotal > 1" class="mt-3" v-model="page" :length="pagesTotal"></v-pagination>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    cards: [],
    filter: {
      name: '',
      weldMethod: '',
      elementsType: '',
      d1From: '',
      d1To: '',
      d2From: '',
      d2To: '',
      s1From: '',
      s1To: '',
      s2From: '',
      s2To: '',
      seamType: '',
      jointType: '',
      elem1Material: '',
      elem2Material: '',
      weldMaterial: '',
      weldPosition: '',
    },
    page: 1,
    pagesTotal: 0,
    apiTimeout: null,
    loading: false
  }),
  props: {
    viewTarget: {
      type: String,
      default: null,
    },
    showActions: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      default: 5,
    },
    queryParams: {
      type: Object,
      default: null,
    },
    baseUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    hasSelectSlot() {
      return !!this.$scopedSlots['select']
    },
    hasActionsSlot() {
      return !!this.$scopedSlots['actions']
    }
  },
  methods: {
    getCards() {
      this.loading = true
      this.cards = []
      this.$axios.get(this.baseUrl + '/index', {
        params: {
          ...this.filter,
          ...this.queryParams,
          page: this.page,
          pageSize: this.perPage,
        }
      }).then(r => {
        this.cards = r.data.items.slice()
        this.pagesTotal = r.data.pagination.pagesTotal
        this.$emit('changed')
      }).catch(() => {
      }).finally(() => {
        this.loading = false
      })
    },

  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.apiTimeout)
        this.apiTimeout = setTimeout(() => this.getCards(), 500)
      },
      deep: true
    },
    page(newPage) {
      this.getCards()
    },
    perPage() {
      this.getCards()
    }
  },
  mounted() {
    this.getCards()
  }
}
</script>

<style>
/*  .table {
    width: 100%;
  }

  .th {
    text-align: left;
  }

  .td {
    text-align: left;
  }*/
  .cards-table {
    overflow-y: auto !important;
  }
  .cards-table th {
    text-align: left !important;
    vertical-align: bottom !important;
    padding: 10px;
  }
  .cards-table td {
    text-align: left !important;
  }
  .my-input {
    background: transparent;
    align-items: center;
    border: 1px solid;
    border-radius: 4px;
    padding: 5px;
    width: 100px;
    display: block;
  }
  .my-input:before {
    border-color: rgba(0, 0, 0, 0.42);
  }
  .my-input:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
  .my-input:focus {
    border-color: #1976d2;
    outline: none;
  }
</style>
