<template>
  <v-autocomplete
    v-model="selected"
    :label="label"
    :items="items"
    item-text="fullName"
    return-object
    :search-input.sync="searchValue"
    @change="select"
  >
    <template v-slot:item="{ item }">
      <v-list-item-avatar>
        <v-img v-if="item.thumbnail" :src="item.thumbnail"/>
        <v-icon v-else size="40">mdi-account-circle</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ calcFullName(item) }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: Object,
    label: {
      type: String,
      default: 'Поиск пользователя'
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      selected: null,
      searchValue: null,
      searchRequestTimeout: null,
    }
  },
  methods: {
    calcFullName(item) {
      return `${item.firstname} ${item.middlename} ${item.lastname}`
    },
    query(v) {
      this.loading = true
      this.$axios.get('/user/profile/index', { params: { fullName: v } }).then(r => {
          this.items = r.data.items.slice()
      }).finally(() => {
        this.loading = false
      })
    },
    select() {
      this.$emit('input', this.selected)
    },
  },
  watch: {
    searchValue(v) {
      if(v && v.length >= 2) {
        if (this.selected == v) {
          return
        }
        clearTimeout(this.searchRequestTimeout);
        this.searchRequestTimeout = setTimeout(() => this.query(v), 1000);
      }
    },
  },
}
</script>
