<template>
  <v-card width="75%" style="margin: 25px auto;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Регистограммы</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <TimeoutedField
        v-if="registrations.length > 0"
        label="Поиск..."
        v-model="searchName"
      />
      <v-simple-table v-if="registrations.length > 0" :key="tableKey">
        <template v-slot:default>
          <thead>
            <tr>
              <td>ID</td>
              <td>Название</td>
              <td>Дата создания</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in registrations" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ secToDatetime(item.createdAt) }}</td>
              <td class="pa-0 text-right" width="350">
                <v-btn class="mr-2" color="primary" tile outlined :to="'/registration/view?id=' + item.id">
                  Просмотр
                </v-btn>                
                <v-btn color="error" tile outlined>
                  Удалить
                </v-btn>
              </td>              
            </tr>
          </tbody>
        </template>        
      </v-simple-table>
      <div class="text-center" v-else>
        Регистограмм не найдено 
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import TimeoutedField from '@/components/TimeoutedField.vue'
import { secToDatetime } from '@/libs/time.js'

export default {
  components: { TimeoutedField },
  data() {
    return {
      searchName: '',
      registrations: [],
      secToDatetime,
    }
  },
  methods: {
    query() {
      this.registrations = []
      this.axios.get('/registration/main/index', { params: { name: this.searchName, pageSize: 1000 } }).then(r => {
        this.registrations = r.data.items.slice()
        this.tableKey = Date.now()
      })
    },
  },
  watch: {
    searchName() {
      this.query()
    }
  },
  mounted() {
    this.query()
  }
}
</script>