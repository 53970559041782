import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/index.js'
import { _axios as axios } from '@/plugins/axios.js'
// BASE
import user from '@/modules/user/store.js'
import organization from '@/modules/organization/store.js'
// EXTRA
import module from '@/modules/module/store.js'
import device from '@/modules/device/store.js'
import dictionary from '@/modules/dictionary/store.js'
// CARDS
// import cards from '@/modules/cards/store.js'

Vue.use(Vuex)

const alertDuartion = 3000;

export default new Vuex.Store({
    modules: {
        user,
        organization,
        module,
        device,
        dictionary,
        // cards,
    },
    state: {
        apiRequestCounter: 0,
        alerts: [],
        showMainMenu: false,
        showAvatarMenu: false,
        layersNames: [],
        polaritiesNames: [],
        channelsNames: [],        
    },
    getters: {
        getUserId: state => state.user.profile.userId,
        // isAdmin: state => state.user.roles.includes('adminnn'),
        isOrganizationSelected: state => state.organization.selected,
        getOrgId: state => state.organization.selected ? state.organization.profile.id : null,
        alert: state => state.alerts[0],
        getModules: state => {
            if (state.organization.selected) {
                return state.organization.modules
            }
            return state.user.modules
        },
        getActiveModule: state => {
            if (state.organization.selected) {
                return state.organization.activeModule
            }
            return state.user.activeModule
        },
        getModuleById: (state, getters) => id => {
            return getters.getModules.find(m => m.id == id)
        },
        getPolarityNameById: state => id => {
            let p = state.polaritiesNames.find(pItem => pItem.id == id)
            return p ? p.name : 'Низвестная полярность'
        },
        getLayerNameById: state => id => {
            let l = state.layersNames.find(l => l.id == id)
            return l ? l.name : 'Низвестный слой'
        },
        getChannelNameById: state => id => {
            let ch = state.channelsNames.find(ch => ch.id == id)
            return ch ? ch.name : 'Низвестный канал'
        },
    },
    actions: {
        selectModule({ commit, state }, m) {
            commit('setShowMainMenu', false)
            if (state.organization.selected) {
                commit('organization/selectModule', m, { root: true })                
            } else {
                commit('user/selectModule', m, { root: true })
            }
            router.push(m.baseUrl + '/index').catch(() => {})
        },
        setAlert({ commit }, alert) {
            const timerId = setTimeout(() => {
                commit('removeAlert')
            }, alertDuartion)
            commit('setAlert', {
                message: alert.message,
                type: alert.type,
                timerId: timerId
            })
        },
        init({ commit, state, dispatch }) {
            if (state.user.loggedIn) {
                // axios.get('/main/layers-names').then(r => commit('setLayersNames', r.data))
                // axios.get('/main/channels-names').then(r => commit('setChannelsNames', r.data))
                // axios.get('/main/polarities-names').then(r => commit('setPolaritiesNames', r.data))
            }
            // axios.get('main/init').then(r => {
            //     commit('setModules', r.data.modules)
            //     commit('setLayersNames', r.data.layersNames)
            //     commit('setPolaritiesNames', r.data.polaritiesNames)
            //     commit('channelsNames', r.data.channelsNames)
            //     // commit('orgPositionsNames', r.data.orgPositionsNames)
            //     // commit('rolesNames', r.data.rolesNames)
            // })
        },
    },
    mutations: {
        setShowMainMenu(state, value) {
            state.showMainMenu = value
        },
        setShowAvatarMenu(state, value) {
            state.showAvatarMenu = value
        },
        setAlert(state, alert) {
            state.alerts.push(alert)
        },
        removeAlert(state) {
            if (state.alerts.length > 0) {
                clearTimeout(state.alerts[0].timerId)
                state.alerts.splice(0, 1)
            }
        },
        incAPIRequestCounter(state) {
            state.apiRequestCounter++
        },
        decAPIRequestCounter(state) {
            state.apiRequestCounter--
        },
        setLayersNames(state, data) {
            state.layersNames = data.slice()
        },
        setChannelsNames(state, data) {
            state.channelsNames = data.slice()
        },
        setPolaritiesNames(state, data) {
            state.polaritiesNames = data.slice()
        },
    }
})