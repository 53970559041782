<template>
  <v-container class="mb-3" style="border: thin solid;">
    <CardSearchDialog v-if="cardSearchDialog" @done="newCard" @close="closeDialog"></CardSearchDialog>
    <div class="subtitle-1 mb-2 text-center">Тех. карты</div>
    <v-card v-for="(card, i) in value" :key="i" class="mb-3">
      <div class="secondary white--text text-center pa-2 d-flex">
        <v-icon color="white" class="mr-2" @click="up(i)">mdi-arrow-up</v-icon>
        <v-icon color="white" @click="down(i)">mdi-arrow-down</v-icon>
        <v-spacer></v-spacer>
        Порядковый номер: {{card.queue}}
        <v-spacer></v-spacer>
        <v-icon color="white" class="mr-3" @click="copy(i)">mdi-content-copy</v-icon>
        <v-icon color="white" @click="remove(i)">mdi-close</v-icon>
      </div>
      <v-card-text>
        <v-row align="center">
          <v-col cols="8">
            Карта: {{card.name}} от {{secToDatetime(card.acceptedAt)}}
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Шов"
              v-model="card.seam"
              >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Шифр"
              v-model="card.code"
              >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            Способ сварки: {{card.weldMethod}}
          </v-col>
          <v-col cols="4">
            Вид элементов: {{card.elementsType}}
          </v-col>
          <v-col cols="4">
            Соединение: {{card.baseMaterial}}
          </v-col>
          <v-col cols="4">
            Материал: {{card.baseMaterial}}
          </v-col>
          <v-col cols="4">
            Положение: {{card.weldPosition}}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-btn color="info" small @click="cardSearchDialog = true">Добавть карту</v-btn>
    </div>
  </v-container>
</template>

<script>
import CardSearchDialog from './CardSearchDialog.vue'
import { secToDatetime } from '@/libs/time.js'
export default {
  components: { CardSearchDialog },
  props: {
    value: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    secToDatetime,
    cardSearchDialog: false
  }),
  methods: {
    newCard(cards) {
      this.cardSearchDialog = false
      cards.forEach(c => this.value.push(Object.assign({}, {
        queue: this.value.length + 1, 
        seam: this.value.length + 1,
        code: this.value.length + 1,
        ...c
      })))
      this.cardRefreshQueue()
    },
    up(index) {
          let item = this.value[index]
          this.value.splice(index, 1)
          this.value.splice(index - 1, 0, item)
          this.cardRefreshQueue()     
    }, 
    down(index) {
          let item = this.value[index]
          this.value.splice(index, 1)
          this.value.splice(index+1, 0, item)
          this.cardRefreshQueue()  
    },
    copy(index) {
          let item = this.value[index]
          this.value.splice(index + 1, 0, Object.assign({}, item, { seam: this.value.length + 1, code: this.value.length + 1 }))
          this.cardRefreshQueue()
    },
    remove(index) {
          if(confirm('Действительно удалить карту?')) {
            this.value.splice(index, 1)
            this.cardRefreshQueue()
          }
    },
    cardRefreshQueue() {
      this.value.forEach((c, i) => {c.queue = i + 1})
    },
    closeDialog() {
      this.cardSearchDialog = false
    }
  }
}
</script>