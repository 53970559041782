import router from '@/router/index.js'
import { _axios as axios } from '@/plugins/axios.js'

function getInitialState() {
    return {
        modules: [],
        activeModule: null,
    }
}

const lastState = JSON.parse(localStorage.getItem('modules'))

export default {
    namespaced: true,
    state: lastState || getInitialState(),
    actions: {
        // init({ commit }) {
        //     axios.get('module/main/index').then(r => commit('setModules', r.data))
        // },
        activateModule({ commit, rootGetters }, module) {
            return axios.post('/module/main/create', { moduleId: module.id }).then(r => {
                if (rootGetters.isOrganizationSelected) {
                    commit('organization/addModule', module, { root: true })
                } else {
                    commit('user/addModule', module, { root: true })
                }
            })
        },
    },
    mutations: {
        setModules(state, payload) {
            state.modules = payload.slice()
        },
    }
}