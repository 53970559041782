<template>
    <v-card>
        <v-card-subtitle>{{title}}</v-card-subtitle>
        <v-card-text>
            <v-text-field
              label="ФИО"
              v-model="value.name"
            />
            <v-text-field
              label="Организация"
              v-model="value.org"
            />
            <v-text-field
              label="Должность"
              v-model="value.post"
            />
            <v-text-field
              label="Дата"
              v-model="value.date"
            />
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: { title: String, value: Object },
}
</script>