import SimpleRoutes from './simple/routes.js'
import SpecialRoutes from './special/routes'
import store from '@/store/index.js'

export default {
    path: '/cards',
    component: () => import('@/layouts/main/Index.vue'),
    meta: { private: true },
    children: [
        {
            path: 'simple',
            component: { render: (c) => c('router-view') },    
            children: SimpleRoutes
        },
        {
            path: 'special',
            component: { render: (c) => c('router-view') },    
            children: SpecialRoutes
        },
    ]   
}