export default {
  path: '/user',
  component: { render: (c) => c('router-view') },
  children: [
    {
      path: 'account',
      component: () => import('./layouts/Blank.vue'),
      children: [
        {
          path: 'login',
          component: () => import('./views/account/Login.vue')
        },
        {
          path: 'signup',
          component: () => import('./views/account/Signup.vue')
        },
        {
          path: 'email-confirm',
          component: () => import('./views/account/EmailConfirm.vue')
        },
        {
          path: 'password-reset-request',
          component: () => import('./views/account/PasswordResetRequest.vue')
        },
        {
          path: 'password-reset',
          component: () => import('./views/account/PasswordReset.vue')
        },
      ]
    },
    {
      path: 'profile',
      meta: { private: true },
      component: () => import('@/layouts/main/Index.vue'),
      children: [
        {
          path: 'update',
          component: () => import('./views/profile/Update.vue')
        }
      ]
    },
    {
      path: 'modules',
      meta: { private: true },
      component: () => import('@/layouts/main/Index.vue'),
      children: [
        {
          path: 'index',
          component: () => import('./views/modules/Index.vue')
        }
      ]
    },
    {
      path: 'organizations',
      meta: { private: true },
      component: () => import('@/layouts/main/Index.vue'),
      children: [
        {
          path: 'index',
          component: () => import('./views/organizations/Index.vue')
        }
      ]
    },
  ]
}

