<template>
  <v-navigation-drawer
    :value="showMainMenu"
    absolute
    temporary
    style="padding-top: 64px; z-index: 4;"
    @input="!$event && setShowMainMenu(false)"
    >
    <v-list class="py-0">
      <v-list-item v-if="isAdmin" to="/admin">
        <v-list-item-title>Админ</v-list-item-title>
      </v-list-item>
      <v-list-item 
        v-for="(m, i) in modules" 
        class="py-0" 
        :key="'main-menu-' + i" 
        @click="selectModule(m)"
      >
        <v-list-item-title>{{ m.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  data: () => ({

  }),
  computed: {
    ...mapState(['showMainMenu']),
    ...mapGetters({ modules: 'getModules'}),
    ...mapGetters('user', ['isAdmin']),
    // ...mapState('organization', { orgFlag: 'selected' }),
    // modules() {
    //     if (this.$store.getters.getOrgId) {
    //       return this.$store.state.organization.modules
    //     }
    //     return this.$store.state.user.modules
    // },
  },
  methods: {
    ...mapMutations(['setShowMainMenu']), 
    ...mapActions(['selectModule']),
    // selectModule(m) {
    //   this.setShowMainMenu(false)
    //   if (this.orgFlag) {
    //     this.$store.dispatch('organization/selectModule', m)
    //   } else {
    //     this.$store.dispatch('user/selectModule', m)
    //   }
    //   if (m && m.indexUrl) {
    //       this.$router.push(m.indexUrl).catch(() => {})
    //   }
    // }
  }
}
</script>
