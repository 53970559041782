<template>
    <v-card>
        <v-card-subtitle>{{title}}</v-card-subtitle>
        <v-card-text>
            <TooltipComboboxField
                v-model="value.type"
                label="Тип"
                :items="elementList"
            />
            <div class="mt-2">
                <v-text-field
                    v-model="value.material"
                    label="Материал"
                />
            </div>
            <div class="mt-2">
                <v-text-field
                    label="Диаметр"
                    v-model="value.d"
                    hide-details
                />
            </div>
            <div class="mt-2">
                <v-text-field
                    label="Толщина"
                    v-model="value.s"
                    hide-details
                />
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import TooltipComboboxField from '@/components/TooltipComboboxField.vue'

export default {
    props: { value: Object, elementList: Array, title: String },
    components: { TooltipComboboxField },
}
</script>