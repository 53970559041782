import { _axios as axios } from '@/plugins/axios.js'

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    actions: {
    },
    mutations: {
    }
}
