import navbarItems from './navbarItems.js'

export default {
    path: '/registration',
    meta: { private: true },
    props: { navbarItems: navbarItems },
    component: () => import('@/layouts/main/Index.vue'),
    children: [
      {
        path: 'monitor',
        component: () => import('./views/Monitor.vue')
      },
      {
        path: 'index',
        component: () => import('./views/Index.vue')
      },
      {
        path: 'view',
        component: () => import('./views/View.vue')
      },
    ]
}  