<template>
  <v-card width="75%" class="mt-4 mx-auto" v-if="loaded">
    <v-card-title class="primary white--text justify-center">
      Регистрайция от {{secToDatetime(registration.createdAt)}}
    </v-card-title>
    <v-card-text class="mt-4">
        <v-text-field label="Название" v-model="registration.name" filled/>
        <v-textarea label="Описание" v-model="registration.description" rows="6" filled/>
        <div class="text-right">
            <v-btn color="success" @click="save">Сохранить</v-btn>
        </div>
        <div class="title text-center pt-4">Регистограммы</div>
        <div class="text-center" v-if="regFileLoaded">
        <div ref="charts"></div>
            <v-btn
                color="success"
                :to="registration.url"
                target="_blank"
                >
                скачать
            </v-btn>
        </div>
        <div v-else>
            <div class="subheading text-center">Регистограммы загружаются</div>
        </div>
    </v-card-text>
    <v-card-actions>
        <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { secToDatetime } from '@/libs/time.js'
import Highcharts from 'highcharts/highstock'
import pako from 'pako'

export default {
    data: () => ({
            loaded: false,
            regFileLoaded: false,
            registration: { name: '', description: ''},
            secToDatetime,
            chart: null,
    }),
    methods: {
        loadRegFile() {            
            this.$axios.get(this.registration.url, {
                baseURL: '/',
                responseType: 'arraybuffer'
            }).then(r => {
                this.name = r.data.name
                this.description = r.data.description
                this.regFileLoaded = true
                this.prepareCharts(r.data).then(options => {
                    // console.log(options)
                    this.chart = Highcharts.stockChart(this.$refs.charts, options)
                }).catch(() => {})
            }).catch(() => {})
        },
        prepareCharts(data) {
            const options = {
                rangeSelector: {
                    enabled: false,
                },
                chart: {
                    type: 'spline'
                },
                yAxis: [
                    { title: { text: 'Ток' }, opposite: false, crosshair: true },
                    { title: { text: 'Напряжение' }, opposite: true, crosshair: true },
                ],
                tooltip: {
                    headerFormat: '',
                    valueDecimals: 1,
                    split: false
                },
                series: [],
                legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'top',
                },
            }
            return new Promise((resolve, reject) => {
                const iSeries = {
                    name: 'Ток',
                    //pointInterval: 1,
                    data: []
                }
                const uSeries = {
                    name: 'Напряжение',
                    //pointInterval: 1,
                    yAxis: 1,
                    data: [],
                }
                const d = pako.inflate(data, { to: 'string' })
                const lines = d.split('\n')
                // const lines = data.split('\n')
                lines.forEach((line, i) => {
                    if (line && i > 2) {
                        // console.log(line)
                        var items = line.split(';')
                        // let timeParts = items[0].split('.')
                        // let t = (Number(timeParts[0]) * 1000) + (Number(timeParts[1]) / 1000)
                        iSeries.data.push([Number(items[0]), parseFloat(items[1])])
                        uSeries.data.push([Number(items[0]), parseFloat(items[2])])
                    }
                })
                options.series.push(iSeries)
                options.series.push(uSeries)
                resolve(options)
            })
        },
        save() {
            this.axios.post('/registration/main/update', { 
                name: this.registration.name,
                description: this.registration.description 
            }, { params: { id: this.registration.id }}).then(() => {})
        }
    },
    mounted() {
      this.axios.get('/registration/main/view', { params: { id: this.$route.query.id } }).then(r => {
        this.registration = r.data
        this.loadRegFile()
        this.loaded = true
      })

    }
}
</script>