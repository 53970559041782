<template>
  <v-card width="75%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Тех. процессы в очереди на согласование</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="px-0 pb-0">
      <TmplProcessesTable
        url="manufacture/process-template/index-agree"
        :key="tableUK"
        >
        <template v-slot:status="{ processTemplate }">
          <TmplProcessStatus :value="getTmplProcessStatus(processTemplate.status)"/>
        </template>
        <template v-slot:name="{processTemplate}">
          <router-link :to="cardViewUrl(processTemplate)">{{processTemplate.version.name}}</router-link>
        </template>
        <template v-slot:action="{ processTemplate }">
          <v-btn
            v-if="processTemplate.status == 16"
            icon
            color="primary"
            @click="showChatDialog(processTemplate)"
            >
            <v-icon>mdi-chat</v-icon>
          </v-btn>
        </template>
      </TmplProcessesTable>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import TmplProcessesTable from './components/TmplProcessesTable.vue'
import TmplProcessStatus from './components/TmplProcessStatus.vue'
import { tmplProcessStatuses } from '../../vars/index.js'

export default {
  components: { TmplProcessesTable, TmplProcessStatus },
  data: () => ({
    tableUK: 0,
  }),
  computed: {
    orgId() {
      return this.$store.state.organization.profile.id
    },
    userId() {
      return this.$store.state.user.profile.userId
    },
  },
  methods: {
    showChatDialog(processTemplate) {
      // this.chatDialogTitle = processTemplate.name
      // this.chatDialogProcessId = processTemplate.id
      // this.chatDialogShow = true
    },
    cardViewUrl(processTemplate) {
      if (processTemplate.status == 8 || processTemplate.status == 16) {
        if (processTemplate.createdBy == this.userId) {
          return '/manufacture/process-template-comment/correct?id=' + processTemplate.id
        }
      } else {
        if (processTemplate.agreederId == this.userId) {
          return '/manufacture/process-template-comment/agree?id=' + processTemplate.id
        }
      }
      return '/manufacture/process-template-comment/view?id=' + processTemplate.id
    },
    getTmplProcessStatus(status) {
      let res = tmplProcessStatuses.find(s => s.id == status)
      return res || { icon: 'mdi-help', text: 'Неизвестно', color: 'default' }
    },
  },
}
</script>
