<template>
  <v-card width="75%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Рабочие тех. процессы</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="px-0 pb-0">
      <TmplProcessesTable
        url="manufacture/process-template/index-active"
        :key="tableUK"
        >
        <template v-slot:select="{ processTemplate }">
          <v-checkbox :value="processTemplate.id" v-model="selectedProcesses"></v-checkbox>
        </template>
        <template v-slot:name="{processTemplate}">
          <router-link  :to="'/manufacture/process-template/view?id=' + processTemplate.id">
            {{processTemplate.version.name}}
          </router-link>
        </template>
      </TmplProcessesTable>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="success"
        :disabled="!selectedProcesses.length"
        @click="toArchive"
        >
        В архив
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TmplProcessesTable from './components/TmplProcessesTable.vue'

export default {
  components: { TmplProcessesTable },
  data: () => ({
    selectedProcesses: [],
    tableUK: 0,
  }),
  methods: {
    toArchive() {
      this.$axios.post('/manufacture/process-template/archive', {
        ids: this.selectedProcesses
      }).then(r => {
        this.selectedProcesses = []
        this.tableUK = Date.now()
      })
    }
  }
}
</script>
