import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import axios from '@/plugins/axios'

Vue.config.productionTip = false

import "@/assets/global.css"

new Vue({
  router,
  store,
  vuetify,
  axios,
  created() {
    // console.log('DEBUG: get all modules')
    // this.$store.dispatch('module/init')

    this.$store.dispatch('init')
    // if (localStorage.hasOwnProperty("lastRoute")) {
    //   this.$router.replace(localStorage.getItem('lastRoute'))
    // }
  },
  render: h => h('router-view')
}).$mount('#app')
