<template>
    <table class="table">
      <thead>
        <tr>
          <th colspan="8" class="th" style="background-color: white;">
            Режимы сварки и сварочные материалы
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="th" rowspan="2">Слой шва</th>
          <th class="th" rowspan="2">Полярность</th>
          <th class="th" rowspan="2">Св. материал</th>
          <th class="th" rowspan="2">Диаметр электрода, мм</th>
          <th class="th" colspan="2">Сварочный ток, А</th>
          <th class="th" colspan="2">Напряжение, В</th>
        </tr>
        <tr>
          <th class="th">Минимум</th>
          <th class="th">Максимум</th>
          <th class="th">Минимум</th>
          <th class="th">Максимум</th>
        </tr>
        <tr
          class="tmpl-hovered"
          :id="'modes' + l.num"
          @click="$emit('click', { fieldName: 'modes' + l.num, fieldTitle: 'Режимы сварки'})"
          v-for="l in layers"
          :key="'MTL_' + l.id"
          >
          <td class="th">{{getLayerNameById(l.layerNameId)}}</td>
          <td class="th">{{getPolarityNameById(l.polarityNameId)}}</td>
          <td class="th">{{l.weldMaterial}}</td>
          <td class="th">{{l.electrode}}</td>
          <td class="th">{{getCurrent(l.channels).min}}</td>
          <td class="th">{{getCurrent(l.channels).max}}</td>
          <td class="th">{{getVoltage(l.channels).min}}</td>
          <td class="th">{{getVoltage(l.channels).max}}</td>
        </tr>
      </tbody>
    </table>    
</template>

<script>
import { secToDatetime } from '@/libs/time.js'

export default {
  props: { layers: Array },
  data: () => ({
    layersNames: [],
    channelsNames: [], 
    polaritiesNames: [],
    secToDatetime,
  }),
  computed: {
    developer() {
      return `${this.card.developer.firstname} ${this.card.developer.middlename} ${this.card.developer.lastname}`
    },
  },
  methods: {
    getLayerNameById(id) {
      let layer = this.layersNames.find(l => l.id == id)
      return layer ? layer.name : 'Этого не должно было произойти'
    },
    getChannelNameById(channelId) {
      let channel = this.channelsNames.find(ch => ch.id === channelId)
      return channel ? channel.name : 'Этого не должно было произойти'
    },
    getPolarityNameById(id) {
      let pol = this.polaritiesNames.find(pn => pn.id == id)
      return pol ? pol.name : 'Этого не должно было произойти'
    },
    getCurrent(channels) {
      let c = channels.find(ch => Number(ch.channelId) === 1)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    },
    getVoltage(channels) {
      let c = channels.find(ch => Number(ch.channelId) === 2)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    }
  },
  mounted() {
    this.$axios.get('/cards/layer-name/index').then(r => this.layersNames = r.data.slice())
    this.$axios.get('/cards/channel-name/index').then(r => this.channelsNames = r.data.slice())
    this.$axios.get('/cards/polarity-name/index').then(r => this.polaritiesNames = r.data.slice())
  }
}
</script>

<style>
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  .table td {
    height: 22px;
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
  }
  .table th {
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
    text-align: center;
  }
</style>
