import Vue from 'vue'
import Store from '@/store'
import VueRouter from 'vue-router'
// BASE
import UserRoutes from '@/modules/user/routes.js'
import OrganizationRoutes from '@/modules/organization/routes.js'
// EXTRA
import AdminRoutes from '@/modules/admin/routes.js'
import DeviceRoutes from '@/modules/device/routes.js'
import RegistrationRoutes from '@/modules/registration/routes.js'
import ModuleRoutes from '@/modules/module/routes.js'
import ManufactureRoutes from '@/modules/manufacture/routes.js'
// import dictionary from '@/modules/dictionary/routes.js'

// CARDS
import CardsRoutes from '@/modules/cards/routes.js'
// import SpecialCardRoutes from '@/modules/specialCard/routes.js'
// import GostCard from '@/modules/gostCard/routes.js'
// import goCards from '@/modules/goCards/routes.js'


Vue.use(VueRouter)

const routes = [
// BASE    
    UserRoutes,
    OrganizationRoutes,
// EXTRA
    AdminRoutes,
    ModuleRoutes,
    DeviceRoutes,
    RegistrationRoutes,
    ManufactureRoutes,
    // dictionary,
// CARDS    
    CardsRoutes,
    // SpecialCardRoutes,
    // GostCard,
    // goCards,
//    
    // {
    //     path: '/modules',
    //     meta: { private: true },
    //     component: () => import ('@/layouts/main/Index.vue'),
    //     children: [{
    //         path: 'index',
    //             component: () => import ('@/views/modules/Index.vue')
    //         }, 
    //         {
    //             path: 'simple-card',
    //             component: () => import ('@/views/modules/components/simpleCard/Index.vue')
    //         },
    //         {
    //             path: 'special-card',
    //             component: () => import ('@/views/modules/components/specialCard/Index.vue')
    //         },
    //         {
    //             path: 'manufacture',
    //             component: () => import ('@/views/modules/components/manufacture/Index.vue')
    //         },
    //     ]
    // },
    {
        path: '/',
        meta: { private: true },
        component: () => import ('@/layouts/main/Index.vue'),
        children: [
            {
                path: 'test',
                component: () => import ('@/components/Test.vue')
            }
        ]
    },
    {
        path: '*',
        name: 'Not found',
        component: () =>
            import ('@/components/NotFound.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // console.log(from, to)
    var loggedIn = Store.state.user.loggedIn
    if (loggedIn && (to.path.includes('login') || to.path.includes('signup'))) {
        router.replace('/')
    } else if (!loggedIn && to.matched.some((route) => route.meta.private)) {
        next('/user/account/login')
    } else {
        next()
    }
})

router.afterEach((to, from, next) => {
    if (Store.state.user.loggedIn) {
        localStorage.setItem('lastRoute', to.fullPath)
    } else if (localStorage.hasOwnProperty("lastRoute")) {
        localStorage.removeItem('lastRoute')
    }
});

export default router