<template>
  <v-card width="75%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Тех. процессы ожидающие распределения</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/manufacture/process/create">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <ProcessesTable :queryParams="{ status: 23 }">
        <template v-slot:name="{ process }">
          <router-link :to="'/manufacture/process-template/view?id=' + process.templateId">
            {{process.template.version.name}}
          </router-link>
        </template>
        <template v-slot:action="{ process }">
          <v-btn color="blue" icon :to="'/manufacture/process-card/index?procId=' + process.id">
            <v-icon>mdi-clipboard-list</v-icon>
          </v-btn>
        </template>
      </ProcessesTable>
    </v-card-text>
  </v-card>
</template>

<script>
import ProcessesTable from './components/ProcessesTable.vue'

export default {
  components: { ProcessesTable },
  methods: {
  }
}
</script>
