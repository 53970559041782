export default [
  {
    path: 'index',
    component: () => import('./views/Index.vue')
  },
  {
    path: 'card/view',
    component: () => import('./views/View.vue')
  },
  {
    path: 'card/create',
    props: { mode: 'create' },
    component: () => import('./views/Create.vue')
  },
  {
    path: 'card/update',
    props: { mode: 'update' },
    component: () => import('./views/Create.vue')
  },
  {
    path: 'card/copy',
    props: { mode: 'copy' },
    component: () => import('./views/Create.vue')
  }
]
