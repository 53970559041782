<template>
  <v-card class="my-4" width="70%" style="margin-right: auto; margin-left: auto;">
    <ViewTmpl :cardId="Number($route.query.id)"/>
    <v-card-actions>
      <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="print(card)">Печать</v-btn>
      <v-btn color="primary" @click="exportDocx(card)">Экспорт .docx</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ViewTmpl from './components/ViewTmpl.vue'

export default {
  components: {
    ViewTmpl,
  },
  methods: {
    print() {
      this.$axios.get('/cards/special/print', {
        params: { id: this.$route.query.id },
        responseType: 'blob'
      }).then(r => {
        const file = new Blob([r.data],  { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      }).catch(() => {})
    },
    exportDocx() {
      this.$axios.get('/cards/special/export-docx', {
        params: { id: this.$route.query.id },
        responseType: 'blob'
      }).then(response => {
        let fileName = response.headers["content-disposition"].split("filename=")[1];
        fileName = fileName.substring(1, fileName.length - 1)
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
          ), fileName);
        } else {
          const url = window.URL.createObjectURL(new Blob(
            [response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
          ));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove()
        }
      }).catch(() => {})
    },
  },
}
</script>


