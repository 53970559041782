<template>
    <v-text-field
        :label="label"
        v-model="data"
        :hide-details="hideDetails"
        :clearable="clearable"
        @click:clear="$emit('input', '')"
    />    
</template>

<script>
export default {    
    props: {
        label: {
            type: String,
            default: "Поиск..."
        },
        timeout: {
            type: Number,
            default: 1500
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: true
        }, 
    },
    data() {
        return {
            data: '',
            timer: null,
        }
    },
    watch: {
      data(value) {
        if(value && value.length >= 3) {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => this.$emit('input', this.data), this.timeout)
        }
      },
    },
}
</script>  