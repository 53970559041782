<template>
  <v-card class="ma-5">
    <v-toolbar color="primary" dark>Монитор</v-toolbar>
    <v-card-text>
        <v-data-table
            :headers="headers"
            :items="workPlacesFront"
            :search="search"
            :expanded.sync="expanded"
            single-expand
            item-key="uuid"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-2">
                <v-row justify="center">
                  <v-col cols="12" class="text-center">
                    Процесс: {{ item.process ? item.process.name : '- - -' }}
                  </v-col>
                  <v-col cols="12" class="text-center">
                    Карта: {{ item.card ? item.card.name : '- - -' }}
                  </v-col>
                  <v-col cols="8">                    
                    <DeviationsTable 
                      title="Отклонения по карте" 
                      :deviations="item.card ? item.card.deviations : null"
                      :weldTime="item.card ? item.card.weldTime : 0"
                    />
                  </v-col>
                  <v-col cols="12" class="text-center">
                    Слой: {{ item.layer ? item.layer.num : '- - -' }}
                  </v-col>
                  <v-col cols="8">                    
                    <DeviationsTable 
                      title="Отклонения по слою"
                      :deviations="item.layer ? item.layer.deviations : null"
                      :weldTime="item.layer ? item.layer.weldTime : 0"
                    />
                  </v-col>
                </v-row>
            </td>
          </template>        
          <template v-slot:item.actions="{ item }">
            <v-btn class="mr-2" icon>
              <v-icon
                @click.stop="expand(item)"
              >
                mdi-dots-vertical
              </v-icon>
            </v-btn>
            <!-- <v-btn class="ml-2" icon>
              <v-icon
                @click.stop="view(item)"
              >
                mdi-page-next-outline
              </v-icon>
            </v-btn> -->
          </template>          
        </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import DeviationsTable from '../../components/DeviationsTable.vue'
import Paho from 'paho-mqtt'

export default {
  components: { DeviationsTable },
  data() {
    return {
        headers: [
            { text: 'Пост', value: 'name' },
            { text: 'Статус', value: 'status' },
            { text: 'I', value: 'I' },
            { text: 'U', value: 'U' },
            { text: 'Отклонения по карте', value: 'card.deviations.common' },
            { text: 'Отклонения по слою', value: 'layer.deviations.common' },
            { text: '', value: 'actions', align: 'end' },                        
        ],
        workPlacesBack: [],
        workPlacesFront: [],
        search: '',
        expanded: [],
        mqttClient: null,
        refreshTimer: null,
    }
  },
  methods: {
    view(item) {
      let route = this.$router.resolve({
        path: '/manufacture/work-place/view', 
        query: { uuid: item.uuid}
      })
      window.open(route.href, '_blank');      
    },
    expand(item) {
      if (this.expanded.length && this.expanded[0].uuid == item.uuid) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    refreshTable() {
      this.workPlacesBack.forEach(item => {
        if ((Date.now() - item.timestamp) > 5000) {
          item.status = 'Оффлайн'
        }
      })
      this.workPlacesFront = this.workPlacesBack.slice()
    },
    onMessage(message) {
      const deviceUuid = message.topic.split('/')[0];
      const deviceData = JSON.parse(message.payloadString)
      const index = this.workPlacesBack.findIndex((item) => item.uuid === deviceUuid)
      if (index === -1) return
      Object.assign(this.workPlacesBack[index], deviceData, { timestamp: Date.now() })
    },
    subscribe() {
      this.workPlacesBack.forEach(device => {
        const topic = device.uuid + '/data'
        this.mqttClient.subscribe(topic, { 
          // onSuccess: () => {},
          onFailure: (ctx, code, msg) => { console.log(msg) }, 
          qos: 0,
          timeout: 5, 
        })
      })
    },
    connect() {
        this.mqttClient.connect({
            onSuccess: () => {
                console.log('Connected')
                this.subscribe()
                this.refreshTimer = setInterval(this.refreshTable, 1000)
            },
            onFailure: (ctx, code, msg) => { console.log(msg) },
            reconnect: false
        })  
    },
    getDevices() {
      this.$axios.get('device/main/index', { params: { typeId: 4 }}).then(r => {
        r.data.items.forEach(device => {
          this.workPlacesBack.push(Object.assign({}, device, {
            uuid: device.uuid,
            name: device.name,
            status: 'Оффлайн',
            i: '',
            u: '',
            process: null,
            card: null,
            layer: null,
            timestamp: Date.now()
          }))
        })
        this.workPlacesFront = this.workPlacesBack.slice()
        this.connect()
      }).catch(() => {})      
    },
  },
  mounted() {
    this.mqttClient = new Paho.Client(
        'ws://weldassist.ru:1884/',
        'clientId-' + Math.random().toString(16).substr(2, 10)
    )
    this.mqttClient.onMessageArrived = this.onMessage
    this.mqttClient.onConnectionLost = this.connect
    this.getDevices()
  },
  beforeDestroy () {
    // this.unsubscribe()
    try{  this.mqttClient.disconnect() } catch(e) { console.log(e) }
    clearInterval(this.refreshTimer)
  }
}
</script>
