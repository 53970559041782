import navbarItems from './navbarItems.js'

export default {
  path: '/manufacture',
  meta: { private: true },
  props: { navbarItems: navbarItems },
  component: () => import('@/layouts/main/Index.vue'),
  // redirect: '/manufacture/index',
  children: [
    {
      path: 'index',
      redirect: '/manufacture/monitor'
      // redirect: '/manufacture/card/index-accept'
    },
    {
      path: 'monitor',
      component: () => import('./views/monitor/Index.vue')
    },
    /////////////////////////// CARDS //////////////////////////
    {
      path: 'card/create',
      component: () => import('./views/card/Create.vue')
    },
    {
      path: 'card/index-accept',
      component: () => import('./views/card/IndexAccept.vue')
    },
    {
      path: 'card/index-agree',
      component: () => import('./views/card/IndexAgree.vue')
    },
    {
      path: 'card/index-active',
      component: () => import('./views/card/IndexActive.vue')
    },
    {
      path: 'card/index-archive',
      component: () => import('./views/card/IndexArchive.vue')
    },
    {
      path: 'card-comment/view',
      component: () => import('./views/card-comment/View.vue')
    },
    {
      path: 'card-comment/accept',
      component: () => import('./views/card-comment/Accept.vue')
    },
    {
      path: 'card-comment/correct',
      component: () => import('./views/card-comment/Correct.vue')
    },
    {
      path: 'card-comment/agree',
      component: () => import('./views/card-comment/Agree.vue')
    },
    ///////////////////////// PROCESSES TEMPLATES //////////////////////
    {
      path: 'process-template/create',
      component: () => import('./views/process-template/Create.vue')
    },
    {
      path: 'process-template/update',
      props: { mode: 'update' },
      component: () => import('./views/process-template/Create.vue')
    },
    {
      path: 'process-template/index-accept',
      component: () => import('./views/process-template/IndexAccept.vue')
    },
    {
      path: 'process-template/index-agree',
      component: () => import('./views/process-template/IndexAgree.vue')
    },
    {
      path: 'process-template/index-active',
      component: () => import('./views/process-template/IndexActive.vue')
    },
    {
      path: 'process-template/index-archive',
      component: () => import('./views/process-template/IndexArchive.vue')
    },
    {
      path: 'process-template/view',
      component: () => import('./views/process-template/View.vue')
    },
    {
      path: 'process-template-comment/view',
      component: () => import('./views/process-template-comment/View.vue')
    },
    {
      path: 'process-template-comment/accept',
      component: () => import('./views/process-template-comment/Accept.vue')
    },
    {
      path: 'process-template-comment/correct',
      component: () => import('./views/process-template-comment/Correct.vue')
    },
    {
      path: 'process-template-comment/agree',
      component: () => import('./views/process-template-comment/Agree.vue')
    },
    ///////////////////////// PROCESSES ///////////////////////////////
    {
      path: 'process/create',
      component: () => import('./views/process/Create.vue')
    },
    {
      path: 'process/index-wait-appoint',
      component: () => import('./views/process/IndexWaitAppoint.vue')
    },
    {
      path: 'process/index-in-queue',
      component: () => import('./views/process/IndexInQueue.vue')
    },
    {
      path: 'process/index-in-progress',
      component: () => import('./views/process/IndexInProgress.vue')
    },
    {
      path: 'process/index-done',
      component: () => import('./views/process/IndexDone.vue')
    },
    {
      path: 'process/index-archive',
      component: () => import('./views/process/IndexArchive.vue')
    },
    {
      path: 'process/view',
      component: () => import('./views/process/View.vue')
    },
    {
      path: 'process-card/index',
      component: () => import('./views/process-card/Index.vue')
    },
    {
      path: 'process-card/view',
      component: () => import('./views/process-card/View.vue')
    },
    {
      path: 'process-card-layer/view',
      component: () => import('./views/process-card-layer/View.vue')
    },
    {
      path: 'report/staff/index',
      component: () => import('./views/report/staff/Index.vue')
    },
    {
      path: 'report/staff/view',
      component: () => import('./views/report/staff/View.vue')
    },
  ]
}

const cardsRoutes = [
]
