<template>
  <v-card width="60%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Сотрудники</v-toolbar-title>
    </v-toolbar>
    <!-- <UpdateDialog
      v-if="updateDialogShow"
      :id="updateDialogId"
      :role="updateDialogRole"
      :position="updateDialogPosition"
      @close="updateDialogShow = false"
      @input="getStaff()"
    /> -->
    <v-card-text>
      <v-row justify="space-between">
        <v-col cols="5">
          <TimeoutedField
            label="Поиск сотрудника по ФИО..."
            v-model="fullName"
            hide-details
            @input="getStaff()"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            label="Должность"
            v-model="positionId"
            :items="positionsNames"
            item-value="id"
            item-text="name"
            clearable
            @input="getStaff()"            
          />
        </v-col>
        <v-col cols="3">
          <v-select
            :items="statusItems"
            v-model="status"
            label="Статус"
            @input="getStaff()"
          />
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th style="width: 50px;"></th>
            <th class="text-left">ФИО</th>
            <th class="text-left">Должность</th>
            <th class="pa-0" width="10"></th>
            <th class="pa-0" width="10"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in staff" :key="s.id">
            <td>
              <img v-if="s.thumbnail" :src="s.thumbnail" width="50px"/>
              <v-icon v-else size="50px">mdi-account</v-icon>
            </td>
            <td>{{ s.fullName }}</td>
            <td>{{ s.positionName }}</td>
            <td class="pa-0 pr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon color="success" text small :disabled="s.status != 6"  @click="acceptStaff(s.id)" v-on="on">
                    <v-icon>mdi-login</v-icon>
                  </v-btn>
                </template>
                <span>Принять сотрудника</span>
              </v-tooltip>
            </td>
            <td class="pa-0 pr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon color="error" text small :disabled="!(s.status == 2)"  @click="deleteStaff(s.id)" v-on="on">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Удалить сотрудника</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import UpdateDialog from './components/UpdateDialog.vue'
import TimeoutedField from '@/components/TimeoutedField.vue'

export default {
  components: { TimeoutedField, UpdateDialog },
  data: () => ({
    positionsNames: [],
    staff: [],
    fullName: null,
    positionId: null,
    status: 2,

    updateDialogShow: false,
    updateDialogStaff: null,
    statusItems: [
      { text: 'Активные', value: 2 },
      { text: 'Новые', value: 6 },
      { text: 'Удаленные', value: 5 },
    ]
  }),
  computed: {
    isAdmin() {
      return this.$store.getters['user/isAdmin']
    },
  },
  methods: {
    acceptStaff(id) {
      this.$axios.post('organization/staff/accept', { id: id }).then(() => { this.getStaff() })
    },
    deleteStaff(id) {
      this.$axios.post('organization/staff/delete', { id: id }).then(() => { this.getStaff() })
    },
    getStaff() {
      this.staff= []
      this.$axios.get('organization/staff/index', { params: {
        fullName: this.fullName,
        positionId: this.positionId,
        status: this.status
      }}).then(r => {
        this.staff = r.data.items.slice()
      })
    }
  },
  mounted() {
    this.$axios.get('organization/positions/index').then(r => this.positionsNames = r.data.slice())
    this.getStaff()
  }
}
</script>
