<template>
  <v-card width="75%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      {{ title }}
    </v-toolbar>
    <v-card-text>
        <v-row>
          <v-col cols="6">
            <PersoneField v-model="card.agreeder" title="Согласовал"/>
          </v-col>
          <v-col cols="6">
            <PersoneField v-model="card.accepter" title="Утвердил"/>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="ID/название/номер карты"
              v-model="card.name"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              auto-grow
              filled
              rows="4"
              row-height="30"
              label="Краткое описание технологической карты"
              v-model="card.about"
            />
          </v-col>
          <v-col cols="2">
            <TooltipComboboxField
              v-model="card.weldMethod"
              label="Способ сварки"
              :items="weldMethodList"
            />
          </v-col>
          <v-col cols="3">
            <TooltipComboboxField
              label="Норм. документ"
              v-model="card.normDocument"
              :items="normDocumentList"
            />
          </v-col>
          <v-col cols="2">
            <JointTypeCombobox
              v-model="card.jointType"
              label="Тип соединения"
              :queryParams="{ pageSize: 5 }"
              @select="jointTypeSelect"
              @clear="jointTypeClear"
            />
          </v-col>
          <v-col cols="2">
            <TooltipComboboxField
              v-model="card.seamType"
              label="Тип шва"
              :items="seamTypeList"
            />
          </v-col>
          <v-col cols="2">
            <TooltipComboboxField
              v-model="card.seamKind"
              label="Вид шва"
              :items="seamKindList"
            />
          </v-col>
          <v-col cols="3">
            <TooltipComboboxField
              v-model="card.weldPosition"
              label="Положение стыка"
              :items="weldPositionList"
            />
          </v-col>
          <v-col cols="9">
            <v-text-field
              label="Прочие параметры"
              v-model="card.others"
            />
          </v-col>
          <v-col cols="6">
            <ElementField v-model="card.element1" :elementList="elementList" title="Элемент 1"/>
          </v-col>
          <v-col cols="6">
            <ElementField v-model="card.element2" :elementList="elementList" title="Элемент 2"/>
          </v-col>
          <v-col cols="12">
            <FilesField v-model="card.files"/>
          </v-col>
          <v-col cols="12">
            <LayerField v-model="card.layers"/>
          </v-col>
        </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="create" :loading="loading" :disabled="loading">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/libs/validate-rules.js'
import PersoneField from '@/modules/cards/components/PersoneField.vue' 
import FilesField from '@/components/FilesField.vue'
import LayerField from '@/modules/cards/components/LayerField.vue'
import ElementField from '@/modules/cards/components/ElementField.vue'
import TooltipComboboxField from '@/components/TooltipComboboxField.vue'
import JointTypeCombobox from '@/modules/cards/components/JointTypeCombobox.vue'

const testCard = {
  accepter: { name: "Khurin",  org: "Delta 1", post: "Constructor", date: "22.02.1984" },
  agreeder: { name: "Smirnov", org: "Delta 2", post: "Director", date: "19.05.1977" },
  name: "TEST CARD NAME",
  about: "Something about card and it destenee",
  files: [],
  weldMethod: "РД",
  normDocument: "ГОСТ 5264-80",
  jointType: "С1",
  jointUrl: "",
  seamUrl: "", 
  seamType: "СШ",
  seamKind: "Ос, сп",
  weldPosition: "Н1",
  others: "Прочее параметры",
  element1:  { type: "Труба (круглая) (Т)", material: "09Г2С", d: "100", s: "4" },
  element2:  { type: "Труба (круглая) (Т)", material: "Ст3", d: "101", s: "5" },
  layers: [
    { num: 1, layerNameId: 2, weldMaterial: "M0", polarityNameId: 1, electrode: 3, channels: [
      { channelId: 1, min: 50, max: 70},
      { channelId: 2, min: 20, max: 25}
    ]},
    { num: 2, layerNameId: 3, weldMaterial: "M1", polarityNameId: 1, electrode: 3.2, channels: [
      { channelId: 1, min: 60, max: 80},
      { channelId: 2, min: 20, max: 25}
    ]}
  ],
}

const emptyCard = {
  // CARD FORM FOR ALL
  accepter: { name: '', org: '', post: '', date: '' },
  agreeder: { name: '', org: '', post: '', date: '' },
  name: '' ,
  element1: { type: '', material: '', d: '', s: '' },
  element2: { type: '', material: '', d: '', s: '' },
  weldMethod: '',
  seamType: '',
  seamKind: '',
  jointType: '',
  weldMaterial: '',
  weldPosition: '',
  others: '',
  about: '',
  files: [],
  layers: [],
}

export default {
  components: {
    PersoneField,
    TooltipComboboxField,
    JointTypeCombobox,
    FilesField,
    LayerField,
    ElementField,
  },
  props: {
    mode: {
      type: String,
    },
  },
  data() {
    return {
      weldMethodList: [], 
      jointTypeList: [], 
      normDocumentList: [],
      seamTypeList: [],
      seamKindList: [],
      weldPositionList: [],
      elementList: [],

      rules: rules,
      dataValid: false,
      loading: false,
      card: testCard,
    }
  },
  computed: {
    title() {
      if (this.mode == 'copy') {
        return 'Создание копии карты режимов'
      } else if (this.mode == 'update') {
        return 'Редактирование карты режимов'
      } else {
        return 'Создание простой карты режимов'
      }
    }
  },
  methods: {
    jointTypeSelect(e) {
      this.card.jointUrl = e.edge
      this.card.seamUrl = e.seam
    },
    jointTypeClear() {
      this.card.jointType = ''
      this.card.jointUrl = ''
      this.card.seamUrl = ''
    },
    create() {
      this.loading = true
      let url = '/cards/simple/card/create'
      if (this.mode == 'update') {
        url = '/cards/simple/card/update?id=' + this.$route.query.id
      }
      this.$axios.post(url, { ...this.card }).then(res => {
        this.$router.go(-1)
      }).catch(() => {}).finally(() => { this.loading = false })
    }
  },
  mounted() {
    this.$axios.get('cards/weld-method/index').then(r => this.weldMethodList = r.data.slice())
    this.$axios.get('cards/norm-document/index').then(r => this.normDocumentList = r.data.slice())
    this.$axios.get('cards/seam-type/index').then(r => this.seamTypeList = r.data.slice())
    this.$axios.get('cards/seam-kind/index').then(r => this.seamKindList = r.data.slice())
    this.$axios.get('cards/weld-position/index').then(r => this.weldPositionList = r.data.slice())
    this.$axios.get('cards/weld-element/index').then(r => this.elementList = r.data.slice())
    if (this.mode == 'copy' || this.mode == 'update') {
      this.$axios.get('cards/simple/card/view?id=' + this.$route.query.id).then(r => {
        this.card = r.data.version
        if (this.mode == 'copy') {
          this.card.name = ''
        }
      })
    }
  }
}
</script>
