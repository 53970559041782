<template>
    <v-combobox
        label="Сварочное оборудование"
        :search-input.sync="search"
        :items="items"
        item-text="name"
        :return-object="true"
        multiple
        chips
        autocomplete="false"
        :loading="loading"
        :value="value"
        @input="input"
    >
        <template v-slot:item="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.name }}</v-list-item>
                </template>
                <span>{{item.description}}</span>
            </v-tooltip>
        </template>
    </v-combobox>
</template>

<script>
export default {
    props: { value: Array },
    data() {
        return {
            search: null,
            lastSearch: null,
            searchRequestTimeout: null,
            items: [],
            loading: false,
        }
    },
    methods: {
        query(v) {
            this.loading = true
            this.items = []
            this.axios.get('cards/weld-equipment/index', { params: { name: v }})
                .then(r=> {
					this.items = r.data.items.slice()
				}).finally(() => this.loading = false)
        },
        input(e) {
            const l = e.pop()
            if(typeof l == "string") {
                e.push(Object.assign({}, { name: l }));            
            } else if(typeof l == "object") {
                e.push(l);            
            }
            this.$emit('input', e)
            this.search = ''
        }
    },
    watch: {
        search(v) {
            if (!v || v.length < 2) return
            clearTimeout(this.searchRequestTimeout);
            this.searchRequestTimeout = setTimeout(() => this.query(v), 1000);
        },
    }
}
</script>