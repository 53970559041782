<template>
	<v-dialog value="true" scrollable persistent max-width="30%">
		<v-card>
			<v-card-text>
				<OrganizationSearchField
					v-model="value.base"
					label="База"
					:queryParams="{ scope: 1 }"
				/>
				<StaffSearchField
					v-model="value.welder"
					:queryParams="{ roles: 1 }"
					label="Сварщик"
				/>
				<DeviceSearchField
					v-model="value.device"
				/>
			</v-card-text>
			<v-card-actions>
			  <v-btn color="default" @click="close">отмена</v-btn>
			  <v-spacer></v-spacer>
			  <v-btn color="success" @click="select">Далее</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import StaffSearchField from '@/modules/organization/components/StaffSearchField.vue'
import OrganizationSearchField from '@/modules/organization/components/OrganizationSearchField.vue'
import DeviceSearchField from '@/modules/device/components/DeviceSearchField.vue'

export default {
  components: { StaffSearchField, OrganizationSearchField, DeviceSearchField },
	props: {
		value: {
			type: Object,
		},
		cardsIds: {
			type: Array,
			required: true
		}
	},
	methods: {
		select() {
			this.$axios.post('/manufacture/process-card/appoint', {
				cardsIds: this.cardsIds,
				baseOrgId: this.value.base.id,
				welderId: this.value.welder.userId,
				deviceUuid: this.value.device.uuid,
			}).then(r => {
				this.$emit('input', this.value)
				this.$emit('close')
			})
		},
		close() {
			this.$emit('close')
		}
	},
}
</script>
