<template>
  <CommentCardView>
    <template v-slot:title="{ card }">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          Согласование карты: {{card.name}}
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:actions="{ card }">
      <v-card-actions>
        <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="disableAgree(card)"
          @click="agree(card)"
          >
          Согласовать
        </v-btn>
        <v-btn
          color="error"
          :disabled="disableCorrect(card)"
          @click="sendCorrect(card)"
          >
          Отправить на исправление
        </v-btn>
      </v-card-actions>
    </template>
  </CommentCardView>
</template>

<script>
import CommentCardView from './components/CommentCardView.vue'

export default {
  components: { CommentCardView },
  data: () => ({}),
  methods: {
    disableAgree(card) {
      return card.privateChat.some(c => (c.status == 1) || (c.status == 22))
      // let fn = ''
      // let c = card.privateChat.find(c => {
      //   if (c.fieldName != fn) {
      //     fn = c.fieldName
      //     return c.status != 2
      //   }
      // })
      // return !!c
    },
    disableCorrect(card) {
      return !card.privateChat.some(c => c.status == 22)
      // let fn = ''
      // let c = card.privateChat.find(c => {
      //   if (c.fieldName != fn) {
      //     fn = c.fieldName
      //     return c.status == 4
      //   }
      // })
      // return !c
    },
    agree(card) {
      this.$axios.post('manufacture/card/agree', {
        id: card.id,
      }).then(r => {
        this.$router.go(-1)
      }).catch(() => {})
    },
    sendCorrect(card) {
      this.$axios.post('manufacture/card/agree-to-correct', {
        id: card.id
      }).then(r => {
        this.$router.go(-1)
      }).catch(() => {})
    },
  },
}
</script>
