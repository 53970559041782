<template>
  <v-card>
    <v-card-title>
      Технические требования
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="Требования к подготовке кромок"
            filled
            hide-details
            v-model="value.edgeRequire"
            @input="setCard({ edge: $event })"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Требования к сборке под сварку"
            filled
            hide-details
            v-model="value.assemblyRequire"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Требования к прихватке"
            filled
            hide-details
            v-model="value.tackweldRequire"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Технологические требования к сварке"
            filled
            hide-details
            v-model="value.weldRequire"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: { value: Object },
}
</script>
