<template>
  <v-card class="ma-5">
    <v-toolbar color="primary" dark>Монитор устройств</v-toolbar>
    <v-card-text>
        <v-data-table
            :headers="headers"
            :items="devicesFront"
            :search="search"
            :expanded.sync="expanded"
            single-expand
            item-key="uuid"
        >
          <!-- <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
            </td>
          </template>        
          <template v-slot:item.actions="{ item }">
            <v-btn icon>
              <v-icon
                @click.stop="expand(item)"
              >
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>           -->
        </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Paho from 'paho-mqtt'

export default {
  data() {
    return {
      mqttClient: null,
      headers: [
          { text: 'Пост', value: 'name' },
          { text: 'Статус', value: 'status' },
          { text: 'I', value: 'i' },
          { text: 'U', value: 'u' },
          { text: '', value: 'actions', sortable: false, align: 'end' },                        
      ],
      devicesBack: [],
      devicesFront: [],
      search: '',
      expanded: [],
      refreshTimer: null,
    }
  },
  methods: {
    expand(item) {
      if (this.expanded.length && this.expanded[0].uuid == item.uuid) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    refreshTable() {
      this.devicesBack.forEach(item => {
        if ((Date.now() - item.timestamp) > 5000) {
          item.status = 'Оффлайн'
        }
      })
      this.devicesFront = this.devicesBack.slice()
    },
    onMessage(message) {
        const deviceUuid = message.topic.split('/')[0]
        // console.log('MQTT: Message from: ' + deviceUuid)
        const deviceData = JSON.parse(message.payloadString)
        const index = this.devicesBack.findIndex((item) => item.uuid === deviceUuid)
        if (index === -1) return
        Object.assign(this.devicesBack[index], deviceData, { timestamp: Date.now() })
    },
    subscribe() {
      this.devicesBack.forEach(device => {
        const topic = device.uuid + '/registration/data'
        this.mqttClient.subscribe(topic, { 
          onSuccess: () => { console.log('MQTT: Subscribed to: ' + topic) },
          onFailure: (ctx, code, msg) => { console.log(msg) }, 
          qos: 0,
          timeout: 5, 
        })
      })
    },
    connect() {
        this.mqttClient.connect({
            onSuccess: () => {
                console.log('MQTT: Connected OK')
                this.subscribe()
                this.refreshTimer = setInterval(this.refreshTable, 1000)
            },
            onFailure: (ctx, code, msg) => { console.log(msg) },
            reconnect: false
        })  
    },
    getDevices() {
      this.$axios.get('device/main/index', { params: { typeId: 3 }}).then(r => {
        r.data.items.forEach(device => {
          this.devicesBack.push(Object.assign({}, device, {
            uuid: device.uuid,
            name: device.name,
            status: 'Оффлайн',
            i: '',
            u: '',
            processCommonDeviations: null,
            cardCommonDeviations: null,
            layerCommonDeviations: null,
            timestamp: Date.now()
          }))
        })
        this.devicesFront = this.devicesBack.slice()
        this.connect()
      }).catch(() => {})      
    },
  },
  mounted() {
    this.mqttClient = new Paho.Client(
        'ws://weldassist.ru:1884/',
        'clientId-' + Math.random().toString(16).substr(2, 10)
    )
    this.mqttClient.onMessageArrived = this.onMessage
    this.mqttClient.onConnectionLost = this.connect
    this.getDevices()
  },
  beforeDestroy () {
    try{  this.mqttClient.disconnect() } catch(e) {}
    clearInterval(this.refreshTimer)
  }
}
</script>
