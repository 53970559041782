<template>
  <v-card width="65%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      {{title}}
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field 
            label="UUID" 
            v-model="device.uuid"
            :disabled="!create"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field 
            label="Название" 
            v-model="device.name"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field 
            label="Место нахождения" 
            v-model="device.location"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="default" to="/device/index">Назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="$emit('input', value)">{{ create ? 'Создать' : 'Сохранить' }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Создание устройства'
        },
        value: {
            type: Object,
            default: () => ({ uuid: '', name: '', location: '' })
        },
        create: {
          type: Boolean,
          default: false
        }
    },
    data() {
        return {
            device: this.value
        }
    }
}
</script>