<template>
  <v-card class="ma-5">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Устройства</v-toolbar-title>
      <v-spacer/>
      <v-btn text to="create">
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <TimeoutedField
        label="Поиск..."
        v-model="searchDevice"
      />
      <v-simple-table v-if="devices.length > 0" :key="tableKey">
        <template v-slot:default>
          <tbody>
            <tr v-for="item in devices" :key="item.uuid">
              <td v-if="verificatorFlag">{{ item.uuid }}</td>
              <td v-else>
                <router-link :to="'/device/view?deviceUuid=' + item.uuid">{{ item.uuid }}</router-link>
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.location }}</td>
              <td class="pa-0 text-right" width="350">
                <v-btn 
                  class="mr-2" 
                  color="warning" 
                  tile 
                  outlined 
                  :to="'/device/verification/index?deviceUuid=' + item.uuid"
                >
                  Поверка
                </v-btn>
                <v-btn color="error" tile outlined>
                  Откл.
                </v-btn>
              </td>              
            </tr>
          </tbody>
        </template>        
      </v-simple-table>
      <div class="text-center" v-else>
        Устройств не найдено 
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import TimeoutedField from '@/components/TimeoutedField.vue'

export default {
  components: { TimeoutedField },
  data() {
    return {
      searchDevice: '',
      devices: [],
    }
  },
  computed: {
    verificatorFlag() {
      return this.$store.state.user.roles.includes('verificator')
    },
  },
  methods: {
    query() {
      this.devices = []
      this.axios.get('/admin/device/index', { params: { uuid: this.searchDevice } }).then(r => {
        this.devices = r.data.items.slice()
        this.tableKey = Date.now()
      })
    },
  },
  watch: {
    searchDevice() {
      this.query()
    }
  },
  mounted() {
    this.query()
  }
}
</script>