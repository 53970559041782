import router from '@/router/index.js'
import { _axios as axios } from '@/plugins/axios.js'

function getInitialState() {
    return {
        selected: false,
        profile: {},
        activeModule: {},
        modules: [],
        devices: [],
    }
}

const lastOrg = JSON.parse(localStorage.getItem('organization'))

export default {
    namespaced: true,
    state: lastOrg || getInitialState(),
    getters: {
        title: state => state.profile.title,
        orgId: state => state.selected ? state.profile.id : null
    },
    actions: {
        login({ dispatch, commit, state }, orgId) {
            dispatch('logout').then(() => {
                axios.post('organization/account/login', { orgId: orgId }).then(r => {
                    commit('login', r.data)
                    localStorage.setItem('organization', JSON.stringify(state))
                    router.push('/').catch(() => {})
                })
            })
        },
        logout({ state, commit }) {
            if (state.selected) {
                return axios.post('organization/account/logout').then(() => {
                    commit('logout')
                    localStorage.removeItem('organization')
                })
            }
        },
    },
    mutations: {        
        addModule(state, module) {
            state.modules.push(module)
            localStorage.setItem('organization', JSON.stringify(state))
        },
        selectModule(state, module) {
            state.activeModule = module
            localStorage.setItem('organization', JSON.stringify(state))
        },
        setProfile(state, profile) {
            state.profile = profile
        },
        login(state, data) {
            Object.keys(state).forEach(key => {
                if (data.hasOwnProperty(key)) {
                    state[key] = data[key]
                }
            })
            state.selected = true
        },
        logout(state) {
            const d = getInitialState()
            Object.keys(state).forEach(key => {
                if (d.hasOwnProperty(key)) {
                    state[key] = d[key]
                } else {
                    delete state[key]
                }
            })
        },
    }
}