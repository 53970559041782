<template>
  <v-card>
    <v-card-title>
      Сварочное оборудование и дополнительные операции
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <WeldEquipmentCombobox v-model="value.weldEquipments"/>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Предварительный подогрев"
            filled
            hide-details
            rows="4"
            v-model="value.preHeat"
          />
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-subtitle>Послесварочная термическая обработка</v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Вид обработки"
                    v-model="value.heatTreatmentType"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Условия охлаждения"
                    v-model="value.heatTreatmentCooling"
                  />
                </v-col>
                <v-col cols="12">
                  <HeatTreatmentEquipment v-model="value.heatTreatmentEquipments"/>
                </v-col>
                <v-col cols="12">
                  <v-card>
                    <v-card-subtitle>Циклы термообработки</v-card-subtitle>
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="3" v-for="(cycle, i) in value.heatTreatmentCycles" :key="i">
                          <v-card>
                            <v-card-title>Цикл {{i + 1}}</v-card-title>
                            <v-card-text>
                                <v-text-field
                                  label="Температура"
                                  hide-details
                                  v-model.number="cycle.temperature"
                                />
                                <v-text-field
                                  label="Выдержка, с"
                                  hide-details
                                  v-model.number="cycle.delay"
                                />
                                <v-text-field
                                  label="Описание"
                                  hide-details
                                  v-model="cycle.text"
                                />
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer/>
                              <v-btn color="red" dark @click="removeCycle(i)">Удалить</v-btn>
                              <v-spacer/>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                        <v-col cols="2">
                          <v-btn color="success" @click="addCycle">Добавить</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import WeldEquipmentCombobox from '@/modules/cards/components/WeldEquipmentCombobox.vue'
import HeatTreatmentEquipment  from '@/modules/cards/components/HeatTreatmentEquipment.vue'
export default {
  props: { value: Object },
  components: { WeldEquipmentCombobox, HeatTreatmentEquipment },
  data() {
    return {
      weldEquipmentItems: [],
      postHeatEquipmentItems: [],
    }
  },
  methods: {
    removeCycle(i) {
      this.value.heatTreatmentCycles.splice(i, 1)
    },
    addCycle() {
      this.value.heatTreatmentCycles.push(Object.assign({}, { temperature: '', delay: '', text: '' }))
    }
  },
}
</script>
