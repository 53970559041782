import router from '@/router/index.js'
import { _axios as axios } from '@/plugins/axios.js'

function getInitialState() {
    return {
        loggedIn: false,
        profile: {},
        modules: [],
        devices: [],
        activeModule: {},
        roles: [],
    }
}

const lastUser = JSON.parse(localStorage.getItem('user'))

export default {
    namespaced: true,
    state: lastUser || getInitialState(),
    getters: {
        // name: state => `${state.profile.firstname} ${state.profile.middlename} ${state.profile.lastname}`,
        name: state => `${state.profile.firstname} ${state.profile.lastname}`,
        image: state => state.profile.image || null,
        thumbnail: state => state.profile.thumbnail || null,
        isAdmin: () => false
        // isAdmin: state => state.roles.includes('admin'),
    },
    actions: {
        updateProfile({ state, commit }, profile) {
            commit('updateProfile', profile)
            localStorage.setItem('user', JSON.stringify(state))
        },
        // selectModule({ state, commit }, module) {
        //     commit('selectModule', module)
        //     localStorage.setItem('user', JSON.stringify(state))
        // },
        login({ state, commit, dispatch }, userData) {
            axios.post('user/account/login', userData).then(r => {
                dispatch('init', null, { root: true }).then(() => {
                    commit('login', r.data)
                    router.push('/')
                })
            })
        },
        logout({ commit }) {
            commit('logout')
            localStorage.removeItem('lastRoute')
            window.location.href = '/'
        },
    },
    mutations: {
        // addDevice(state, device) {
        //     state.devices.push(device)
        // },
        setProfile(state, profile) {
            state.profile = profile
        },
        addModule(state, moduleId) {
            state.modules.push(moduleId)
            localStorage.setItem('user', JSON.stringify(state))
        },
        selectModule(state, module) {
            state.activeModule = module
            localStorage.setItem('user', JSON.stringify(state))
        },
        updateProfile(state, data) {
            state.profile = data
        },
        login(state, data) {
            Object.keys(state).forEach(key => {
                if (data.hasOwnProperty(key)) {
                    state[key] = data[key]
                }
            })
            state.loggedIn = true
            localStorage.setItem('user', JSON.stringify(state))
        },
        logout(state) {
            const d = getInitialState()
            Object.keys(state).forEach(key => {
                if (d.hasOwnProperty(key)) {
                    state[key] = d[key]
                } else {
                    delete state[key]
                }
            })
            localStorage.removeItem('user')
            localStorage.removeItem('organization')
        },
    }
}
