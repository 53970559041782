<template>
  <v-card>
    <v-card-title>
      Режимы сварки и материалы
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <LayerField v-model="value.layers"/>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Дополнителные параметры"
            filled
            hide-details
            rows="5"
            v-model="value.others"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import LayerField from '@/modules/cards/components/LayerField.vue'

export default {
  props: { value: Object },
  components: { LayerField },
}
</script>
