<template>            
  <v-menu offset-y v-model="showMenu">
    <template v-slot:activator="{ on }">
      <v-text-field 
        :value="value" 
        :label="label"
        :rules="rules"
        :loading="loading" 
        autocomplete="off"
        hide-details 
        v-on="on"
        @input="input"	
      />
    </template>
    <v-list max-height="300" class="overflow-y-auto">
      <v-list-item v-for="(item, index) in items" :key="index" @click="select(item)">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.name }}</v-list-item>
                    <img :src="item.thumbnail" height="75"/>
                </template>
                <span>{{item.description}}</span>
            </v-tooltip>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
    props: { label: String, value: String, rules: Array },
    data() { return { items: [], showMenu: false, loading: false }},
    methods: {
        query(v) {
            this.loading = true
            this.axios.get('cards/weld-order/index', { params: { name: v }})
                .then(r=> {
					this.items = r.data.items.slice()
					this.showMenu = true
				}).finally(() => this.loading = false)
        },
        input(v) {
            this.$emit('input', v)
            if (v.length < 2) return
            clearTimeout(this.searchRequestTimeout);
            this.searchRequestTimeout = setTimeout(() => this.query(v), 1000);
        },
        select(i) {
            this.$emit('input', i.name)
            this.$emit('data', { url: i.url, text: i.text})
        }
    },
}
</script>