<template>
  <div style="cursor: default;">
    <v-list-item two-line class="pa-0 ma-0 text-right">
      <v-list-item-content>
        <v-list-item-title>{{userName || 'И как ты сюда попал?'}}</v-list-item-title>
        <v-list-item-subtitle>{{orgName || 'частное лицо'}}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar class="ml-3 my-0">
        <img v-if="userThumbnail" :src="userThumbnail"/>
        <v-icon size="45" v-else dark>mdi-account-circle</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', {
      userName: 'name',
      userThumbnail: 'thumbnail',
      userImage: 'image',
    }),
    ...mapGetters('organization', { orgName: 'title' }),
  }
}
</script>
