<template>
  <v-card width="50%" style="margin: 25px auto;">
    <v-toolbar color="primary" dark>
      Отчеты поверки устройства
    </v-toolbar>
    <v-card-text>
      <v-card-subtitle class="text-center">UUID устройства: {{ $route.query.deviceUuid }}</v-card-subtitle>
      <v-simple-table v-if="verificationsLoadedFlag">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Протокол №</th>
              <th width="150">Дата поверки</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in verifications" :key="item.uuid">
              <td>{{ item.id }}</td>
              <td>{{ secToDatetime(item.createdAt) }}</td>
              <td>
                <v-btn 
                  small 
                  tile
                  text 
                  color="primary" 
                  :to="'/device/verification/view?deviceUuid=' + $route.query.deviceUuid + '&id=' + item.id"
                >
                  <v-icon>mdi-file</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>        
      </v-simple-table>
      <div class="text-center" v-else>
        Отчеты калибровке устройства не найдены 
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn to="/device/index">Назад</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { secToDatetime } from '@/libs/time.js'

export default {
  data() {
    return {
      secToDatetime,
      verification: {},
      verificationsLoadedFlag: false,
    }
  },
  mounted() {
    this.axios.get('/device/verification/index', { params: { deviceUuid: this.$route.query.deviceUuid }}).then(r => {
      this.verifications = r.data.slice()
      this.verificationsLoadedFlag = true
    })
  },
}
</script>