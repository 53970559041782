<template>
  <v-card width="65%" class="mt-4 mx-auto">
    <OrganizationSignupDialog
      :show="organizationSignupDialog"
      :positionsNames="positionsNames"
      @close="organizationSignupDialog = false"
      @input="positionSelected"
    />
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Выбор организации</v-toolbar-title>
      <v-spacer/>
      <v-btn text to="/organization/profile/create">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row justify="space-between">
        <v-col cols="7">
          <TimeoutedField
            label="Поиск организации"
            v-model="searchTitle"
            hide-details
          />
        </v-col>
        <v-col class="text-right" cols="5">
          <v-btn-toggle v-model="scope">
            <v-btn>Только мои</v-btn>
            <v-btn>Все</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-simple-table fixed-header>
        <thead>
          <tr>
            <th class="text-left" style="width: 50px"></th>
            <th class="text-left">Название</th>
            <th class="text-left">Город</th>
            <th class="text-left">Улица</th>
            <th class="text-left" style="width: 50px">Дом</th>
            <th class="pa-0" width="10"></th>
            <th class="pa-0" width="10"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="org in orgs" :key="org.id">
            <td class="pa-1">
              <img v-if="org.thumbnail" :src="org.thumbnail" width="50px"/>
              <v-icon v-else size="50px">mdi-factory</v-icon>
            </td>
            <td>{{org.title}}</td>
            <td>{{org.city}}</td>
            <td>{{org.street}}</td>
            <td>{{org.building}}</td>
            <td class="pa-0 pr-2">              
              <v-btn color="warning" text :disabled="!isMyOrganization(org.id) || isAllreadySignin(org.id)" @click="organizationSignin(org.id)">
                <v-icon>mdi-login</v-icon>
              </v-btn>
            </td>
            <td class="pa-0 pr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="warning" text :disabled="isMyOrganization(org.id)" @click="showOrganizationPositionDialog(org.id)" v-on="on">
                    <v-icon>mdi-message</v-icon>
                  </v-btn>
                </template>
                <span>Подать заявку на вступление</span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-if="orgs.length == 0" class="text-center pt-3">
            <td colspan="5">Ничего не найдено</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import TimeoutedField from '@/components/TimeoutedField.vue'
import OrganizationSignupDialog from '../components/OrganizationSignupDialog.vue'

export default {
  components: { OrganizationSignupDialog, TimeoutedField },
  data: () => ({
    positionsNames: [],
    myOrganizations: [],
    searchTitle: '',
    scope: 0,    
    orgs: [],
    orgId: 0,
    organizationSignupDialog: false,
  }),
  methods: {
    ...mapActions('organization', { organizationSignin: 'signin' }),
    isMyOrganization(id) {
      return this.myOrganizations.some(o => o.orgId == id && o.status == 2)
    },
    isAllreadySignin(id) {
      return id == this.$store.getters['organization/orgId']
    },
    showOrganizationPositionDialog(orgId) {
      this.orgId = orgId
      this.organizationSignupDialog = true
    },
    positionSelected(positionId) {
      this.organizationSignupDialog = false
      this.$axios.post('organization/staff/create', {
        orgId: this.orgId,
        positionId: positionId
      })
    },
    getOrgs() {
      this.orgs = []
      this.$axios.get('organization/profile/index', { params: 
        { title: this.title, scope: this.scope }
      }).then(r => this.orgs = r.data.items.slice())
    }
  },
  mounted() {
    this.$axios.get('/organization/position-name/index').then(r => this.positionsNames = r.data.slice())
    this.$axios.get('/organization/main/my-organizations').then(r => this.myOrganizations = r.data.slice())
    this.getOrgs()
  },
  watch: {
    scope() {
      this.getOrgs()
    }
  }
}
</script>
