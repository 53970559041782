export const statuses = [
  {
    name: 'STATUS_NEW',
    id: 1,
    text: 'Новый',
    icon: 'mdi-file',
    color: 'primary'
  },
  {
    name: 'STATUS_ACTIVE',
    id: 2,
    text: 'Актив',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    name: 'STATUS_ARCHIVE', 
    id: 4,
    text: 'Архив',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    name: 'STATUS_DELETED', 
    id: 5,
    text: 'Удалена',
    icon: 'mdi-file-restore',
    color: 'green'
  }, 

  // accept procedure
  { 
    name: 'STATUS_ACCEPT_WAIT_VIEW', 
    id: 8, 
    text: 'Ожидает рассмотрения',
    icon: 'mdi-file-question',
    color: 'blue'
  },
  { 
    name: 'STATUS_ACCEPT_IN_VIEW', 
    id: 9, 
    text: 'Рассматривается',
    icon: 'mdi-file-find',
    color: 'orange'
  }, 
  { 
    name: 'STATUS_ACCEPT_WAIT_CORRECT', 
    id: 10, 
    text: 'С замечаниями',
    icon: 'mdi-file-remove',
    color: 'red'
  }, 
  { 
    name: 'STATUS_ACCEPT_IN_CORRECT', 
    id: 11, 
    text: 'Исправляется',
    icon: 'mdi-file-edit',
    color: 'orange'
  }, 
  { 
    name: 'STATUS_ACCEPT_CORRECTED', 
    id: 12, 
    text: 'Исправлена',
    icon: 'mdi-file-restore',
    color: 'green'
  }, 
  // agree procedure
  { 
    name: 'STATUS_AGREE_WAIT_VIEW', 
    id: 13, 
    text: 'Ожидает рассмотрения',
    icon: 'mdi-file-question',
    color: 'blue'
  }, 
  { 
    name: 'STATUS_AGREE_IN_VIEW', 
    id: 14, 
    text: 'Рассматривается',
    icon: 'mdi-file-find',
    color: 'orange'
  }, 
  { 
    name: 'STATUS_AGREE_WAIT_CORRECT', 
    id: 15, 
    text: 'С замечаниями',
    icon: 'mdi-file-remove',
    color: 'red'
  }, 
  { 
    name: 'STATUS_AGREE_IN_CORRECT', 
    id: 16, 
    text: 'Исправляется',
    icon: 'mdi-file-edit',
    color: 'orange'
  }, 
  { 
    name: 'STATUS_AGREE_CORRECTED', 
    id: 17, 
    text: 'Исправлена',
    icon: 'mdi-file-restore',
    color: 'green'
  }, 
  
  // 'Agreeder' send to accept after agree correct
  { 
    name: 'STATUS_ACCEPT_AGREE_CORRECTED', 
    id: 18,
    text: 'Исправлена после согласования',
    icon: 'mdi-file-restore',
    color: 'green'
  }, 
  // 'Accepter' send to correct and developer can start chat
  { 
    name: 'STATUS_AGREE_ACCEPT_WAIT_CORRECT', 
    id: 19,
    text: 'Не принята утверждающим после согласования',
    icon: 'mdi-file-remove',
    color: 'red'
  },
  
  /**
   * Comments statuses
   */
  { 
    name: 'STATUS_ACCEPTED', 
    id: 20 
  },
  { 
    name: 'STATUS_REJECTED', 
    id: 21 
  },  
  { 
    name: 'STATUS_INWORK', 
    id: 22 
  },  


  /**
   * Processes, processes card statuses
   */
  { 
    name: 'STATUS_WAIT_APPOINT', 
    id: 23, 
    text: 'Распределяется',
    icon: 'mdi-file-document-edit',
    color: 'orange'

  },  
  { 
    name: 'STATUS_IN_QUEUE', 
    id: 24, 
    text: 'В очереди',
    icon: 'mdi-file-clock',
    color: 'blue'
  },
  { 
    name: 'STATUS_FINISHED', 
    id: 25, 
    text: 'Завершено',
    icon: 'mdi-file-check',
    color: 'green'
  }         
]
