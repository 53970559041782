<template>
    <v-card width="250" style="margin: 25px auto;">
        <v-card-text>
            <TooltipComboboxField label="test" :items="items" v-model="data"/>
        </v-card-text>
    </v-card>
</template>

<script>
import TooltipComboboxField from './TooltipComboboxField.vue'

export default {
    components: { TooltipComboboxField },
    data() {
        return {
            data: 'test',
            items: [
                {text: '1', tooltip: '1'},
                {text: '2', tooltip: '2'},
                {text: '3', tooltip: '3'},
            ]
        }
    },
}
</script>