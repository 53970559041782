<template>
  <v-card width="25%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title >Подтверждение акаунта</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="text-center pa-4">
      <v-progress-circular
        size="70"
        width="7"
        color="blue"
        indeterminate
        v-if="!error"
      />
      <template v-else>
        <h3>Что-то пошло не так, обратитесь за помощью на info@weldassist.ru</h3>
        <v-btn class="mt-3" color="primary" to="/user/account/login">Назад</v-btn>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    error: false,
  }),
  mounted: function() {
    this.$axios.post('user/account/email-confirm', {}, { params: { token: this.$route.query.token }}).then(() => {
      this.$router.push('/user/account/login')
    }).catch(() => this.error = true)
  }
}
</script>
