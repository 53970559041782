<template>
  <v-container class="text-center pa-0" style="cursor: pointer;">
    <ImageCropDialog
      v-if="imageCropDialogShow"
      :file="imageCropDialogFile"
      :cropperProps="{ initialAspectRatio: 1, aspectRatio: 1, autoCropArea: 1 }"
      @done="imageCroped"
      @close="imageCropDialogShow = false"
    />
    <input type="file" ref="inputFile" style="display: none;" @change="fileSelected">
    <div class="change-avatar" @click="$refs.inputFile.click()">
      <img v-if="value" :src="value" class="my-ava"/>
      <v-icon v-else size="250">{{icon}}</v-icon>
    </div>
    <FileUploader
      showProgress
      :file="uploadFile"
      @done="uploadDone"
    />
  </v-container>
</template>

<script>
import ImageCropDialog from './ImageCropDialog'
import FileUploader from './FileUploader'

export default {
  props: {
    value: String,
    icon: { type: String, default: 'mdi-account' },
  },
  components: {
    ImageCropDialog,
    FileUploader
  },
  data: function() {
    return {
      imageCropDialogShow: false,
      imageCropDialogFile: null,
      uploadFile: null,
      uploadCancel: false,
    }
  },
  methods: {
    fileSelected(e) {
      if (!e.target.files[0]) {
        return
      }
      this.imageCropDialogFile = e.target.files[0]
      this.imageCropDialogShow = true
    },
    imageCroped (e) {
      // this.image = URL.createObjectURL(e.image)
      this.imageCropDialogShow = false
      this.$refs.inputFile.value = ''
      this.uploadFile = new File([e.image], this.imageCropDialogFile.name)
    },
    uploadDone: function (e) {
      this.$emit('input', e.url)
    },
  }
}
</script>

<style>
  .my-ava {
    /* width: auto; */
    border-radius: 5px;
    /* padding: 15px; */
    height: 282px;
  }

  .change-avatar:hover {
    opacity: 50%;
  }

  .change-avatar {
    cursor: pointer;
    opacity: 100%;
  }
</style>
