<template>
  <v-card width="35%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Восстановление пароля</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-model="dataValid">
        <v-row>
          <v-col cols="12">
            <v-text-field
              type="password"
              v-model="passwordResetForm.password"
              label="Новый пароль"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="password"
              v-model="passwordResetForm.passwordRepeat"
              label="Повторите пароль"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="info" to="/login">Отмена</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="passwordReset" :loading="loading" :disabled="loading||!dataValid">Далее</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/libs/validate-rules.js'
export default {
  data: () => ({
    passwordResetForm: {
      password: '',
      passwordRepeat: '',
    },
    dataValid: false,
    rules: rules,
    loading: false,
  }),
  methods: {
    passwordReset() {
      this.loading = true
      this.$axios.post('user/account/password-reset', { ...this.passwordResetForm }, { params: { token: this.$route.query.token }}).then(r => {
        this.$router.push('/user/account/login')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
