<template>
  <CVUForm :value="device" @input="createDevice" create/>
</template>

<script>
import CVUForm from '../components/cvuForm.vue'

export default {
  components: { CVUForm },
  data: () => ({
    device: {
      uuid: '',
      name: '',
      location: '',
    }
  }),
  methods: {
    createDevice() {
      this.$axios.post('/device/main/activate', { ...this.device })
        .then(() => this.$router.replace('/device/index'))
        .catch(() => {}).finally(() => {})
    },
  },
}
</script>