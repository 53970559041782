export default {
  path: '/module',
  meta: { private: true },
  component: () => import('@/layouts/main/Index.vue'),
  children: [
    {
      path: 'index',
      component: () => import('./views/Index.vue')
    },
  ]
}