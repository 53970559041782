<template>
  <v-card width="95%" class="mt-4 mx-auto" v-if="cardLoaded">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Отчет по карте</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div class="title text-center pt-4">Идентификаторы карты</div>
      <v-simple-table dense class="table-last-column-right">
        <tbody>
          <tr>
            <td>Уникальный номер</td>
            <td>{{card.cardId}}</td>
          </tr>
          <tr>
            <td>Идентификатор/Название карты</td>
            <td>{{card.card.name}}</td>
          </tr>
          <tr>
            <td>Порядковый номер шва по техпроцессу</td>
            <td>{{card.queue}}</td>
          </tr>
          <tr>
            <td>Номер (шифр) стыка</td>
            <td>{{card.code}}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Характеристика применимости</div>
      <v-simple-table dense class="table-last-column-right">
        <tbody>
          <tr>
            <td>Способ сварки</td>
            <td>{{ card.card.weldMethod }}</td>
          </tr>
          <tr>
            <td>Вид соединяемых элементов</td>
            <td>{{ card.card.element1Type + ' + ' + card.card.element2Type }}</td>
          </tr>
          <tr>
            <td>Тип шва</td>
            <td>{{ card.seam }}</td>
          </tr>
          <tr>
            <td>Тип соединения</td>
            <td>{{ card.card.jointType }}</td>
          </tr>
          <tr>
            <td>Материал элементов</td>
            <td>{{ card.card.element1Material + ' + ' + card.card.element2Material}}</td>
          </tr>
          <tr v-if="card.card.d1">
            <td>Диаметр элемента 1, мм (D1)</td>
            <td>{{ card.card.d1 }}</td>
          </tr>
          <tr v-if="card.card.d2">
            <td>Диаметр элемента 2, мм (D2);</td>
            <td>{{ card.card.d2 }}</td>
          </tr>
          <tr v-if="card.card.s1">
            <td>Толщина элемента 1, мм (S1)</td>
            <td>{{ card.card.s1 }}</td>
          </tr>
          <tr v-if="card.card.s2">
            <td>Толщина элемента 2, мм (S2)</td>
            <td>{{ card.card.s2 }}</td>
          </tr>
          <tr>
            <td>Сварочный материал</td>
            <td>{{ card.card.weldMaterial }}</td>
          </tr>
          <tr>
            <td>Положение в пространстве</td>
            <td>{{ card.card.weldPosition }}</td>
          </tr>
          <tr>
            <td>Прочие параметры</td>
            <td>{{ card.card.othes }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Отклонения по карте</div>
      <DeviationsTable :deviations="cardDeviations" :weldTime="Number(card.weldTime)"/>
      <!-- <div class="title text-center pt-4">Данные о реализации</div> -->
      <div class="title text-center pb-4">Слои тех. карты</div>
      <v-simple-table class="table-text-center" dense>
        <thead>
          <tr>
            <th>№ слоя</th>
            <th>Тип слоя</th>
            <th>Начат</th>
            <th>Закончен</th>
            <th>Время выполнения</th>
            <th>Время сварки</th>
            <th>Iср</th>
            <th>Uср</th>
            <th>Доп. откл.</th>
            <th>Крит. откл.</th>
            <th>Общ. откл.</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="layer in card.layers"
            @click="goToViewProcCardLayer(layer.id)"
            style="cursor: pointer"
            :key="layer.id"
          >
            <td>{{layer.num}}</td>
            <td>{{getLayerNameById(layer.layerNameId)}}</td>
            <td>
              <div>{{secToDate(layer.startedAt)}}</div>
              <div>{{secToTime(layer.startedAt)}}</div>
            </td>
            <td>
              <div>{{secToDate(layer.finishedAt)}}</div>
              <div>{{secToTime(layer.finishedAt)}}</div>
            </td>
            <td>{{secToHoursTime(layer.finishedAt - layer.startedAt)}}</td>
            <td>{{secToHoursTime(layer.weldTime/1000)}}</td>
            <td>{{getAverage(layer.channelsAverages, 1)}}</td>
            <td>{{getAverage(layer.channelsAverages, 2)}}</td>
            <td>{{toPercentString(layer.allow, layer.weldTime)}}</td>
            <td>{{toPercentString(layer.crit, layer.weldTime)}}</td>
            <td>{{toPercentString(layer.common, layer.weldTime)}}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-btn :to="'/manufacture/process/view?id=' + card.procId">Назад</v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
import { secToDate, secToTime, secToHoursTime } from '@/libs/time.js'
import { toPercentString } from '@/libs/common.js'
import DeviationsTable from '../../components/DeviationsTable.vue'

export default {
  components: { DeviationsTable },
  data: () => ({
    secToTime,
    secToDate,
    secToHoursTime,
    toPercentString,
    cardLoaded: false,
    card: {},
    layerNames: [],
  }),
  computed: {
    cardDeviations() {
      return {
        allowUp: this.card.allowUp,
        allowDown: this.card.allowDown,
        critUp: this.card.critUp,
        critDown: this.card.critDown,
        allow: this.card.allow,
        crit: this.card.crit,
        common: this.card.common,        
      }
    },
  },
  methods: {
    goToViewProcCardLayer(id) {
      this.$router.push('/manufacture/process-card-layer/view?id=' + id)
    },
    getLayerNameById(layerNameId) {
      let layerName = this.layerNames.find(l => l.id == layerNameId)
      return layerName ? layerName.name : '????'
    },
    getAverage(avrs, channelId) {
      let chAvr = avrs.find(a => a.channelId == channelId)
      if (chAvr) {
        return (chAvr.acc / chAvr.samplesCount).toFixed(1)
      }
      return 'Нет'
    },
    getCard() {
      let url = '/manufacture/process-card/view?id=' + this.$route.query.id
      this.$axios.get(url).then(r => {
        this.card =  r.data
        this.cardLoaded = true
      })
    },
  },
  mounted: function() {
    this.$axios.get('/cards/layer-name/index').then(r => {
      this.layerNames = r.data.slice()
      this.getCard()
    })
  },
}
</script>

<style>
  .table-last-column-right tbody tr td:last-child {
    text-align: right;
  }
  .table-text-center {
    text-align: center;
  }
  .table-text-center th {
    text-align: center !important;
  }
  .bottom-border {
    border-bottom-style: solid;
    border-bottom-width: thin;
  }
</style>
