<template>
  <v-card>
    <v-card-title>
      Прочая информация
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="Операционный контроль"
            filled
            hide-details
            rows="5"
            v-model="value.operationControl"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Техника безопасности и охрана труда"
            filled
            hide-details
            rows="7"
            v-model="value.safety"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: { value: Object },
}
</script>
