<template>
  <v-card width="75%" class="mt-4 mx-auto" v-if="layerLoaded">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Отчет по слою</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div class="title text-center pt-4">Общая информация</div>
      <v-simple-table class="table-last-column-right" dense>
        <tbody>
          <tr>
            <td>Слой</td>
            <td>{{getLayerNameById(layer.layerNameId)}}</td>
          </tr>
          <tr>
            <td>Номер</td>
            <td>{{layer.num}}</td>
          </tr>
          <tr>
            <td>Диаметр электрода, мм</td>
            <td>{{modes.electrode}}</td>
          </tr>
          <tr>
            <td>Полярность тока</td>
            <td>{{getPolarityNameById(modes.polarityNameId)}}</td>
          </tr>
          <tr>
            <td>Cредний ток</td>
            <td>{{ calcAverages(layer.channelsAverages[0]) }}</td>
          </tr>
          <tr>
            <td>Ток по карте макс.</td>
            <td>{{ modes.channels[0].max }}</td>
          </tr>
          <tr>
            <td>Ток по карте мин.</td>
            <td>{{ modes.channels[0].min }}</td>
          </tr>
          <tr>
            <td>Среднее напряжение</td>
            <td>{{ calcAverages(layer.channelsAverages[1]) }}</td>
          </tr>
          <tr>
            <td>Напряжение по карте макс.</td>
            <td>{{ modes.channels[1].max }}</td>
          </tr>
          <tr>
            <td>Напряжение по карте мин.</td>
            <td>{{ modes.channels[1].min }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Временные характеристики слоя</div>
      <v-simple-table class="table-last-column-right" dense>
        <tbody>
          <tr>
            <td>Дата начала</td>
            <td>{{ secToDate(layer.startedAt) }}</td>
          </tr>
          <tr>
            <td>Время начала</td>
            <td>{{ secToTime(layer.startedAt) }}</td>
          </tr>
          <tr>
            <td>Дата окончания</td>
            <td>{{ secToDate(layer.finishedAt) }}</td>
          </tr>
          <tr>
            <td>Время окончания</td>
            <td>{{ secToTime(layer.finishedAt) }}</td>
          </tr>
          <tr>
            <td>Общее время выполнения</td>
            <td>{{ secToHoursTime(layer.finishedAt - layer.startedAt) }}</td>
          </tr>
          <tr>
            <td>Время сварки слоя (чистое время горения сварочной дуги)</td>
            <td>{{ secToHoursTime(layer.weldTime / 1000) }}</td>
          </tr>
          <tr>
            <td>Время дополнительных операций</td>
            <td>
              {{secToHoursTime(
                (layer.finishedAt - layer.startedAt) -
                (layer.weldTime / 1000)
              )}}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Отклонения по слою</div>
      <DeviationsTable :deviations="layerDeviations" :weldTime="Number(layer.weldTime)"/>
      <div class="title text-center pt-4">Отклонения по каналам</div>
      <DeviationsTable       
        v-for="devs in layer.channelsDeviations"
        :title="getChannelNameById(devs.channelId)"
        :deviations="devs" 
        :weldTime="Number(layer.weldTime)"
        :key="'devs_' + devs.id"
      />
      <div class="title text-center pt-4">Регистограммы</div>
      <div class="text-center" v-if="layer.regFile">
        <div ref="charts"></div>
        <v-btn
          color="success"
          @click="download()"
          target="_blank"
          >
          скачать
        </v-btn>
      </div>
      <div v-else>
        <div class="subheading text-center">Регистограммы загружаются</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn :to="'/manufacture/process-card/view?id=' + layer.processCardId">Назад</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Highcharts from 'highcharts/highstock'
import { secToDate, secToTime, secToHoursTime } from '@/libs/time.js'
import { toPercentString } from '@/libs/common.js'
import DeviationsTable from '../../components/DeviationsTable.vue'
import pako from 'pako'

export default {
  components: { DeviationsTable },
  data: () => ({
    secToTime,
    secToDate,
    secToHoursTime,
    toPercentString,
    layer: {},
    layerNames: [],
    layerLoaded: false,
    modes: {},
    polarityNames: [],
    channelNames: [],
    modesLoaded: false,
    showCharts: false,
    loadCharts: false,
    sending: false,
    chart: null,
  }),
  computed: {
    layerDeviations() {
      return {
        allowUp: this.layer.allowUp,
        allowDown: this.layer.allowDown,
        critUp: this.layer.critUp,
        critDown: this.layer.critDown,
        allow: this.layer.allow,
        crit: this.layer.crit,
        common: this.layer.common,        
      }
    }
  },
  methods: {
    download() {
      this.axios.get(window.location.origin + this.layer.regFile, {
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.gz'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    calcAverages(data) {
      if (data.samplesCount > 0) {
        return (data.acc / data.samplesCount).toFixed(1)
      }
      return 0
    },
    loadRegFile() {
      let dataUrl = window.location.origin + this.layer.regFile
      this.$axios.get(dataUrl, { 
        responseType: 'arraybuffer',
      }).then(r => {
        this.prepareCharts(r.data).then(options => {
          this.chart = Highcharts.stockChart(this.$refs.charts, options)
        }).catch(() => {})
      })
    },
    prepareCharts(data) {
      var self = this
      const options = {
        rangeSelector: {
          enabled: false,
        },
        chart: {
          type: 'spline'
        },
        yAxis: [
          { title: { text: 'Ток' }, opposite: false, crosshair: true },
          { title: { text: 'Напряжение' }, opposite: true, crosshair: true },
        ],
        tooltip: {
          headerFormat: '',
          valueDecimals: 1,
          split: false
        },
        series: [],
        legend: {
          enabled: true,
          align: 'center',
          verticalAlign: 'top',
        },
      }
      return new Promise((resolve, reject) => {
        const iSeries = {
          name: 'Ток',
          //pointInterval: 1,
          data: []
        }
        const uSeries = {
          name: 'Напряжение',
          //pointInterval: 1,
          yAxis: 1,
          data: [],
        }
        const d = pako.inflate(data, { to: 'string' })
        var lines = d.split('\n')
        lines.forEach(function(line, lineNo) {
          if(lineNo != 0) {
            if(line) {
              var items = line.split(';')
              let timeParts = items[0].split('.')
              let t = (parseInt(timeParts[0])*1000000) + parseInt(timeParts[1])
              iSeries.data.push([t, parseFloat(items[1])])
              uSeries.data.push([t, parseFloat(items[2])])
            }
          }
        })
        options.series.push(iSeries)
        options.series.push(uSeries)
        resolve(options)
      })
    },
    getChannelNameById(nameId) {
      let r = this.channelNames.find(i => i.id == nameId)
      return r ? r.name : '---'
    },
    getLayerNameById(layerNameId) {
      let r = this.layerNames.find(i => i.id == layerNameId)
      return r ? r.name : '---'
    },
    getPolarityNameById(nameId) {
      let r = this.polarityNames.find(i => i.id == nameId)
      return r ? r.name : '---'
    },
    getModes() {
      let url = this.layer.processCard.card.module.baseUrl + '/card/layer-modes'
      this.$axios.get(url, { params: { 
        cardId: this.layer.processCard.card.moduleCardId,
        layerNum: this.layer.num
      }}).then(r => {
        this.modes = r.data || { electrode: '---', channels: [{ min: '---', max: '---' }, { min: '---', max: '---' }] }
        this.layerLoaded = true
      })
    },
    getLayer() {
      let url = '/manufacture/process-card-layer/view'
      this.$axios.get(url, { params: { id: this.$route.query.id }}).then(r => {
        this.layer = r.data
        this.getModes()
        if (this.layer.regFile) {
          this.loadRegFile()
        }
      })
    }
  },
  mounted() {
    let urls = ['/cards/layer-name/index', '/cards/polarity-name/index', '/cards/channel-name/index']
    let promises = []
    urls.forEach(url => promises.push(this.$axios.get(url)))
    Promise.all(promises).then(r => {
      this.layerNames = r[0].data.slice()
      this.polarityNames = r[1].data.slice()
      this.channelNames = r[2].data.slice()
      this.getLayer()
    })
  }
}
</script>

<style>
  .table-last-column-right tbody tr td:last-child {
    text-align: right;
  }
  .table-text-center {
    text-align: center;
  }
  .table-text-center th {
    text-align: center !important;
  }
  .bottom-border {
    border-bottom-style: solid;
    border-bottom-width: thin;
  }
</style>
