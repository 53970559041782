<template>
  <v-container class="mb-3" style="border: thin solid;">
    <div class="subtitle-1 mb-2 text-center">Слои тех. карты</div>
    <v-card v-for="(layer, i) in value" :key="i" class="mb-3">
      <v-toolbar color="primary" dark dense>
        <v-icon color="white" class="mr-2" @click="up(i)">mdi-arrow-up</v-icon>
        <v-icon color="white" @click="down(i)">mdi-arrow-down</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>Слой: {{layer.num}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon color="white" class="mr-3" @click="copy(i)">mdi-content-copy</v-icon>
        <v-icon color="white" @click="remove(i)">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-select
              label="Слой"
              :items="layersNames"
              item-text="name"
              item-value="id"
              v-model="layer.layerNameId"
              @change="filterLayers"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              label="Полярность"
              :items="polaritiesNames"
              item-text="name"
              item-value="id"
              v-model="layer.polarityNameId"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Марка св. материала"
              v-model="layer.weldMaterial"
            />
          </v-col>      
          <v-col cols="2">
            <v-text-field
              label="Диаметр электрода"
              type="number" step="0.1"
              v-model.number="layer.electrode"
            />
          </v-col>
          <v-col cols="12">
            <div class="subtitle-1">Параметры режима</div>
            <v-row class="ma-0" style="border-width: thin; border-style: solid;">
              <v-col cols="6" v-for="channel in layer.channels" :key="channel.channelId">
                <div class="subtitle-1">{{getChannelNameById(channel.channelId)}}</div>
                <v-row class="ma-0" style="border-width: thin; border-style: solid;">
                  <v-col cols="6">
                    <v-text-field
                      label="Минимальный"
                      v-model.number="channel.min"
                      :hide-details="true"
                      dense
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Максимальный"
                      v-model.number="channel.max"
                      :hide-details="true"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="success"
                  outlined
                  :disabled="layer.channels.length === channelsNames.length"
                  @click="addChannel(layer)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-btn color="info" small @click="newLayer">Добавить слой</v-btn>
    </div>
  </v-container>
</template>

<script>

export default {
  props: {
    value: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      layersNames: [],
      channelsNames: [], 
      polaritiesNames: [],
    }
  },
  methods: {
    getLayerNameById(id) {
      return this.layersNames.find(l => l.id == id)
    },
    getChannelNameById(channelId) {
      let channel = this.channelsNames.find(ch => ch.id === channelId)
      return channel ? channel.name : 'Этого не должно было произойти'
    },
    addChannel(layer) {
      layer.channels.push(Object.assign({}, {
        channelId: layer.channels.length + 1, min: '', max: ''
      }))
    },
    filterLayers() {
      this.layersNames.forEach(l => {
        l.disabled = false
        if(this.value.some(ll => ll.layerId === l.id)) {
          l.disabled = true
        }
        if(l.id === -1) {
          if(this.value.length !== 1) {
            l.disabled = true
          }
        }
        if(l.id === -2) {
          if(this.value.length === 1) {
            l.disabled = true
          }
        }
      })
    },
    newLayer() {
      this.value.push(Object.assign({}, {
        layerNameId: '',
        num: this.value.length + 1,
        polarityNameId: '',
        weldMaterial: '',
        electrode: '',
        channels: [
          { channelId: 1, min: '', max: '' }
        ],
      }))
      this.refreshQueue()
    },
    up(index) {
          let item = this.value[index]
          this.value.splice(index, 1)
          this.value.splice(index - 1, 0, item)
          this.refreshQueue()
    },
    down(index) {
          let item = this.value[index]
          this.value.splice(index, 1)
          this.value.splice(index+1, 0, item)
          this.refreshQueue()
    },
    copy(index) {
      let item = JSON.parse(JSON.stringify(this.value[index]))//PPC COSTYLI(deep copy)
      this.value.splice(index + 1, 0, Object.assign(item, {
        id: '',
        num: this.value.length + 1
      }))
      this.refreshQueue()
    },
    remove(index) {
          if(confirm('Действительно удалить слой?')) {
            this.value.splice(index, 1)
            this.refreshQueue()
          }
    },
    refreshQueue() {
      this.value.forEach((c, i) => {c.num = i + 1})
      this.filterLayers()
    },
  },
  mounted() {
    this.$axios.get('/cards/layer-name/index').then(r => this.layersNames = r.data.slice())
    this.$axios.get('/cards/channel-name/index').then(r => this.channelsNames = r.data.slice())
    this.$axios.get('/cards/polarity-name/index').then(r => this.polaritiesNames = r.data.slice())
  }
}
</script>
