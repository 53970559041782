<template>
  <CommentCardView>
    <template v-slot:title="{ card }">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          Исправление карты: {{card.name}}
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:actions="{ card }">
      <v-card-actions>
        <v-btn color="default" @click="$router.go(-1)">назад</v-btn>
        <ChatDialog
          v-if="chatDialogShow"
          :title="card.name"
          :cardId="card.id"
          @close="chatDialogShow = false"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          v-if="card.status == 16"
          @click="chatDialogShow = true"
          >
          Обсудить
        </v-btn>
        <v-btn
          color="success"
          :to="card.module.baseUrl + '/card/update?id=' + card.moduleCardId"
          >
          Исправить
        </v-btn>
        <v-btn
          color="error"
          :disabled="disableToCorrected(card)"
          @click="sendToCorrected(card)"
          >
          Отправить на рассмотрение
        </v-btn>
      </v-card-actions>
    </template>
  </CommentCardView>
</template>

<script>
import CommentCardView from './components/CommentCardView.vue'
import ChatDialog from '../card-chat/ChatDialog.vue'

export default {
  components: { CommentCardView, ChatDialog },
  data: () => ({
    chatDialogShow: false,
    chatDialogTitle: '',
    chatDialogCardId: 0,
  }),
  methods: {
    disableToCorrected(card) {
      let fn = ''
      let c = card.privateChat.find(c => {
        if (c.fieldName != fn) {
          fn = c.fieldName
          return c.status == 1
        }
      })
      return !!c
    },
    sendToCorrected(card) {
      this.$axios.post('manufacture/card/to-corrected', { id: card.id})
      .then(r => {
        this.$router.go(-1)
      }).catch(() => {})
    },
  },
}
</script>
