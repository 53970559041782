<template>
  <v-card>
    <v-card-title>Общяя информация</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <PersoneField v-model="value.agreeder" title="Согласовал"/>
        </v-col>
        <v-col cols="6">
          <PersoneField v-model="value.accepter" title="Утвердил"/>
        </v-col>
        <v-col cols="12">
          <v-col cols="12">
            <v-text-field
              label="Название(№) карты"
              v-model="value.name"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              auto-grow
              filled
              rows="4"
              row-height="30"
              label="Краткое описание технологической карты"
              v-model="value.about"
            />
          </v-col>
          <v-col cols="12">
            <FilesField v-model="value.files"/>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import PersoneField from '@/modules/cards/components/PersoneField.vue' 
import FilesField from '@/components/FilesField.vue'

export default {
  props: {
    value: {
      type: Object,
    }
  },
  components: { FilesField, PersoneField },
  methods: {
  }
}
</script>
