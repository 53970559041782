<template>
  <v-dialog
    value="true"
    persistent
    scrollable
    max-width="45%"
    >
    <v-card>
      <CommentDiffDialog
        v-if="diffDialogShow"
        :title="title"
        :component="diffDialogComponent"
        :oldValue="diffDialogOldValue"
        :newValue="diffDialogNewValue"
        :message="diffDialogMessage"
        @close="diffDialogShow = false"
      />
      <v-toolbar color="primary" dark>
        Коментарии к полю: {{ title }}
      </v-toolbar>
      <v-card-text class="mb-2">
        <v-row v-for="comment in filteredComments" :key="comment.id">
          <v-col
            cols="10"
            :offset="getOffset(comment.createdBy)"
            class="pa-3"
            >
            <Comment
              :class="[getCommentClass(comment.status), 'lighten-4']"
              :comment="comment"
              @click.native="openDiffDialog(comment)"
              v-on:statusUpdate="$emit('commentUpdate')"
            />
          </v-col>
        </v-row>
        <v-row v-if="filteredComments.length == 0">
          <v-col cols="12" class="px-4 pt-4 text-center">
            Нет ни одного коментария
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-textarea
          label="Коментарий"
          :rows="2"
          hide-detail
          v-model="message"
        />
      </v-card-actions>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Назад</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="save"
          :disabled="message === '' || message === lastComment.message"
          >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Comment from './Comment.vue'
import { mapState } from 'vuex'
import CommentDiffDialog from './CommentDiffDialog.vue'
import { capitalize } from '@/libs/common.js'

export default {
  props: {
    // show: {
    //   type: Boolean,
    //   default: false
    // },
    title: {
      type: String,
      default: ''
    },
    fieldName: {
      type: String,
      default: ''
    },
    processTmpl: {
      type: Object,
      default: () => ({ comments: [] })
    },
    lastComment: Object,
  },
  components: { Comment, CommentDiffDialog },
  data: function () {
    return {
      message: this.lastComment.message,
      diffDialogShow: false,
      diffDialogComponent: false,
      diffDialogOldValue: null,
      diffDialogNewValue: null,
      diffDialogMessage: '',
    }
  },
  computed: {
    userId() { return this.$store.state.user.profile.userId },
    filteredComments() {
      let arr = this.processTmpl.privateChat.filter(cm => {
        return (cm.fieldName == this.fieldName && cm.status != 4)
      })
      return arr.sort((a,b) => b.createdAt - a.createdAt)
    }
  },
  methods: {
    openDiffDialog(comment) {
      // if (this.fieldName == 'modes') {
      //   this.diffDialogComponent = 'Modes'
      // } else {
      //   this.diffDialogComponent = 'SimpleField'
      // }
      // this.diffDialogMessage = comment.message
      // let baseUrl = this.card.module.baseUrl + '/card/view'
      // let index = this.filteredComments.findIndex(c => c.createdAt == comment.createdAt)
      // const oldVal = this.$axios.get(baseUrl, { params: {
      //   id: this.card.moduleCardId,
      //   versionTimestamp: comment.cardVersionTimestamp
      // }})
      // let params = { id: this.card.moduleCardId }
      // if (index > 0) {
      //   params.versionTimestamp = this.filteredComments[index - 1].cardVersionTimestamp
      // }
      // const newVal = this.$axios.get(baseUrl, { params })
      // Promise.all([oldVal, newVal]).then(r => {
      //   this.diffDialogOldValue = r[0].data.version[this.fieldName]
      //   this.diffDialogNewValue = r[1].data.version[this.fieldName]
      //   this.diffDialogShow = true
      // }).catch(() => {})
    },
    getCommentClass(status) {
      let cl = ''
      switch(status) {
        case 1:
          cl = 'red'
          break
        case 20:
          cl = 'green'
          break
        case 21:
          cl = 'orange'
          break
        case 22:
          cl = 'blue'
          break
      }
      return cl
    },
    getOffset(createdBy) {
      if (createdBy === this.userId) {
        return 2
      }
      return 0
    },
    save() {
      if (this.message) {
        if(this.lastComment.id) {
          this.$axios.post('manufacture/process-template-private-chat/update', {
            id: this.lastComment.id,
            message: this.message,
          }).then(r => {
            this.$emit('update:comment', r.data)
          }).catch(() => {})
        } else {
          let data = {
            processTmplateId: this.processTmpl.id,
            fieldName: this.fieldName,
            message: this.message,
          }
          if (this.filteredComments.length > 0) {
            if (this.filteredComments[0].createdBy != this.userId) {
              data.createdTo = this.filteredComments[0].createdBy
            } else {
              data.createdTo = this.processTmpl.createdBy
            }
          } else {
            data.createdTo = this.processTmpl.createdBy
          }
          this.$axios.post('manufacture/process-template-private-chat/create', data).then(r => {
            this.$emit('create:comment', r.data)
          }).catch(() => {})
        }
      } else if (this.lastComment.id) {
        console.log('delete comment')
      }
    },
  },
}
</script>
