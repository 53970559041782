<template>
  <v-container v-if="card">
    <table class="table text-center">
      <tr>
        <th style="width: 25%;">СОГЛАСОВАНО</th>
        <th
          class="tmpl-hovered"
          id="name"
          @click="$emit('click', { fieldName: 'name', fieldTitle: 'Название карты'})"
          >
            Технологическая карта сварки №{{card.version.name}}
        </th>
        <th style="width: 25%;">УТВЕРЖДАЮ</th>
      </tr>
      <tr>
        <td>{{card.version.agreeder.post}}</td>
        <th>ОПИСАНИЕ КАРТЫ</th>
        <td>{{card.version.accepter.post}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.org}}</td>
        <td
          class="tmpl-hovered"
          id="about"
          rowspan="3"
          @click="$emit('click', { fieldName: 'about', fieldTitle: 'Описание карты'})"
          >
          {{card.version.about}}
        </td>
        <td>{{card.version.accepter.org}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.name}}</td>
        <td>{{card.version.accepter.name}}</td>
      </tr>
      <tr>
        <td>{{card.version.agreeder.date}}</td>
        <td>{{card.version.accepter.date}}</td>
      </tr>
    </table>
    <table class="table">
      <tr><th colspan="6" class="th">Харктеристика соединения</th></tr>
      <tr>
        <th class="th">Способ сварки</th>
        <th class="th">Нормативный документ</th>        
        <th class="th">Тип соединения</th>
        <th class="th">Тип шва</th>
        <th class="th">Вид шва</th>
        <th class="th">Положение стыка</th>
      </tr>
      <tr>
        <td
          class="th tmpl-hovered"
          id="weldMethod"
          @click="$emit('click', { fieldName: 'weldMethod', fieldTitle: 'Способ сварки'})"
          >
          {{card.version.weldMethod}}
        </td>
        <td
          class="th tmpl-hovered"
          id="normDocument"
          @click="$emit('click', { fieldName: 'normDocument', fieldTitle: 'Нормативный документ'})"
          >
          {{card.version.normDocument}}
        </td>
        <td
          class="th tmpl-hovered"
          id="joinType"
          @click="$emit('click', { fieldName: 'joinType', fieldTitle: 'Тип соединения'})"
          >
          {{card.version.jointType}}
        </td>
        <td
          class="th tmpl-hovered"
          id="seamType"
          @click="$emit('click', { fieldName: 'seamType', fieldTitle: 'Тип шва'})"
          >
          {{card.version.seamType}}
        </td>
        <td
          class="th tmpl-hovered"
          id="seamKind"
          @click="$emit('click', { fieldName: 'seamKind', fieldTitle: 'Вид шва'})"
          >
          {{card.version.seamKind}}
        </td>
        <td
          class="th tmpl-hovered"
          id="weldPosition"
          @click="$emit('click', { fieldName: 'weldPosition', fieldTitle: 'Положение'})"
          >
          {{card.version.weldPosition}}
        </td>
      </tr>
      <tr><th colspan="6" class="th">Прочие параметры соединения</th></tr>
      <tr>
        <td colspan="6"
          class="th tmpl-hovered"
          id="others"
          @click="$emit('click', { fieldName: 'others', fieldTitle: 'Прочие параметры'})"
          >
          {{card.version.others}}
        </td>
      </tr>
    </table>
    <table class="table">
      <tr><th colspan="5" class="th">Характеристика свариваемых элементов</th></tr>
      <tr>
        <th class="th"></th>
        <th class="th">Тип</th>
        <th class="th">Марка материала</th>
        <th class="th">Диамтер, (мм)</th>
        <th class="th">Толщина, (мм)</th>
      </tr>
      <tr
        class="th tmpl-hovered"
        id="element1"
        @click="$emit('click', { fieldName: 'element1', fieldTitle: 'Элемент 1'})"
        >
        <td class="th">1</td>
        <td>{{card.version.element1.type}}</td>
        <td>{{card.version.element1.material}}</td>
        <td>{{card.version.element1.d || '---' }}</td>
        <td>{{card.version.element1.s || '---'}}</td>
      </tr>
      <tr
        class="th tmpl-hovered"
        id="element2"
        @click="$emit('click', { fieldName: 'element2', fieldTitle: 'Элемент 2'})"
        >
        <td class="th">2</td>
        <td>{{card.version.element2.type}}</td>
        <td class="th">{{card.version.element2.material}}</td>
        <td class="th">{{card.version.element2.d || '---'}}</td>
        <td class="th">{{card.version.element2.s || '---'}}</td>
      </tr>
    </table>
    <LayersTable :layers="card.version.layers" />
    <table class="table" style="max-width: 50%;">
      <thead>
        <tr>
          <th class="th text-right pr-1">
            Разработал
          </th>
          <th class="th text-left pl-1">
            {{
              card.developer.firstname + ' ' +
              card.developer.middlename + ' ' +
              card.developer.lastname
            }}
          </th>
        </tr>
        <tr>
          <th class="th text-right pr-1">
            Дата разработки
          </th>
          <th class="th text-left pl-1">
            {{secToDatetime(card.createdAt)}}
          </th>
        </tr>
      </thead>
    </table>
    <table class="table" v-if="card.version.files.length">
      <thead>
        <tr>
          <th class="th text-center">
            Прикрепленные файлы
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="f in card.version.files" :key="'CF_' + f.id">
          <td>
            <a :href="f.url" :download="f.name" target="_blank">
              {{f.name}}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </v-container>
</template>

<script>
import { secToDatetime } from '@/libs/time.js'
import LayersTable from '@/modules/cards/components/LayersTable.vue'

export default {
  components: { LayersTable },
  props: { cardId: Number },
  data: () => ({
    card: null,
    secToDatetime,
  }),
  computed: {
    developer() {
      return `${this.card.developer.firstname} ${this.card.developer.middlename} ${this.card.developer.lastname}`
    },
  },
  methods: {
    getCurrent(channels) {
      let c = channels.find(ch => Number(ch.channelId) === 1)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    },
    getVoltage(channels) {
      let c = channels.find(ch => Number(ch.channelId) === 2)
      if (!c) {
        return { min: '- - -', max: '- - -'}
      }
      if (c.min && !c.max) {
          return { min: c.min, max: '- - -'}
      }
      if (!c.min && c.max) {
          return { min: '- - -', max: c.max}
      }
      return c
    }
  },
  mounted() {
    this.$axios.get('/cards/simple/card/view', { params: { id: this.cardId }}).then(r => {
      this.card = r.data      
      this.$nextTick(() => { this.$emit('update:cardLoaded') })
    })
  },
}
</script>

<style>
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  .table td {
    height: 22px;
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
  }
  .table th {
    border-width: 1px;
    border-style: solid;
    border-collapse: collapse;
    text-align: center;
  }
</style>
