<template>
  <div class="crads-table">
    <v-simple-table class="cards-table" fixed-header max-height="300">
      <thead>
        <tr>
          <th v-if="hasSelectSlot"></th>
          <th v-if="hasStatusSlot" class="text-center">
            <span>Статус</span>
            <select v-model="filter.status" class="my-input" style="max-width: 100%;">
              <option value="[8, 13]">Ожидает рассмотрения</option>
              <option value="[9, 14]">Рассматривается</option>
              <option value="[10, 15]">C замечания</option>
              <option value="[11, 16]">Исправляется</option>
              <option value="[12, 17]">Исправлена</option>
              <option value="[18, 19]">Исправлена после согласования</option>
              <option value="" default>Все</option>
            </select>
          </th>
          <th>
            <span>ID/Название</span>
            <input v-model="filter.name" class="my-input" style="max-width: 100%;"/>
          </th>
          <th>
            <span>Способ сварки</span>
            <input v-model="filter.weldMethod" class="my-input"/>
          </th>
          <th>
            <span>Вид элементов</span>
            <input v-model="filter.elementsType" class="my-input"/>
          </th>
          <th>
            <span>D1</span>
            <input v-model="filter.d1From" class="my-input"/>
            <input v-model="filter.d1To" class="my-input"/>
          </th>
          <th>
            <span>D2</span>
            <input v-model="filter.d2From" class="my-input"/>
            <input v-model="filter.d2To" class="my-input"/>
          </th>
          <th>
            <span>S1</span>
            <input v-model="filter.s1From" class="my-input"/>
            <input v-model="filter.s1To" class="my-input"/>
          </th>
          <th>
            <span>S2</span>
            <input v-model="filter.s2From" class="my-input"/>
            <input v-model="filter.s2To" class="my-input"/>
          </th>
          <th>
            <span>Шов</span>
            <input v-model="filter.seamType" class="my-input"/>
          </th>
          <th>
            <span>Соединение</span>
            <input v-model="filter.jointType" class="my-input"/>
          </th>
          <th>
            <span>Материал 1</span>
            <input v-model="filter.elem1Material" class="my-input"/>
          </th>
          <th>
            <span>Материал 2</span>
            <input v-model="filter.elem2Material" class="my-input"/>
          </th>
          <th>
            <span>Св. материал</span>
            <input v-model="filter.weldMaterial" class="my-input"/>
          </th>
          <th>
            <span>Положение</span>
            <input v-model="filter.weldPosition" class="my-input"/>
          </th>
          <th v-if="hasActionSlot"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="card in cards" :key="card.id">
          <td v-if="hasSelectSlot"><slot name="select" :card="card"></slot></td>
          <td v-if="hasStatusSlot" class="text-center">
            <slot name="status" :card="card"/>
          </td>
          <td v-if="hasNameSlot"><slot name="name" :card="card"></slot></td>
          <td v-if="!hasNameSlot">{{ card.name }}</td>
          <td>{{ card.weldMethod }}</td>
          <td>{{ card.elementsType }}</td>
          <td>{{ card.d1 }}</td>
          <td>{{ card.d2 }}</td>
          <td>{{ card.s1 }}</td>
          <td>{{ card.s2 }}</td>
          <td>{{ card.seamType }}</td>
          <td>{{ card.jointType }}</td>
          <td>{{ card.elem1Material }}</td>
          <td>{{ card.elem2Material }}</td>
          <td>{{ card.weldMaterial }}</td>
          <td>{{ card.weldPosition }}</td>
          <td v-if="hasActionSlot"><slot name="action" :card="card"></slot></td>
        </tr>
        <tr v-if="cards.length === 0"><td colspan="14">Не найдено ни одной тех. карты</td></tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      v-if="pagesTotal > 1"
      class="mt-3"
      v-model="page"
      :length="pagesTotal"
    />
  </div>
</template>

<script>
export default {
  name: 'CardsTable',
  data: () => ({
    cards: [],
    filter: {
      status: '',
      afterAgree: '',
      name: '',
      weldMethod: '',
      elementsType: '',
      d1From: '',
      d1To: '',
      d2From: '',
      d2To: '',
      s1From: '',
      s1To: '',
      s2From: '',
      s2To: '',
      seamType: '',
      jointType: '',
      elem1Material: '',
      elem2Material: '',
      weldMaterial: '',
      weldPosition: '',
    },
    page: 1,
    pagesTotal: 0,
    apiTimeout: null,
    loading: false
  }),
  props: {
    url: {
      type: String,
      default: '/manufacture/card/index-active'
    },
    perPage: {
      type: Number,
      default: 10,
    },
    queryParams: {
      type: Object,
      default: null,
    },
  },
  computed: {
    hasSelectSlot() {
      return !!this.$scopedSlots['select']
    },
    hasStatusSlot() {
      return !!this.$scopedSlots['status']
    },
    hasAgreeSlot() {
      return !!this.$scopedSlots['agree']
    },
    hasNameSlot() {
      return !!this.$scopedSlots['name']
    },
    hasActionSlot() {
      return !!this.$scopedSlots['action']
    }
  },
  methods: {
    getCards() {
      this.loading = true
      this.$axios.get(this.url, {
        params: {
          ...this.filter,
          ...this.queryParams,
          page: this.page,
          pageSize: this.perPage,
        }
      }).then(r => {
        this.cards = r.data.items.slice()
        this.pagesTotal = r.data.pagination.pagesTotal
        this.$emit('changed')
      }).catch(() => {
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.apiTimeout)
        this.apiTimeout = setTimeout(() => this.getCards(), 500)
      },
      deep: true
    },
    page(newPage) {
      this.getCards()
    }
  },
  mounted() {
    this.getCards()
  }
}
</script>

<style>
  .cards-table {
    overflow-y: auto !important;
  }
  .cards-table th {
    text-align: left !important;
    vertical-align: bottom !important;
    padding: 10px;
  }
  .cards-table td {
    text-align: left !important;
    padding: 15px 10px !important;
  }
  .my-input {
    background: transparent;
    align-items: center;
    border: 1px solid;
    border-radius: 4px;
    padding: 5px;
    width: 100px;
    display: block;
  }
  .my-input:before {
    border-color: rgba(0, 0, 0, 0.42);
  }
  .my-input:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
  .my-input:focus {
    border-color: #1976d2;
    outline: none;
  }
</style>
