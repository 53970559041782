import { _axios as axios } from '@/plugins/axios.js'
import router from '@/router/index.js';



function getInitialState() {
    return {
        newSteel: {
            c: '',
            cEquiv: '',
            certificate: '',
            cr: '',
            createdAt: '',
            createdBy: '',
            cu: '',
            delta: '',
            group: '',
            id: '',
            kcuIn20: '',
            kcuInMinus40: '',
            kcuInMinus70: '',
            melting: '',
            mn: '',
            mpa: '',
            ni: '',
            orgId: '',
            otherElements: '',
            otherProperties: '',
            p: '',
            part: '',
            psi: '',
            si: '',
            sigmaB: '',
            sigmaT: '',
            status: '',
            title: '',
            v: '',
            strengthСlass: '',
        }
    }
}

const lastState = JSON.parse(localStorage.getItem('dictionary'))
export default {
    namespaced: true,
    // state: lastState || getInitialState(),
    state: getInitialState(),

    actions: {


    },

    mutations: {

        setNewSteelField(state, payload) {
            for (let [key, value] of Object.entries(payload)) {
                state.newCard.newSteel[key] = value
            }
        },


    }

}