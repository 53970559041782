<template>
  <v-card width="70%" class="mt-4 mx-auto">
    <AppointDialog
      v-if="appointDialog"
      v-model="appointData"
      :cardsIds="selectedCards"
      @close="closeDialog"
      >
    </AppointDialog>
    <v-card-subtitle class="primary white--text text-center">
      Респределение карт тех. процессса
    </v-card-subtitle>
    <v-card-text class="pb-0">
      <v-simple-table fixed-header class="appoint-card">
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th>Очередь</th>
              <th>Шов</th>
              <th>Шифр</th>
              <th>ID/Название</th>
              <th>Создан</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in cards" :key="c.id">
              <td><v-checkbox :value="c.id" v-model="selectedCards"></v-checkbox></td>
              <td>{{ c.queue }}</td>
              <td>{{ c.seam }}</td>
              <td>{{ c.code }}</td>
              <td>
                <router-link
                  :to="c.card.module.baseUrl + '/card/view?id=' + c.card.moduleCardId"
                  target="_blank"
                  >
                  {{ c.card.name }}
                </router-link>
              </td>
              <td>{{ secToDatetime(c.createdAt) }}</td>
            </tr>
            <tr v-if="cards.length === 0">
              <td colspan="5">Не найдено ни одного тех. процесса</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-btn color="default" @click="$router.go(-1)">Назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        :disabled="selectedCards.length === 0"
        @click="appointDialog = true"
        >
        Распределить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { secToDatetime } from '@/libs/time.js'
import AppointDialog from './components/AppointDialog.vue'

export default {
  components: {
    AppointDialog,
  },
  data: () => ({
    secToDatetime,
    cards: [],
    selectedCards: [],
    appointDialog: false,
    appointData: {
      base: null,
      welder: null,
      device: null,
    }
  }),
  computed: {
    orgId() { return this.$store.state.organization.profile.id },
  },
  methods: {
    getProcessCards() {
      this.$axios.get('manufacture/process-card/index', {
        params: { procId: this.$route.query.procId }
      }).then(r => {
        if(Array.isArray(r.data) && r.data.length > 0) {
          this.cards = r.data.slice()
        } else {
          this.$router.push('/manufacture/process/index-wait-appoint')
        }
      }).catch(() => {})
    },
    closeDialog() {
      this.selectedCards = []
      this.appointDialog = false
      this.getProcessCards()
    }
  },
  mounted() {
    this.getProcessCards()
  }
}
</script>

<style>
  .appoint-card th {
    text-align: center !important;
  }

  .appoint-card td {
    text-align: center !important;
  }
</style>
