<template>
  <v-card width="60%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Создание тех. процесса</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="ID/название"
            v-model="name"
            >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Группа/объект"
            v-model="groupName"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Серия"
            v-model="serialNumber"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            auto-grow
            filled
            rows="4"
            row-height="30"
            label="Краткое описание тех. процесса"
            v-model="about"
          />
        </v-col>
        <v-col cols="6">
          <StaffSearchField
            v-model="accepter"
            :queryParams="{ roles: ['accepter'] }"
            label="Утверждающий"
          />
        </v-col>
        <v-col cols="3" >
          <v-checkbox label="Требуется согласование" v-model="requireAgree"/>
        </v-col>
        <v-col cols="12" v-if="requireAgree">
          <v-row>
            <v-col cols="6">
              <OrganizationSearchField
                v-model="agreederOrg"
                label="Согласующая организация"
              />
            </v-col>
            <v-col cols="6">
              <StaffSearchField
                v-model="agreeder"
                :queryParams="{ orgId: agreederOrg.id, roles: ['agreeder'] }"
                label="Согласующий"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <Files
        :value="files"
        @input="addFile"
        @removeFile="removeFile"
      />
      <Cards v-model="cards"/>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        :loading="sending"
        :disabled="sending || cards.length === 0"
        @click="send"
        >
        Далее
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Cards from './components/Cards.vue'
import Files from './components/Files.vue'
import StaffSearchField from '@/modules/organization/components/StaffSearchField.vue'
import OrganizationSearchField from '@/modules/organization/components/OrganizationSearchField.vue'

export default {
  components: {
    Cards,
    Files, StaffSearchField, OrganizationSearchField
  },
  props: {
    mode: {
      type: String,
    },
  },
  data: () => ({
    name: 'Process 1',
    groupName: 'Group 1',
    serialNumber: '1',
    about: 'About',
    files: [],
    cards: [],
    sending: false,
    accepter: null,
    agreeder: null,
    agreederOrg: null ,
    requireAgree: false,
  }),
  computed: {
    sortedCards() {
      return this.cards.sort((a, b) => a.queue > b.queue );
    },
    ...mapState('organization', {
      orgId: state => state.profile.id,
    }),
  },
  methods: {
    removeFile(e) {
      this.axios.post('file/delete', { url: e.url }).then(() => {
        let index = this.files.findIndex(f => f.name == e.name)
        this.files.splice(index, 1)
      })
    },
    addFile(file) {
      this.files.push(file)
    },
    send() {
      let procData = {
        name: this.name,
        groupName: this.groupName,
        about: this.about,
        serialNumber: this.serialNumber,
        accepterId: this.accepter ? this.accepter.userId : null,
        agreederId: this.agreeder ? this.agreeder.userId : null,
        agreederOrgId: this.agreederOrg ? this.agreederOrg.id : null,
        cards: this.cards.map(c => ({
          cardId: c.id,
          queue: c.queue,
          seam: c.seam,
          code: c.code.toString()
        })),
        files: this.files
      }
      this.$axios.post('/manufacture/process-template/create', procData).then(r => {
        this.$router.push('/manufacture/process-template/index-accept')
      })
    },
  },
}
</script>
