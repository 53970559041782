<template>
  <v-card width="50%" class="mt-4 mx-auto">
    <v-form v-model="profileValid">
      <v-tabs
        background-color="primary"
        dark centered icons-and-text
        >
        <v-tab>
          Профиль
          <v-icon>mdi-account</v-icon>
        </v-tab>
        <v-tab>
          Контакты
          <v-icon>mdi-phone</v-icon>
        </v-tab>
        <v-tab-item eager>
          <v-card flat tile>
            <v-card-text class="pt-0">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Название организации"
                        v-model="profile.title"
                        :rules="[rules.required]"
                        >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="ИНН"
                        v-model="profile.inn"
                        :rules="[rules.required]"
                        >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="КПП"
                        v-model="profile.kpp"
                        :rules="[rules.required]"
                        >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Email"
                        v-model="profile.email"
                        disabled
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <AvatarField v-model="profile.image" icon="mdi-factory"/>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Коротко о организации"
                    rows="2"
                    v-model="profile.about"
                    >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item eager>
          <v-card flat tile>
            <v-card-text>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    label="Индекс"
                    v-model="profile.zip"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Страна"
                    v-model="profile.country"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Регион"
                    v-model="profile.region"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Обл."
                    v-model="profile.state"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Город"
                    v-model="profile.city"
                  />
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    label="Улица"
                    v-model="profile.street"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Дом"
                    v-model="profile.building"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Строенние"
                    v-model="profile.housing"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Оффис"
                    v-model="profile.office"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Рабочий телефон"
                    v-model="profile.workPhone"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Мобильный телефон"
                    v-model="profile.mobile"
                  />
                </v-col>
                <v-col cols=8>
                  <v-text-field
                    label="Сайт"
                    v-model="profile.site"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-form>
    <v-card-actions>
      <v-btn color="default" @click="$router.go(-1)">Назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" :loading="loading"
        :disabled="!profileValid || loading" @click="save"
        >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/libs/validate-rules.js'
import AvatarField from '@/components/AvatarField.vue'

export default {
  components: { AvatarField },
  data: () => ({
    tab: null,
    profileValid: false,
    rules: rules,
    profile: {
      title: '',
      inn: '',
      kpp: '',
      image: '',
      about: '',
      country: '',
      city: '',
      zip: '',
      region: '',
      street: '',
      building: '',
      housing: '',
      office: '',
      mobilePhone: '',
      workPhone: '',
      email: '',
      site: '',
    },
  }),
  computed: { loading() { return !!this.$store.state.apiRequestCounter } },
  methods: {
    save() {
      let id = this.$store.state.organization.profile.id
      this.$axios.post('organization/profile/update',
        this.profile,
        { params: { id: id } }
      ).then(r => {
        this.$router.replace('/')
      })
    }
  },
  mounted() {
    let id = this.$store.state.organization.profile.id
    this.$axios.get('/organization/profile/view', { params: { id: id } }).then(r => {
      this.profile = r.data
    })
  }
}
</script>
