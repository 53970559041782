<template>
  <v-card>
    <v-card-title>
      Характеристика соединения и свариваемых элементов
    </v-card-title>
    <v-card-text>
      <input
        type="file"
        ref="fileInput"
        style="display: none;"
        accept="image/jpeg,image/png"
        @change="fileInputChange"
      />
      <v-row>
        <v-col cols="2">
          <TooltipComboboxField
            v-model="value.weldMethod"
            label="Способ сварки"
            :items="weldMethodList"
          />
        </v-col>
        <v-col cols="3">
          <TooltipComboboxField
            v-model="value.normDocument"
            label="Нормирующий документ"
            :items="normDocumentList"
          />
        </v-col>
        <v-col cols="2">
          <JointTypeCombobox
            v-model="value.jointType"
            label="Тип соединения"
            @select="jointTypeSelect"
            @clear="jointTypeClear"
          />
        </v-col>
        <v-col cols="1">
          <TooltipComboboxField
            v-model="value.seamType"
            label="Тип шва"
            :items="seamTypeList"
          />
        </v-col>
        <v-col cols="2">
          <TooltipComboboxField
            v-model="value.seamKind"
            label="Вид шва соединения"
            :items="seamKindList"
          />
        </v-col>
        <v-col cols="2">
          <TooltipComboboxField
            v-model="value.weldPosition"
            label="Положение стыка"
            :items="weldPositionList"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Прочие параметры"
            v-model="value.jointOthers"
          />
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-subtitle class="text-center">Конструкция соединения</v-card-subtitle>
            <v-card-text class="text-center hovered" @click="upload('joint')">
              <img v-if="value.jointUrl" :src="value.jointUrl" style="max-height: 300px; max-width: 450px;"/>
              <v-icon size="200" v-else>mdi-image</v-icon>
            </v-card-text>
            <v-card-actions><v-textarea v-model="value.jointText" label="Примечание" rows="3"/></v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-subtitle class="text-center">Конструктивные элементы шва</v-card-subtitle>
            <v-card-text class="text-center hovered" @click="upload('seam')">
              <img v-if="value.seamUrl" :src="value.seamUrl" style="max-height: 300px; max-width: 450px;"/>
              <v-icon size="200" v-else>mdi-image</v-icon>
            </v-card-text>
            <v-card-actions><v-textarea v-model="value.seamText" label="Примечание" rows="3"/></v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <ElementField v-model="value.element1" :elementList="elementList" title="Элемент 1"/>
        </v-col>
        <v-col cols="6">
          <ElementField v-model="value.element2" :elementList="elementList" title="Элемент 2"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TooltipComboboxField from '@/components/TooltipComboboxField.vue'
import ElementField from '@/modules/cards/components/ElementField.vue'
import JointTypeCombobox from '@/modules/cards/components/JointTypeCombobox.vue'

export default {
  props: { value: Object },
  components: { TooltipComboboxField, ElementField, JointTypeCombobox },
  data() {
    return {
      weldMethodList: [], 
      jointTypeList: [], 
      normDocumentList: [],
      seamTypeList: [],
      seamKindList: [],
      weldPositionList: [],
      elementList: [],
      // materialList: [],
      uploading: false,
      uploadDrawingType: 'none',
    }
  },
  methods: {
    upload(drawingType) {
      this.uploadDrawingType = drawingType
      this.$refs.fileInput.click()
    },
    fileInputChange(e) {
      this.uploading = true
      var formData = new FormData()
      formData.append('file', e.target.files[0])
      this.$axios.post('file/upload', formData).then(r => {
        if (this.uploadDrawingType == 'joint') {
          this.value.jointUrl = r.data.url
        } else if (this.uploadDrawingType == 'seam') {
          this.value.seamUrl = r.data.url
        }
      }).catch(() => {
        alert('Ошибка загрузки файла')
      }).finally(() => {       
        this.uploading = false
        this.uploadDrawingType = 'none'
        this.$refs.fileInput.value = ''      
      });
    },
    jointTypeSelect(e) {
      this.value.jointUrl = e.edge
      this.value.seamUrl = e.seam
    },
    jointTypeClear() {
      this.value.jointType = ''
      this.value.jointUrl = ''
      this.value.seamUrl = ''
    }
  },
  mounted() {
    this.$axios.get('cards/weld-method/index').then(r => this.weldMethodList = r.data.slice())
    // this.$axios.get('cards//index').then(r => this.jointTypeList, 
    this.$axios.get('cards/norm-document/index').then(r => this.normDocumentList = r.data.slice())
    this.$axios.get('cards/seam-type/index').then(r => this.seamTypeList = r.data.slice())
    this.$axios.get('cards/seam-kind/index').then(r => this.seamKindList = r.data.slice())
    this.$axios.get('cards/weld-position/index').then(r => this.weldPositionList = r.data.slice())
    this.$axios.get('cards/weld-element/index').then(r => this.elementList = r.data.slice())
    // this.$axios.get('cards/weld-material/index').then(r => this.materialList,
    // this.$axios.get('cards/weld-material/index').then(r => this.materialList,
  }
}
</script>
