<template>            
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-text-field 
        :value="value" 
        :label="label"
        :rules="rules"
        autocomplete="off"
        hide-details 
        v-on="on"
        @input="$emit('input', $event)" 
      />
    </template>
    <v-list max-height="200" class="overflow-y-auto">
      <v-list-item v-for="(item, index) in items" :key="index" @click="$emit('input', item.name)">
            <v-tooltip v-if="item.description" bottom>
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">{{ item.name }}</v-list-item>
                </template>
                <span>{{item.description}}</span>
            </v-tooltip>
            <v-list-item-title v-else>{{item.name}}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: { label: String, value: String, items: Array, rules: Array },
}
</script>