<template>
  <v-dialog :value="show" persistent width="400px">
    <v-card>
      <v-toolbar color="info" dark>
        <v-toolbar-title>Выберите должность</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-select
          :items="positions"
          item-text="name"
          item-value="id"
          v-model="selectedPositionId"
          label="Должность..."
        />
        <OrganizationSearchField v-model="selectedOrg"/>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="signup()">Отправить запрос</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrganizationSearchField from '@/modules/organization/components/OrganizationSearchField.vue'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  components: { OrganizationSearchField },
  data() {
    return {
        positions: [],
        selectedPositionId: 0,
        selectedOrg: {},
    }
  },
  methods: {
    signup() {
      this.$axios.post('/organization/staff/create', {
        orgId: this.selectedOrg.id,
        positionId: this.selectedPositionId
      }).then(() => this.$emit('close'))
    }
  },
  mounted() {
    this.$axios.get('/organization/positions/index').then(r => this.positions = r.data.slice())
  }
}
</script>
