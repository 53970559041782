<template>
  <v-card width="90%" class="mt-4 mx-auto" v-if="card">
    <CommentDialog
      v-if="commentDialogShow"
      :title="commentDialogTitle"
      :fieldName="commentDialogFieldName"
      :card="card"
      :lastComment="commentDialogLastComment"
      v-on:create:comment="createComment"
      v-on:update:comment="updateComment"
      v-on:delete:comment="deleteComment"
      v-on:close="commentDialogShow = false"
      v-on:commentUpdate="highligthFields()"
    />    
    <slot name="title" :card="card"/>
    <v-card-text>
      <component
        class="deep-tmpl-hovered"
        v-if="card"
        :is="tmpl"
        :cardId="card.moduleCardId"
        v-on:click="openCommentDialog"
        v-on:update:cardLoaded="highligthFields()"
      />
    </v-card-text>
    <slot name="actions" :card="card"/>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CommentDialog from './CommentDialog.vue'
//TMPLS
import SimpleTmpl from '@/modules/cards/simple/views/components/ViewTmpl.vue'
import SpecialTmpl from '@/modules/cards/special/views/components/ViewTmpl.vue'

export default {
  components: {
    CommentDialog,
    SimpleTmpl,
    SpecialTmpl,
  },
  data: () => ({
    card: null,
    // moduleCard: null,
    tmpl: null,
    // cardNewprivateChat: [],
    commentDialogShow: false,
    commentDialogTitle: '',
    commentDialogFieldName: '',
    commentDialogLastComment: null,
    // cardViewKey: 1,
  }),
  computed: {
    ...mapState('user', { userId: state => state.profile.userId }),
  },
  methods: {
    createComment(comment) {
      this.card.privateChat.push(Object.assign({}, comment))
      this.highligthFields()
      this.commentDialogShow = false
    },
    updateComment(comment) {
      let com = this.card.privateChat.find(c => {
        return c.id === comment.id
      })
      if (com) {
        com.message = comment.message
      } else {
        alert('Fatal error, please reload page')
      }
      this.highligthFields()
      this.commentDialogShow = false
    },
    deleteComment(comment) {
      alert('delete not work')
      this.highligthFields()
      this.commentDialogShow = false
    },
    sortCardPrivateChat() {
      this.card.privateChat.sort((a, b) => {
        if (a.fieldName == b.fieldName) {
          return b.createdAt - a.createdAt
        } else if (a.fieldName > b.fieldName) {
          return 1
        }
        return -1
      })
    },
    highligthFields() {
      this.sortCardPrivateChat()
      var fn = ''
      this.card.privateChat.forEach(c => {
        if (c.fieldName != fn) {
          fn = c.fieldName
          let el = document.getElementById(fn)
          if (el) {
            switch(c.status) {
              case 1:
                el.style.backgroundColor = '#FFCDD2'
                break
              case 20:
                el.style.backgroundColor = '#C8E6C9'
                break
              case 21:
                el.style.backgroundColor = '#FFE0B2'
                break
              case 22:
                el.style.backgroundColor = '#BBDEFB'
                break
            }
          } else {
            console.log('Wrning ',fn,' not found in DOM')
          }
        }
      })
    },
    openCommentDialog(cardField) {
      if(this.card.status == 10 || this.card.status == 15) {
        // card in correct
        if (!this.card.privateChat.find(c => c.fieldName == cardField.fieldName)) {
          return
        }
      }
      this.commentDialogTitle = cardField.fieldTitle
      this.commentDialogFieldName = cardField.fieldName
      this.commentDialogLastComment = this.card.privateChat.find(c => {
        return (c.fieldName == cardField.fieldName && c.status == 4)
      })
      if (this.commentDialogLastComment == null) {
        this.commentDialogLastComment = { message: '' }
      }
      this.commentDialogShow = true
    },
    getCard() {
      this.$axios.get('/manufacture/card/view', {
        params: { id: this.$route.query.id }
      }).then(r => {
        let tmpl = r.data.module.baseUrl.replace('/cards/','')
        tmpl = tmpl.charAt(0).toUpperCase() + tmpl.slice(1) + 'Tmpl'
        this.card = r.data
        this.tmpl = tmpl
        // this.$nextTick(() => {this.highligthFields()})
      })
    }
  },
  mounted() {
    this.getCard()
  }
}
</script>
