<template>
  <v-card width="35%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>
        Восстановление пароля
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0">
      <v-form v-model="dataValid">
        <v-row class="px-2">
          <v-text-field
            v-model="email"
            label="Пожалуйста, укажите email"
            :rules="[rules.required, rules.email]"
          />
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="info" to="/user/account/login">Назад</v-btn>
        <v-spacer></v-spacer>
      <v-btn color="success" @click="reset" :loading="loading" :disabled="loading||!dataValid">Далее</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/libs/validate-rules.js'
export default {
  data: () => ({
    email: '',
    rules: rules,
    dataValid: false,
    loading: false
  }),
  methods: {
    reset() {
      this.loading = true
      this.$axios.post('user/account/password-reset-request', { email: this.email }).then(res => {
        this.$router.push('/user/account/login')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}

</script>
