<template>
  <v-card>
    <v-card-title>Порядок сварки</v-card-title>
    <input
      type="file"
      ref="fileInput"
      style="display: none;"
      accept="image/jpeg,image/png"
      @change="fileInputChange"
    />
    <v-card-text>
      <v-row align="center" class="bordered">
        <v-col
          cols="3"
          v-for="(ord, i) in value.weldOrders"
          :key="'ord_' + i"
        >
          <v-card>
            <v-card-title>
              <WeldOrderCombobox label="Название" v-model="ord.name" @data="setWeldOrderData($event, i)"/>
            </v-card-title>
            <v-card-text class="text-center">
              <div class="hovered" @click="upload(i)">
                <img v-if="ord.url" :src="ord.url" height="200" width="200"/>
                <v-icon size="200" v-else>mdi-image</v-icon>
              </div>
              <v-textarea v-model="ord.text" label="Примечание" rows="1"/>
            </v-card-text>
            <v-card-actions>
              <v-spacer/><v-btn color="red" dark @click="remove(i)">Удалить</v-btn><v-spacer/>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-btn color="success" @click="newWeldOrder">Добавить</v-btn>
        </v-col>
      </v-row>
      <v-textarea
        label="Примечание"
        v-model="value.weldOrderText"
        rows="3"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import WeldOrderCombobox from '@/modules/cards/components/WeldOrderCombobox.vue'

export default {
  props: { value: Object },
  components: { WeldOrderCombobox },
  data: () => ({ weldOrderIndex: -1}),
  methods: {
    fileInputChange(e) {
      this.uploading = true
      var formData = new FormData()
      formData.append('file', e.target.files[0])
      this.$axios.post('file/upload', formData).then(r => {
        this.value.weldOrders[this.weldOrderIndex].url = r.data.url
      }).catch((e) => {
        console.log(e)
        alert('Ошибка загрузки файла')
      }).finally(() => {       
        this.uploading = false
        this.$refs.fileInput.value = ''      
      });
    },
    setWeldOrderData(e, i) {
      this.value.weldOrder[i].url = '/api' + e.url
      this.value.weldOrder[i].text = e.text
    },
    remove(i) {
      this.value.weldOrder.splice(i, 1)
    },
    newWeldOrder() {
      this.value.weldOrder.push({ name: '', url: '', about: ''})
    },
    upload(index) {
      this.weldOrderIndex = index
      this.$refs.fileInput.click()
    },
  },
}
</script>