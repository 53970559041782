export default [
	{ name: 'Монитор', icon: 'mdi-chart-timeline-variant', route: '/manufacture/monitor' },
	// { name: 'Раб. места', icon: 'mdi-chart-timeline-variant', route: '/manufacture/work-place' },
	{
	  name: 'Тех. карты',
	  icon: 'mdi-file',
	  children: [
    //   { name: 'Импорт', icon: 'mdi-file-plus', route: '/manufacture/card/create' },
      { name: 'На утверждении', icon: 'mdi-file-edit', route: '/manufacture/card/index-accept' },
	    { name: 'На согласовании', icon: 'mdi-file-sync', route: '/manufacture/card/index-agree' },
	    { name: 'Рабочие', icon: 'mdi-file-check', route: '/manufacture/card/index-active' },
	    { name: 'Архивные', icon: 'mdi-file-lock', route: '/manufacture/card/index-archive' },
	  ]
	},
	{
	  name: 'Типовые ТП',
	  icon: 'mdi-clipboard-text-outline',
	  children: [
	  	// { name: 'Новый', icon: 'mdi-clipboard-outline', route: '/manufacture/process-template/create' },
		{ name: 'На утверждении', icon: 'mdi-clipboard-flow-outline', route: '/manufacture/process-template/index-accept'},
		{ name: 'На согласовании', icon: 'mdi-clipboard-flow-outline', route: '/manufacture/process-template/index-agree'},
	    { name: 'Рабочие', icon: 'mdi-clipboard-check-outline', route: '/manufacture/process-template/index-active'},
	    // { name: 'Устаревшие', icon: 'mdi-clipboard-text-off-outline', route: '/manufacture/process-template/index-archive'},
	    // { name: 'В очереди', icon: 'mdi-clipboard-text-play', route: '/manufacture/process/index-in-queue' },
	    // { name: 'В производстве', icon: 'mdi-clipboard-play', route: '/manufacture/process/index-in-progress' },
	    // { name: 'Завершенные', icon: 'mdi-clipboard-check', route: '/manufacture/process/index-done' },
		{ name: 'Архивные', icon: 'mdi-archive-outline', route: '/manufacture/process-template/index-archive' },
	  ]
	},
	{
	  name: 'Активные ТП',
	  icon: 'mdi-clipboard-text',
	  children: [
	    { name: 'На распределении', icon: 'mdi-clipboard-list', route: '/manufacture/process/index-wait-appoint' },
	    { name: 'В очереди', icon: 'mdi-clipboard-text-play', route: '/manufacture/process/index-in-queue' },
	    { name: 'В производстве', icon: 'mdi-clipboard-play', route: '/manufacture/process/index-in-progress' },
	  ]
	},
	{ name: 'Завершенные ТП', icon: 'mdi-clipboard-check', route: '/manufacture/process/index-done' },
	{ name: 'Архив ТП', icon: 'mdi-archive', route: '/manufacture/process/index-archive' },
	{
		name: 'Отчеты',
		icon: 'mdi-finance',
		children: [
			{ name: 'По персоналу', icon: 'mdi-account', route: '/manufacture/report/staff/index' },
		]
	},
	// { name: 'Архив', icon: 'mdi-archive', route: '/manufacture/process/index-archive' },
]
