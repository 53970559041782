<template>
  <v-card width="65%" class="mt-4 mx-auto">
    <v-toolbar color="primary" dark>
      Создание нового устройства
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Общее описание</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field 
                    label="UUID" 
                    v-model="config.deviceUuid"
                    append-icon="mdi-new-box"
                    @click:append="generateUuid"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    label="Название" 
                    v-model="config.name"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field 
                    label="Место нахождения" 
                    v-model="config.location"
                  />
                </v-col>
                <v-col cols="6">
                  <UserSearchField label="Пользователь" v-model="user"/>
                </v-col>
                <v-col cols="6">
                  <OrganizationSearchField label="Организация" v-model="org" :queryParams="{ scope: 1 }"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>Общие настройки</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                    <v-text-field label="Base URL" v-model="config.httpServer"/>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="MQTT server" v-model="config.mqttServer"/>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Calibrations upload URL" v-model="config.httpCalibrationsUploadUrl"/>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Verifications upload URL" v-model="config.httpVerificationsUploadUrl"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>Настройки WiFi</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                    <v-text-field label="SSID" v-model="config.ssid"/>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Password" v-model="config.psk">
                    <template v-slot:append-outer>
                      <v-btn small @click="hashPassword()">hash it</v-btn>
                    </template>
                  </v-text-field>              
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>Настройки самописца</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                    <v-text-field label="Upload URL" v-model="config.httpRegistrationsUploadUrl"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>                
        <v-col cols="12">
          <v-card>
            <v-card-title>Настройки производства</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                    <v-text-field label="Next card URL" v-model="config.httpNextCardUrl"/>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Create layer URL" v-model="config.httpCreateLayerUrl"/>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Finish card URL" v-model="config.httpFinishCardUrl"/>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Reg file upload URL" v-model="config.httpManufacturesUploadUrl"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="default" to="/device/index">Назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="createDevice('weldassist.conf')">Создать weldassist.conf</v-btn>
      <v-btn color="success" @click="createDevice(config.uuid + '.conf')">Создать UUID.conf</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import OrganizationSearchField from '@/modules/organization/components/OrganizationSearchField.vue';
import UserSearchField from '@/modules/user/components/UserSearchField.vue';
import crypto from 'crypto'
import { saveAs } from 'file-saver';

export default {
  components: {
    OrganizationSearchField,
    UserSearchField,
  },
  data: () => ({
    user: {},
    org: {},
    config: {
      taskId: 3,
      deviceUuid: "A5F1F430-7347-4BAC-BD6B-45F56D145527",
      name: "No name",
      location: "No location",
      httpServer: "http://dev.weldassist.ru/api",
      httpConfigUrl: "/device/api/config",
      httpCalibrationsUploadUrl: "/device/api/create-calibration",
      httpVerificationsUploadUrl: "/device/api/create-verification",
      httpRegistrationsUploadUrl: "/registration/device-api/upload",
      httpNextCardUrl: "/manufacture/device-api/next-card",
      httpCreateLayerUrl: "/manufacture/device-api/create-layer",
      httpFinishCardUrl: "/manufacture/device-api/finish-card",
      httpManufacturesUploadUrl: "/manufacture/device-api/upload",
      mqttServer: "tcp://weldassist.ru:1883",
      ssid: "Serega",
      psk: "ae6a3829b8bcad16e4d9756cb78ecab4445bd261ce4bfb87d2c8f683534c2dad"
    },
    channelsNames: [],
  }),
  computed: {
    adminFlag() {
      return this.$store.state.user.role == 'admin' 
    },
  },
  methods: {
    hashPassword() {
      crypto.pbkdf2(this.config.psk, this.config.ssid, 4096, 32, null, (err, derivedKey) => {
        if (err) console.log(err)
        else {
          let psk = ''
          derivedKey.forEach(byte => {
            psk += ('0' + (byte & 0xFF).toString(16)).slice(-2)
          })
          this.config.psk = psk
        }
      })
    },
    generateUuid() {
      this.config.uuid = uuidv4().toUpperCase()
    },
    createDevice(fileaName) {
      this.$axios.post('/admin/device/create', { userId: Number(this.user.userId), orgId: Number(this.org.id), ...this.config }).then(r => {
        let mainConfig = new Blob(
          [JSON.stringify(this.config, null, 4)], 
          { type: "text/plain;charset=utf-8" }
        )
        saveAs(mainConfig, fileaName)
      }).catch(() => {}).finally(() => {})
    },
  },
}
</script>