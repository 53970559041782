<template>
  <v-dialog :value="show" persistent width="400px">
    <v-card>
      <v-toolbar color="info" dark>
        <v-toolbar-title>Выберите должность</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-select
          :items="positionsNames"
          item-text="name"
          item-value="id"
          v-model="positionId"
          label="Должность..."
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="$emit('input', positionId)">Отправить запрос</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    positionsNames: {
      type: Array,
      required: true
    }
  },
  data: () => ({
      positionId: null
  })
}
</script>
