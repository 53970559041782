<template>
    <v-card style="width: 75%; margin: 15px auto">
        <v-toolbar color="primary" dark>Доступные модули</v-toolbar>
        <v-card-text>
            <v-simple-table :key="tableTimestamp">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Модуль</th>
                            <th>Краткое описание</th>
                            <th>Активирован</th>
                            <th>Действует до</th>
                            <th width="64"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="m in modules" :key="m.id">
                            <td>{{m.name}}</td>
                            <td>{{m.description}}</td>
                            <td>{{m.activatedAt}}</td>
                            <td>{{m.expiredAt}}</td>
                            <td>
                                <v-btn 
                                    tile 
                                    outlined 
                                    :disabled="isActiveModule(m.id)"
                                    color="warning" 
                                    @click="activateModule(m)"
                                >
                                    Активировать
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>            
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            modules: [],
            userModules: [],
            tableTimestamp: 0,
        }
    },
    methods: {
        isActiveModule(id) {            
            return this.userModules.some(um => um.moduleId == id)
        },
        getUserModules() {
            this.$axios.get('user/modules/index').then(r => {
                this.userModules = r.data.slice()
                this.tableTimestamp = Date.now()
            })
        },        
        activateModule(module) {
            this.$axios.post('/user/modules/activate', { moduleId: module.id }).then(() => {
                this.$store.commit('user/addModule', module)
                this.getUserModules()
            })
        }
    },
    mounted() {
        this.$axios.get('modules/index').then(modules => {
            this.modules = modules.data.slice()
            this.getUserModules()
        })
    }
}
</script>