<template>
  <CVUForm v-if="device" title="Просмотр устройства" :value="device" @input="save"/>
</template>

<script>
import CVUForm from '../components/cvuForm.vue'

export default {
  components: { CVUForm },
  data: () => ({
    device: null
  }),
  methods: {
    save(d) {
      this.$axios.post('/device/main/update', { ...d })
        .then(() => this.$router.replace('/device/index'))
        .catch(() => {})
        .finally(() => {})
    },
  },
  mounted() {
      this.$axios.get('/device/main/view', { params: { uuid: this.$route.query.deviceUuid }})
        .then(r => this.device = r.data)
  }
}
</script>