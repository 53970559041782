<template>
  <v-card width="90%" class="mt-4 mx-auto" v-if="proc">
    <CommentDialog
      v-if="commentDialogShow"
      :title="commentDialogTitle"
      :fieldName="commentDialogFieldName"
      :processTmpl="proc"
      :lastComment="commentDialogLastComment"
      v-on:create:comment="createComment"
      v-on:update:comment="updateComment"
      v-on:delete:comment="deleteComment"
      v-on:close="commentDialogShow = false"
      v-on:commentUpdate="highligthFields"
    />
    <slot name="title" :proc="proc"/>
    <v-card-text>
      <TmplProcessView
        :process="proc"
        v-on:click="openCommentDialog"
      />
    </v-card-text>
    <slot name="actions" :proc="proc"/>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CommentDialog from './CommentDialog.vue'
import TmplProcessView from '../../process-template/components/ViewTmpl.vue'

export default {
  components: { CommentDialog, TmplProcessView },
  data: () => ({
    proc: null,
    commentDialogShow: false,
    commentDialogTitle: '',
    commentDialogFieldName: '',
    commentDialogLastComment: null,
  }),
  computed: {
    userId() { return this.$store.state.user.profile.userId },
  },
  methods: {
    createComment(comment) {
      this.proc.privateChat.push(Object.assign({}, comment))
      this.highligthFields()
      this.commentDialogShow = false
    },
    updateComment(comment) {
      let com = this.proc.privateChat.find(c => {
        return c.id === comment.id
      })
      if (com) {
        com.message = comment.message
      } else {
        alert('Fatal error, please reload page')
      }
      this.highligthFields()
      this.commentDialogShow = false
    },
    deleteComment(comment) {
      alert('delete not work')
      this.highligthFields()
      this.commentDialogShow = false
    },
    sortProcComments() {
      this.proc.privateChat.sort((a, b) => {
        if (a.fieldName == b.fieldName) {
          return b.createdAt - a.createdAt
        } else if (a.fieldName > b.fieldName) {
          return 1
        }
        return -1
      })
    },
    highligthFields() {
      this.sortProcComments()
      var fn = ''
      this.proc.privateChat.forEach(c => {
        if (c.fieldName != fn) {
          fn = c.fieldName
          let el = document.getElementById(fn)
          if (el) {
            switch(c.status) {
              case 1:
                el.style.backgroundColor = '#FFCDD2'
                break
              case 20:
                el.style.backgroundColor = '#C8E6C9'
                break
              case 21:
                el.style.backgroundColor = '#FFE0B2'
                break
              case 22:
                el.style.backgroundColor = '#BBDEFB'
                break
            }
          } else {
            console.log(fn,'not found in DOM')
          }
        }
      })
    },
    openCommentDialog(procField) {
      if(this.proc.status == 10 || this.proc.status == 15) {
        // proc in correct
        if (!this.proc.privateChat.find(c => c.fieldName == procField.fieldName)) {
          return
        }
      }
      this.commentDialogTitle = procField.fieldTitle
      this.commentDialogFieldName = procField.fieldName
      this.commentDialogLastComment = this.proc.privateChat.find(c => {
        return (c.fieldName == procField.fieldName && c.status === 4)
      })
      if (this.commentDialogLastComment == null) {
        this.commentDialogLastComment = { message: '' }
      }
      this.commentDialogShow = true
    },
    getProc() {
      this.$axios.get('/manufacture/process-template/view', {
        params: { id: this.$route.query.id }
      }).then(r => {
        this.proc = r.data
        this.$nextTick(() => { this.highligthFields() })
      })
    }
  },
  mounted() {
    this.getProc()
  }
}
</script>
