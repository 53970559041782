<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <td v-if="title" colspan="7" class="text-center">{{title}}</td>
        </tr>
        <tr>
          <td>Доп. вверх</td>
          <td>Доп. вниз</td>
          <td>Крит. вверх</td>
          <td>Крит. вниз</td>
          <td>Доп.</td>
          <td>Крит.</td>
          <td>Общие</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{calc('allowUp')}}</td>
          <td>{{calc('allowDown')}}</td>
          <td>{{calc('critUp')}}</td>
          <td>{{calc('critDown')}}</td>
          <td>{{calc('allow')}}</td>
          <td>{{calc('crit')}}</td>
          <td>{{calc('common')}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>    
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    deviations: {
      type: Object,
    },
    weldTime: {
      type: Number,
    }
  },
  methods: {
    calc(type) {
      if (this.deviations) {
        if (this.weldTime > 0) {
          return ((this.deviations[type] * 100)/this.weldTime).toFixed(1) + '%'
        }
        return 0 + '%'
      } else {
        return 'Н/Д'
      }
    }
  }
}
</script>