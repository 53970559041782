export default {
    required: value => !!value || 'Данное поле обязательно для заполнения!',
    requiredForGroup: (value) => value.length > 0 || 'Данное поле обязательно для заполнения!',
    min: (value, min) => value.length >= min || 'Минимум ' + min + ' символов',
    max: (value, max) => value.length <= max || 'Максимум ' + max + ' символов',
    maxAfterPoint: (valueAfterPoint, max) => {
        const pattern = RegExp("^(\\d{1,}.?\\d{0," + max + "})$")
        return pattern.test(valueAfterPoint) || 'Максимум ' + max + ' символов после точки'
    },
    email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Не правильно введен e-mail'
    },
    minSeam: (value, min) => value >= min || 'Слоёв шва должно быть минимум ' + min,
}